import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Routes } from '@/domain/Routes';
import { TermsOfServiceRepository } from '@/domain/termsOfService/TermsOfServiceRepository';
import { Theme } from '@/domain/theme/Theme';
import { IconVue } from '@/primary/components/common/icon';
import CguBus from '@/primary/components/footer/CguBus';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';
import { StoreRepository } from '@/secondary/store/localeStore/StoreRepository';

@Component({
    components: {
        IconVue,
    },
})
export default class FooterComponent extends Vue {
    @Prop()
    loginPage?: boolean;

    @Inject()
    private termsOfServiceRepository!: () => TermsOfServiceRepository;

    @Inject()
    private storeRepository!: () => StoreRepository;

    @Inject()
    private cguBus!: () => CguBus;

    isTosModalDisplayed = false;
    areTosValidated = true;
    cguBusId = -1;
    private html = '';
    private lastModified?: Date;
    theme!: Theme;

    async created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;
        await this.loadTOS('fr');

        if (this.storeRepository().getUser()) this.getTermOfServices();
        this.cguBusId = this.cguBus().subscribe(() => {
            this.getTermOfServices();
        });
    }

    async loadTOS(langage: string) {
        await this.termsOfServiceRepository()
            .get(langage)
            .then(terms => {
                this.html = terms.data;
                this.lastModified = terms.lastModified;
            });
    }

    destroyed() {
        this.cguBus().unsubscribe(this.cguBusId);
    }

    async accept() {
        await this.termsOfServiceRepository().validate();
        this.closeTosModal();
    }

    logout() {
        this.storeRepository().removeUser();
        this.$router.push({ name: Routes.LoginPage });
        this.closeTosModal();
    }

    openTosModal() {
        this.isTosModalDisplayed = true;
    }

    closeTosModal() {
        this.isTosModalDisplayed = false;
    }

    private getTermOfServices() {
        this.termsOfServiceRepository()
            .hasUserValidated(this.lastModified!)
            .then(value => {
                this.isTosModalDisplayed = !value;
                this.areTosValidated = value;
            });
    }
}
