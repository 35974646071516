export interface SiteDetailsResponsible {
    inCharge: string;
    phone: string;
    fax: string;
    mail: string;
}

export const emptySiteDetailsResponsible = (): SiteDetailsResponsible => ({
    inCharge: '',
    phone: '',
    fax: '',
    mail: '',
});
