import { ReplacedContact } from '@/domain/contact/ReplacedContact';
import { RestContact, toRestContact } from '@/secondary/restContact/RestContact';

export interface RestReplacedContact {
    ContactToReplace: RestContact;
    ContactReplacing: RestContact;
}

export const toRestReplacedContact = (replacedContact: ReplacedContact): RestReplacedContact => ({
    ContactToReplace: toRestContact(replacedContact.old),
    ContactReplacing: toRestContact(replacedContact.new),
});
