import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import { callListStorageModule } from '@/secondary/store/callListStore/CallListStorageModule';
import { localStorageModule } from '@/secondary/store/localeStore/LocalStorageModule';

Vue.use(Vuex);

export const createStore = () =>
    new Store({
        modules: {
            localStorageModule: localStorageModule(),
            callListModuleModal: callListStorageModule(),
        },
    });
