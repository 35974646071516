import moment from 'moment';

import { TimeGridSchedule } from '@/domain/timeGrid/TimeGridSchedule';
import { TimeSlot } from '@/domain/timeGrid/TimeSlot';
import { RestTimeGridSchedule } from '@/secondary/restTimeGrid/RestTimeGridSchedule';

export interface RestTimeSlot {
    Exceptional: boolean;
    StartHour: string;
    EndHour: string;
}

export const toTimeSlot = (restTimeGridSchedule: RestTimeGridSchedule): TimeSlot[] => {
    const timeSlots: TimeSlot[] = [];

    restTimeGridSchedule.TimeSlots.forEach(item => {
        const timeSlot: TimeSlot = {
            exceptional: item.Exceptional,
            start: moment(restTimeGridSchedule.Date).format('YYYY-MM-DD') + ' ' + item.StartHour + ':00',
            end: moment(restTimeGridSchedule.Date).format('YYYY-MM-DD') + ' ' + item.EndHour + ':00',
            closed: restTimeGridSchedule.Closed,
            reference: restTimeGridSchedule.Reference,
        };
        timeSlots.push(timeSlot);
    });

    return timeSlots;
};

export const toRestTimeSlot = (timeGridSchedule: TimeGridSchedule): RestTimeSlot[] => {
    const timeSlots: RestTimeSlot[] = [];

    timeGridSchedule.timeSlots
        .filter(timeSlot => !timeSlot.closed)
        .forEach(item => {
            const timeSlot: RestTimeSlot = {
                Exceptional: item.exceptional,
                StartHour: item.start.slice(11, 16),
                EndHour: item.end.slice(11, 16),
            };
            timeSlots.push(timeSlot);
        });

    return timeSlots;
};
