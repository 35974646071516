import { AxiosError } from 'axios';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Inject, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { EventHistory, EventHistoryMediaDetail } from '@/domain/event/Event';
import { EventRepository } from '@/domain/event/EventRepository';
import { ExportEventParam, mediaTypePrimary, typePrimary } from '@/domain/event/ExportEventParam';
import { eventsHeader, eventsHeaderDetails, eventsHeaderMobile } from '@/domain/header/eventsHeader';
import { Right } from '@/domain/menu/Right';
import { SiteRepository } from '@/domain/site/SiteRepository';
import { UserSite } from '@/domain/site/UserSite';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import { IconVue } from '@/primary/components/common/icon';
import { HumanSupervisionVue } from '@/primary/components/humanSupervision';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { Tab } from '@/primary/components/navbar/Tab';
import TabsNavigationBus from '@/primary/components/navbar/TabsNavigationBus';
import TabsNumberNavigationBus from '@/primary/components/navbar/TabsNumberNavigationBus';
import { formatDate } from '@/primary/utils/Filters/DateFormat';
import { Responsive } from '@/primary/utils/Responsive';
import { RightMixin } from '@/primary/utils/rights/RightMixin';
import ToastBus from '@/primary/utils/ToastBus';

@Component({
    components: {
        IconVue,
        HumanSupervisionVue,
    },
})
export default class HistoryPageComponent extends mixins(RightMixin) {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private eventRepository!: () => EventRepository;

    @Inject()
    private responsive!: () => Responsive;

    @Inject()
    private tabsNavigationBus!: () => TabsNavigationBus;

    @Inject()
    private tabsNumberNavigationBus!: () => TabsNumberNavigationBus;

    @Inject()
    private siteRepository!: () => SiteRepository;

    @Inject()
    private toastBus!: () => ToastBus;

    sites: UserSite[] = [];
    siteSelected: UserSite[] = [];
    siteSelectedVuetify: string[] = [];
    typeSelected: string[] = [];
    mediaTypeSelected: string[] = [];
    allCity = false;
    startDate = new Date(Date.now() - 24 * 60 * 60 * 1000 * 7).toISOString();
    endDate = new Date().toISOString();
    cityFocus = false;
    eventFocus = false;
    siteFocus = false;
    startDateOpen = false;
    endDateOpen = false;
    startDateOpenMobile = false;
    endDateOpenMobile = false;
    search = '';
    sortType = '';
    types: string[] = [];
    mediaTypes: string[] = [];
    selectedFile: any;
    headers: DataTableHeader[] = eventsHeader();
    headersMobile: DataTableHeader[] = eventsHeaderMobile();
    headerDetail: DataTableHeader[] = eventsHeaderDetails();
    datas: EventHistory[] = [];
    dialogIsOpen = false;
    typeDocument = '';
    detailsEvent: EventHistoryMediaDetail[] = [];
    detailImage: any = '';
    detailVideo: any = '';
    tab = 0;
    tabs: Tab[] = [
        { name: 'Événement', right: Right.CNS_HIS },
        { name: 'Surveillance humaine', right: Right.CNS_ALA },
    ];
    tabsNumberNavigationBusId = 0;
    isLoading = false;
    detailType = '';

    get formatCustomStartDate() {
        return this.startDate.length > 0 ? moment(new Date(this.startDate)).locale('fr').format('L') : '';
    }

    get formatCustomEndDate() {
        return this.endDate.length > 0 ? moment(new Date(this.endDate)).locale('fr').format('L') : '';
    }

    get endDateNextDay(): string {
        const nextDay = new Date(this.endDate);
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay.toISOString();
    }

    get updateData() {
        return this.datas
            .sort((a, b) => formatDate(b.date).localeCompare(formatDate(a.date)))
            .filter(event => {
                return this.mediaUpdated(event) && this.typeUpdated(event);
            });
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    get siteSelectedFormatted() {
        return this.siteSelected.map(site => site.reference);
    }

    get siteFormatted() {
        return this.sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(site => site.reference + '-' + site.name)
            .sort((a, b) => {
                const hasA = this.siteSelectedVuetify.find(site => site === a) !== undefined;
                const hasB = this.siteSelectedVuetify.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    switchSite(site: string) {
        const index = this.siteSelectedVuetify.indexOf(site);
        if (index != -1) this.siteSelectedVuetify.splice(index, 1);
        else this.siteSelectedVuetify.push(site);
    }

    siteNameFromReference(reference: string) {
        const ref = reference.split('-')[0];
        return this.sites.find(site => site.reference === ref)!.name;
    }

    async mounted() {
        this.isLoading = true;
        this.pageNameBus().add('Mes Historiques');
        await this.getSites();
        this.getData();
        this.tabsNavigationBus().add(this.tabs);
        this.tabsNumberNavigationBusId = this.tabsNumberNavigationBus().subscribe(value => {
            this.tab = value!;
        });
    }

    destroyed() {
        this.tabsNavigationBus().add([]);
        this.tabsNumberNavigationBus().unsubscribe(this.tabsNumberNavigationBusId);
    }

    getDetailsRapport(item: EventHistory) {
        this.dialogIsOpen = true;
        this.detailType = item.media!.typeFilter;
        this.eventRepository()
            .getEventDetail(item.id)
            .then(data => {
                this.detailsEvent = data;
            });
    }

    getDetailsVideo(item: EventHistory) {
        this.dialogIsOpen = true;
        this.detailType = item.media!.typeFilter;
        this.eventRepository()
            .getEventVideo(item.id)
            .then(data => {
                this.detailVideo = data;
            });
    }

    getDetailsImage(item: EventHistory) {
        this.dialogIsOpen = true;
        this.detailType = item.media!.typeFilter;
        this.eventRepository()
            .getEventImage(item.id)
            .then(data => {
                this.detailImage = data;
            });
    }

    setClass(item: EventHistory) {
        switch (item.type) {
            case 'Avertissement':
                return 'history--item history--item-warning';
            case 'Alarme':
                return 'history--item history--item-alarm';
            default:
                return 'history--item ';
        }
    }

    closeModal() {
        this.detailsEvent = [];
        this.detailVideo = '';
        this.detailImage = '';
        this.dialogIsOpen = false;
    }

    exportData(type: string) {
        this.eventRepository()
            .getExport(type, this.toExportEventParams())
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    @Watch('siteSelectedVuetify.length')
    async getData() {
        this.isLoading = true;
        await this.eventRepository()
            .get(
                this.siteSelectedVuetify.map(s => s.split('-')[0]),
                this.startDate.length > 0 ? new Date(this.startDate).toISOString() : undefined,
                this.endDate.length > 0 ? this.endDateNextDay : undefined
            )
            .then(datas => {
                this.fromDomain(datas.events);
                this.warningToast(datas.warnings);
                this.isLoading = false;
            })
            .catch((error: AxiosError) => {
                console.log(error);
            });
    }

    get hasRightVideoImage(): boolean {
        return this.hasRight(Right.CNS_VID);
    }

    get hasRightFile(): boolean {
        return this.hasRight(Right.CNS_CRO);
    }

    private toExportEventParams(): ExportEventParam {
        const eventType: number[] = [];
        this.typeSelected.forEach(type => eventType.push(typePrimary().get(type)!));
        const mediaType: number[] = [];
        this.mediaTypeSelected.forEach(type => mediaType.push(mediaTypePrimary().get(type)!));
        let eventParams: ExportEventParam = {
            SiteReferences: this.siteSelectedVuetify.map(s => s.split('-')[0]),
            EventTypes: eventType,
            AttachmentTypes: mediaType,
        };
        if (this.startDate.length > 0)
            eventParams = {
                ...eventParams,
                FromDate: new Date(this.startDate).toISOString(),
            };
        if (this.endDate.length > 0) eventParams = { ...eventParams, ToDate: this.endDateNextDay };

        return eventParams;
    }

    private fromDomain(events: EventHistory[]) {
        this.datas = events;
        this.createTypeFilter(events);
        this.createMediaTypeFilter(events);
    }

    private createTypeFilter(events: EventHistory[]) {
        events.forEach(event => {
            this.types.push(event.type);
        });
        this.types = [...new Set(this.types)];
    }

    private createMediaTypeFilter(events: EventHistory[]) {
        events.forEach(event => {
            if (event.media) this.mediaTypes.push(event.media.typeFilter);
        });
        this.mediaTypes = [...new Set(this.mediaTypes)];
    }

    private mediaUpdated(event: EventHistory) {
        const media: number[] = [];
        this.mediaTypeSelected.forEach(type => media.push(mediaTypePrimary().get(type)!));

        if (media.length > 0 && event.media) return media.indexOf(event.media.typeNumber) > -1;
        else return media.length == 0;
    }

    private typeUpdated(event: EventHistory) {
        if (this.typeSelected.length > 0) return this.typeSelected.indexOf(event.type) > -1;
        return true;
    }

    private async getSites() {
        return this.siteRepository()
            .getUserSite()
            .then(userSites => {
                this.sites = userSites;
                const querySite = this.$route.query.site;
                if (querySite) {
                    this.siteSelected = this.sites.filter(site => site.reference == querySite);
                    this.siteSelectedVuetify = this.sites
                        .filter(site => site.reference == querySite)
                        .map(site => site.reference + '-' + site.name);
                }
            });
    }

    private warningToast(warnings: string[]) {
        if (warnings.length > 0) {
            this.toastBus().add({
                status: ToastStatus.ERROR,
                text:
                    warnings.length > 1
                        ? "Votre historique a été tronqué car il a généré plus de 10000 évènements dans la période demandée. Merci de diminuer la plage de date pour réduire le nombre d'évènement à afficher."
                        : warnings[0],
            });
        }
    }
}
