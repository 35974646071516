import { DataTableHeader } from 'vuetify';

export const mobileAccessHeader = (): DataTableHeader[] => [
    { text: 'Etat', value: 'state', sortable: false, filterable: false },
    { text: 'Type', value: 'type', sortable: false, filterable: false },
    { text: 'Activé le', value: 'activatedAt', sortable: false, filterable: false },
    { text: 'Désactivé le', value: 'deactivatedAt', sortable: false, filterable: false },
    { text: 'Première connexion', value: 'firstConnexion', sortable: false, filterable: false },
    { text: 'Dernière connexion', value: 'lastConnexion', sortable: false, filterable: false },
];
