export enum Right {
    ADD_USER = 'ADD_USER',
    DEL_USER = 'DEL_USER',
    VIEW_USER = 'VIEW_USER',
    RS_SIT_LST = 'RS_SIT_LST',
    CNS_HIS = 'CNS_HIS',
    CNS_VID = 'CNS_VID',
    CNS_CRO = 'CNS_CRO',
    CNS_ALA = 'CNS_ALA',
    CNS_CSG = 'CNS_CSG',
    DEM_MOD_TSL = 'DEM_MOD_TSL',
    CNS_HOR = 'CNS_HOR',
    CNS_PAT = 'CNS_PAT',
    ADD_PAT_OD = 'ADD_PAT_OD',
    RS_DEPH_LST = 'RS_DEPH_LST',
    RS_TLM_LST = 'RS_TLM_LST',
    RS_VEP_LST = 'RS_VEP_LST',
    RS_VEH_LST = 'RS_VEH_LST',
    RS_TN_AVCHA = 'RS_TN_AVCHA',
    RS_DEVA_LST = 'RS_DEVA_LST',
    HAS_SITES = 'HAS_SITES',
}
