import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { videoInstructionHeader } from '@/domain/header/videoHeader';
import { Patrol } from '@/domain/video/Patrol';
import { VideoInstruction } from '@/domain/video/VideoInstruction';
import { VideoInstructionRepository } from '@/domain/video/VideoInstructionRepository';
import { IconVue } from '@/primary/components/common/icon';

@Component({
    components: {
        IconVue,
    },
})
export default class VideoInstructionComponent extends Vue {
    @Prop({ required: true })
    private siteReference!: string;

    @Inject()
    private videoInstructionRepository!: () => VideoInstructionRepository;

    headers: DataTableHeader[] = videoInstructionHeader();
    patrols: Patrol[] = [];
    videoInstructions: VideoInstruction[] = [];
    tab = 0;

    @Watch('siteReference')
    getPatrols() {
        this.videoInstructionRepository()
            .getPatrols(this.siteReference)
            .then(patrols => {
                this.patrols = patrols;
                this.tab = 0;
                this.getInstructions();
            });
    }

    getInstructions() {
        this.videoInstructionRepository()
            .getInstructions(this.siteReference, this.patrols[this.tab].reference)
            .then(videoInstructions => {
                this.videoInstructions = videoInstructions;
            });
    }
}
