import { IRule } from './IRule';

export class ValueMatchRule implements IRule {
    passed: boolean;
    description: string;
    getValue: () => string;
    showDescription: boolean;
    descriptionMobile: string;

    constructor(getValue: () => string, description: string, showDescription: boolean) {
        this.description = description;
        this.descriptionMobile = description;
        this.passed = false;
        this.showDescription = showDescription;
        this.getValue = getValue;
    }

    check(value: string): boolean | string {
        this.passed = this.getValue() === value;

        return this.passed ? this.passed : this.description;
    }
}
