import { MailAlertSubscription } from '@/domain/user/MailAlertSubscription';

export enum RestMailAlertSubscription {
    ALA = 'ALA',
    MES = 'MES',
    MHS = 'MHS',
    TST = 'TST',
}

export const toRestMailAlertSubscription = (mailAlertSubscription: MailAlertSubscription): RestMailAlertSubscription => {
    if (mailAlertSubscription == MailAlertSubscription.ALA) return RestMailAlertSubscription.ALA;
    if (mailAlertSubscription == MailAlertSubscription.TST) return RestMailAlertSubscription.TST;
    if (mailAlertSubscription == MailAlertSubscription.MHS) return RestMailAlertSubscription.MHS;
    return RestMailAlertSubscription.MES;
};

export const fromRestMailAlertSubscription = (restMailAlertSubscription: RestMailAlertSubscription): MailAlertSubscription => {
    if (restMailAlertSubscription == RestMailAlertSubscription.ALA) return MailAlertSubscription.ALA;
    if (restMailAlertSubscription == RestMailAlertSubscription.TST) return MailAlertSubscription.TST;
    if (restMailAlertSubscription == RestMailAlertSubscription.MHS) return MailAlertSubscription.MHS;
    return MailAlertSubscription.MES;
};
