import { AxiosError } from 'axios';
import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';

import { CallList } from '@/domain/contact/CallList';
import { Contact } from '@/domain/contact/Contact';
import { ContactInformationsModify, emptyContactInformationsModify } from '@/domain/contact/ContactInformationsModify';
import { ContactRepository } from '@/domain/contact/ContactRepository';
import { Site } from '@/domain/contact/Site';
import { Right } from '@/domain/menu/Right';
import { Step } from '@/domain/step/Step';
import { TimeGridSiteGroup } from '@/domain/timeGrid/TimeGridSiteGroup';
import { CallListVue } from '@/primary/components/callList';
import { IconVue } from '@/primary/components/common/icon';
import { GridTimeListVue } from '@/primary/components/gridTimeList';
import { ModifyCallListModalFirstStepVue } from '@/primary/components/modifyCallListModal/modifyCallListModalFirstStep';
import { ModifyCallListModalSecondStepVue } from '@/primary/components/modifyCallListModal/modifyCallListModalSecondStep';
import { ModifyCallListModalThirdStepVue } from '@/primary/components/modifyCallListModal/modifyCallListModalThirdStep';
import { ModifyContactModalFirstStepVue } from '@/primary/components/ModifyContactModal/ModifyContactModalFirstStep';
import { ModifyContactModalFourthStepVue } from '@/primary/components/ModifyContactModal/ModifyContactModalFourthStep';
import { ModifyContactModalSecondStepVue } from '@/primary/components/ModifyContactModal/ModifyContactModalSecondStep';
import { ModifyContactModalThirdStepVue } from '@/primary/components/ModifyContactModal/ModifyContactModalThirdStep';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import { ModifyPhoneContactVue } from '@/primary/components/modifyPhoneContact';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { Tab } from '@/primary/components/navbar/Tab';
import TabsNavigationBus from '@/primary/components/navbar/TabsNavigationBus';
import TabsNumberNavigationBus from '@/primary/components/navbar/TabsNumberNavigationBus';
import { ContactWithSites } from '@/primary/pages/Instructions/ContactWithSites';
import ModifyInformationsContactBus from '@/primary/pages/Instructions/ModifyInformationsContactBus';
import { SiteWithCallList } from '@/primary/pages/Instructions/SiteWithCallList';
import { RightMixin } from '@/primary/utils/rights/RightMixin';
import { CallListStoreRepository } from '@/secondary/store/callListStore/CallListStoreRepository';

@Component({
    components: {
        IconVue,
        ModifyPhoneContactVue,
        ModifyContactModalFirstStepVue,
        ModifyContactModalSecondStepVue,
        ModifyContactModalThirdStepVue,
        ModifyContactModalFourthStepVue,
        ModifyCallListModalFirstStepVue,
        ModifyCallListModalSecondStepVue,
        ModifyCallListModalThirdStepVue,
        CallListVue,
        GridTimeListVue,
    },
})
export default class InstructionsPageComponent extends mixins(RightMixin) {
    @Inject()
    private contactRepository!: () => ContactRepository;

    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private modifyInformationsContactBus!: () => ModifyInformationsContactBus;

    @Inject()
    private tabsNavigationBus!: () => TabsNavigationBus;

    @Inject()
    private tabsNumberNavigationBus!: () => TabsNumberNavigationBus;

    @Inject()
    private callListStoreRepository!: () => CallListStoreRepository;

    activeStep = 1;
    steps: Step[] = [
        { value: 1, text: 'Suppression ou remplacement' },
        { value: 2, text: 'Dates et coordonnées du demandeur' },
        { value: 3, text: 'Récapitulatif de ma demande' },
    ];

    stepsCallListModal: Step[] = [
        { value: 1, text: 'Liste d’appels et prestations' },
        { value: 2, text: 'Dates et coordonnées du demandeur' },
        { value: 3, text: 'Récapitulatif de ma demande' },
    ];
    modifyContactModalIsOpen = false;
    modifyCallListModalIsOpen = false;
    search = '';
    headers = [
        {
            text: 'Nom Prénom',
            value: 'name',
            filterable: true,
            sortable: true,
        },
        {
            text: 'Site(s)',
            value: 'sites',
            filterable: false,
            sortable: false,
        },
        {
            text: 'Numéro de téléphone',
            value: 'phone',
            filterable: false,
            sortable: false,
        },
    ];
    sites: Site[] = [];
    hasModifyContact = false;
    navigationButtonBusId = 0;
    modifyInformationsContactBusId = 0;
    modifyInformationsContact: ContactInformationsModify = emptyContactInformationsModify();
    reloadKeyModal = 0;
    siteSelected: Site[] = [];
    contactsSelected: ContactWithSites[] = [];
    reloadContactTable = 0;
    callList: SiteWithCallList[] = [];
    updateCallList: CallList[] = [];
    timeGridSiteGroup: TimeGridSiteGroup[][] = [];
    reloadCallList = 0;
    tab = 0;
    tabs: Tab[] = [
        { name: "Listes d'appels", right: Right.CNS_CSG },
        { name: 'Horaires', right: Right.CNS_HOR },
    ];
    tabsNumberNavigationBusId = 0;
    updateSiteReference = '';
    isLoading: boolean[] = [];
    displaySites = false;
    selectedContactSites: string[] = [];
    collapsed: boolean[] = [];

    mounted() {
        this.pageNameBus().add('Mes Consignes');
        this.tabsNavigationBus().add(this.tabs);
        this.tabsNumberNavigationBusId = this.tabsNumberNavigationBus().subscribe(value => {
            this.tab = value!;
        });
        this.navigationButtonBusId = this.navigationButtonBus().subscribe(step => {
            this.activeStep = step!;
        });
        this.modifyInformationsContactBusId = this.modifyInformationsContactBus().subscribe(data => {
            this.modifyInformationsContact = data!;
            this.reloadKeyModal++;
        });
        this.contactRepository()
            .getContacts()
            .then(data => {
                this.fromDomain(data);
            })
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    destroyed() {
        this.tabsNavigationBus().add([]);
        this.tabsNumberNavigationBus().unsubscribe(this.tabsNumberNavigationBusId);
        this.navigationButtonBus().unsubscribe(this.navigationButtonBusId);
        this.modifyInformationsContactBus().unsubscribe(this.modifyInformationsContactBusId);
        this.contactRepository().interruptGetCallList();
    }

    get siteOrdered() {
        return this.sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => {
                const hasA = this.siteSelected.find(site => site === a) !== undefined;
                const hasB = this.siteSelected.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    switchSite(site: Site) {
        const index = this.siteSelected.indexOf(site);
        if (index != -1) this.siteSelected.splice(index, 1);
        else this.siteSelected.push(site);
    }

    openModifyContactModal() {
        this.modifyContactModalIsOpen = true;
    }

    openModifyCallListModal(callList: CallList[], siteReference: string) {
        this.updateCallList = callList;
        this.updateSiteReference = siteReference;
        this.modifyCallListModalIsOpen = true;
    }

    closeModal() {
        this.modifyContactModalIsOpen = false;
        this.reloadKeyModal++;
        this.activeStep = 1;
        this.modifyInformationsContact = emptyContactInformationsModify();
    }

    closeModalSites() {
        this.displaySites = false;
        this.selectedContactSites = [];
    }

    openSiteModal(contact: ContactWithSites) {
        this.selectedContactSites = contact.sites;
        this.displaySites = true;
    }

    closeModalCallList() {
        this.modifyCallListModalIsOpen = false;
        this.activeStep = 1;
        this.callListStoreRepository().removeCallListModify();
    }

    reloadContact() {
        this.siteSelected = this.siteSelected.filter(site => this.siteOrdered.includes(site));
        this.contactsBySelectedSite(this.siteSelected);
    }

    updateContact(contact: ContactWithSites) {
        const index = this.contactsSelected.findIndex(contactSelected => {
            return contactSelected.name === contact.name;
        });
        this.contactsSelected[index] = contact;
        this.reloadContactTable++;
    }

    isActive(site: Site): boolean {
        return this.siteSelected.filter(s => s.reference === site.reference).length > 0;
    }

    cancelLoading(index: number) {
        if (index == 1) this.contactRepository().interruptGetCallList();
    }

    swapCollaps(index: number) {
        this.collapsed[index] = !this.collapsed[index];
        this.getCallList(index);
    }

    get hasRightMod(): boolean {
        return this.hasRight(Right.DEM_MOD_TSL);
    }

    private fromDomain(sites: Site[]) {
        this.sites = sites;
        this.siteSelected = [...sites];
        this.callList = this.sites.map(site => ({ ...site, callList: [] }));
        this.sites.forEach(() => {
            this.collapsed.push(true);
            this.isLoading.push(false);
        });
        this.contactsBySelectedSite(sites);
    }

    private contactsBySelectedSite(sites: Site[]) {
        this.contactRepository().interruptGetCallList();
        const contacts: Map<String, ContactWithSites> = new Map();
        sites.forEach(site => {
            site.contacts.forEach(contact => {
                const key = this.concatContact(contact);
                const c = contacts.get(key);
                if (c) c.sites.push(site.name);
                else contacts.set(key, { ...contact, sites: [site.name] });
            });
        });
        this.contactsSelected = [...contacts.values()].sort((a, b) => a.name.localeCompare(b.name));
    }

    private concatContact(contact: Contact) {
        return contact.name + contact.phone + contact.email;
    }

    private getCallList(index: number) {
        this.reloadCallList++;
        this.callList.forEach((site, i) => {
            if (index == i) {
                this.isLoading[index] = true;
                this.contactRepository()
                    .getCallList(site.reference)
                    .then(callListData => {
                        site.callList = callListData;
                        this.isLoading[index] = false;
                        this.reloadCallList++;
                    })
                    .catch((error: AxiosError) => {
                        console.log(error.message);
                    });
            }
        });
    }
}
