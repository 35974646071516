import { DataTableHeader } from 'vuetify';

export const troubleshootingMaintenanceHeader = (): DataTableHeader[] => [
    {
        text: 'N° Bon',
        align: 'start',
        value: 'id',
        sortable: false,
        filterable: true,
    },
    { text: 'Activité', value: 'activity', sortable: true, filterable: true },
    { text: 'Site', value: 'siteName', sortable: true, filterable: true },
    { text: 'Ville', value: 'city', sortable: true, filterable: true },
    { text: 'Motif', value: 'cause', sortable: false, filterable: true },
    {
        text: 'Date demande',
        value: 'interventionDate',
        sortable: true,
        filterable: false,
    },
    {
        text: 'Date intervention',
        value: 'responseDate',
        sortable: true,
        filterable: false,
    },
    { text: 'Facturable', value: 'billable', sortable: true, filterable: false },
    { text: 'Détail', value: 'isOngoing', sortable: false, filterable: false },
];

export const troubleshootingMaintenanceHeaderMobile = (): DataTableHeader[] => [
    {
        text: 'N° Bon',
        align: 'start',
        value: 'id',
        sortable: false,
        filterable: true,
    },
    { text: 'Activité', value: 'activity', sortable: false, filterable: true },
    { text: 'Site', value: 'siteName', sortable: false, filterable: true },
    { text: 'Ville', value: 'city', sortable: false, filterable: true },
    { text: 'Motif', value: 'cause', sortable: false, filterable: true },
    {
        text: 'Date demande',
        value: 'interventionDate',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Date intervention',
        value: 'responseDate',
        sortable: false,
        filterable: false,
    },
    { text: 'Facturable', value: 'billable', sortable: false, filterable: false },
    { text: 'Détail', value: 'detail', sortable: false, filterable: false },
];
