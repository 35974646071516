export interface SiteDetailsDescription {
    siteReference: string;
    socialReason: string;
    address: string;
    postalCode: string;
    city: string;
}

export const emptySiteDetailsDescription = (): SiteDetailsDescription => ({
    siteReference: '',
    socialReason: '',
    address: '',
    postalCode: '',
    city: '',
});
