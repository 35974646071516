import { EventHistoryMedia } from '@/domain/event/Event';
import { RestEventAttachmentDetail, toEventHistoryMediaDetail } from '@/secondary/restEvent/RestEventAttachementDetail';

export interface RestEventAttachment {
    Type: number;
    Details: RestEventAttachmentDetail[] | null;
    Id: string | null;
}

export const toEventHistoryMedia = (restEventAttachment: RestEventAttachment): EventHistoryMedia => ({
    type: toTypeEventAttachment().get(restEventAttachment.Type)!,
    typeFilter: TypeFilterEventAttachment[restEventAttachment.Type],
    icon: toIconEventHistoryMedia(restEventAttachment.Type),
    id: restEventAttachment.Id ? restEventAttachment.Id : undefined,
    details: restEventAttachment.Details ? restEventAttachment.Details.map(toEventHistoryMediaDetail) : undefined,
    typeNumber: restEventAttachment.Type,
});

enum TypeFilterEventAttachment {
    'Aucun',
    'Rapport Télésurveillance',
    'Rapport Télésurveillance avec envoi intervenant',
    'Rapport Télémaintenance',
    'Photo',
    'Video',
}

const toTypeEventAttachment = (): Map<number, string> =>
    new Map([
        [1, 'file'],
        [2, 'file'],
        [3, 'file'],
        [4, 'image'],
        [5, 'video'],
    ]);

const toIconEventHistoryMedia = (restEventAttachmentType: number): string => {
    switch (restEventAttachmentType) {
        case 1:
        case 2:
        case 3: {
            return 'icon-file';
        }
        case 4: {
            return 'icon-image';
        }
        case 5: {
            return 'icon-video3';
        }
        default: {
            return '';
        }
    }
};
