import { Component, Inject, Vue } from 'vue-property-decorator';
import { VAlert } from 'vuetify/lib';

import { IToast } from '@/domain/toast/IToast';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import ToastBus from '@/primary/utils/ToastBus';

@Component({
    components: {
        VAlert,
    },
})
export default class Toast extends Vue {
    @Inject()
    private toastBus!: () => ToastBus;

    toastBusId = 0;
    toast = false;
    toastModel: IToast = { status: ToastStatus.SUCCESS, text: '' };

    mounted() {
        this.toastBusId = this.toastBus().subscribe(toast => {
            if (toast) {
                this.toast = true;
                this.toastModel = toast;
                setTimeout(() => {
                    this.toast = false;
                }, 5000);
            }
        });
    }
}
