import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { RestTroubleshootingMaintenance, toTroubleshootingMaintenance } from './RestTroubleshootingMaintenance';

import { ExportTroubleshootingMaintenanceParam } from '@/domain/troubleshooting/ExportTroubleshootingMaintenanceParam';
import { ITroubleshootingMaintenance } from '@/domain/troubleshooting/ITroubleshootingMaintenance';
import { ITroubleshootingMaintenanceRepository } from '@/domain/troubleshooting/ITroubleshootingMaintenanceRepository';
import { downloadFile } from '@/secondary/DownloadFile';

export class RestTroubleshootingMaintenanceRepository implements ITroubleshootingMaintenanceRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    get(): Promise<ITroubleshootingMaintenance[]> {
        return this.axiosInstance
            .get('/Troubleshootings/Maintenances/OnSite')
            .then((response: AxiosResponse<RestTroubleshootingMaintenance[]>) => {
                return response.data.map(toTroubleshootingMaintenance);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getExport(type: string, exportParams: ExportTroubleshootingMaintenanceParam): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .post(`/Troubleshootings/Maintenances/OnSite/Export/${type}`, exportParams, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getOngoingDetailsReport(interventionNumber: string): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .get(`/Ongoing/Intervention/${interventionNumber}/Details/Export`, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getCompletedDetailsReport(interventionNumber: string): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .get(`/Completed/Intervention/${interventionNumber}/Details/Export`, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }
}
