import { IWorkSite } from '@/domain/workSite/IWorkSite';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export interface RestWorkSite {
    Number: string;
    SiteReference: string;
    SiteName: string;
    City: string;
    Activity: string;
    ExpectedStartDate: string;
    ExpectedEndDate: string;
    Progress: number;
}

export const toWorkSite = (restWorkSite: RestWorkSite): IWorkSite => ({
    id: restWorkSite.Number,
    siteReference: restWorkSite.SiteReference,
    siteName: stringCleaner(restWorkSite.SiteName),
    city: restWorkSite.City,
    activity: restWorkSite.Activity,
    expectedStartDate: restWorkSite.ExpectedStartDate,
    expectedEndDate: restWorkSite.ExpectedEndDate,
    progress: restWorkSite.Progress * 100,
});
