import { TimeGridSchedule } from '@/domain/timeGrid/TimeGridSchedule';
import { RestTimeSlot, toRestTimeSlot, toTimeSlot } from '@/secondary/restTimeGrid/RestTimeSlot';

export interface RestTimeGridSchedule {
    Reference: string;
    Date: string;
    Closed: boolean;
    TimeSlots: RestTimeSlot[];
}

export const toTimeGridSchedule = (restTimeGridSchedule: RestTimeGridSchedule): TimeGridSchedule => ({
    reference: restTimeGridSchedule.Reference,
    timeSlots: toTimeSlot(restTimeGridSchedule),
});

export const toRestTimeGridSchedule = (timeGridSchedule: TimeGridSchedule): any => ({
    ScheduleReference: timeGridSchedule.reference,
    TimeSlots: toRestTimeSlot(timeGridSchedule),
    Closed: timeGridSchedule.timeSlots.length === 0,
});
