export class RestThemeRepository {
    private static wasInSurway = false;
    // Check if is on Surway
    private static isSurwayProd = window.location.href.includes('surway');

    public static isItesis(): boolean {
        // Enable route theming only for local and testing environnement, and keep information on navigation
        this.wasInSurway =
            ((window.location.href.includes('1337') || window.location.href.includes('9443') || window.location.href.includes('9444')) &&
                window.location.href.includes('theme=SURWAY')) ||
            this.wasInSurway;

        // Is Itesis if was not in debug Surway or prod Surway
        return !this.wasInSurway && !this.isSurwayProd;
    }

    public static getNameOfSite(): string {
        return RestThemeRepository.isItesis() ? 'ITESIS' : 'SURWAY';
    }
}
