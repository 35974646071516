import { StoreAccount } from '@/secondary/restAccount/StoreAccount';

interface State {
    user: StoreAccount;
    hasSites: boolean;
}

export const localStorageModule = () => ({
    namespaced: true,
    state: () => ({
        user: {},
        hasSites: false,
    }),
    getters: {
        user: (state: State) => state.user,
        rights: (state: State) => state.user.rights,
        hasSites: (state: State) => state.hasSites,
    },
    mutations: {
        modifyUser(state: State, user: StoreAccount) {
            state.user = user;
        },
        modifyUserRights(state: State, rights: string[]) {
            state.user.rights = rights;
        },
        modifyHasSites(state: State, hasSites: boolean) {
            state.hasSites = hasSites;
        },
    },
});
