import moment from 'moment/moment';

import { HumanSupervision } from '@/domain/humanSupervision/HumanSupervision';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export interface RestHumanSupervision {
    Id: string;
    ClientReference: string;
    SiteReference: string;
    SiteName: string;
    City: string;
    Date: Date;
    Report: string;
    CallTime: Date;
    DepartureDate: Date;
    BillingState: string;
    BillingMode: string;
}

export const toHumanSupervision = (restHumanSupervision: RestHumanSupervision): HumanSupervision => ({
    sites: stringCleaner(restHumanSupervision.SiteName),
    sitesReferences: restHumanSupervision.SiteReference,
    cities: restHumanSupervision.City,
    interventionNumber: restHumanSupervision.Id,
    interventionDate: moment(new Date(restHumanSupervision.Date)).locale('fr').format('L'),
    interventionHour: moment(new Date(restHumanSupervision.Date)).locale('fr').format('LT'),
    callHour: moment(new Date(restHumanSupervision.CallTime)).locale('fr').format('LT'),
    arrivingHour: moment(new Date(restHumanSupervision.Date)).locale('fr').format('LT'),
    goingHour: moment(new Date(restHumanSupervision.DepartureDate)).locale('fr').format('LT'),
    facturationState: restHumanSupervision.BillingState,
    interventionReport: restHumanSupervision.Report,
    expanded: false,
});
