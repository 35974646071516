import { VideoCredit } from '@/domain/video/VideoCredit';

export interface TableVideoCredit {
    period: string;
    credits: number;
    engaged: number;
    balance: number;
}

export const toTableVideoCredit = (videoCredit: VideoCredit): TableVideoCredit => ({
    period: videoCredit.periodLabel,
    credits: videoCredit.credits,
    engaged: videoCredit.engaged,
    balance: videoCredit.balance,
});
