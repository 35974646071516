import { DataTableHeader } from 'vuetify';

export const eventsHeader = (): DataTableHeader[] => [
    { text: "Type(s) d'événement(s)", align: 'start', value: 'type', sortable: true, filterable: true },
    { text: 'Date', value: 'date', sortable: true, filterable: true },
    { text: 'Site', value: 'site', sortable: true, filterable: true },
    { text: 'Description', value: 'description', sortable: false, filterable: true },
    { text: 'Pièce jointe', value: 'media.type', sortable: true, filterable: true, class: 'file', cellClass: 'file' },
];

export const eventsHeaderMobile = (): DataTableHeader[] => [
    { text: 'Site', value: 'site', sortable: false, filterable: true },
    { text: 'Type(s)', align: 'start', value: 'type', sortable: false, filterable: true },
    { text: 'Date', value: 'date', sortable: false, filterable: true },
    { text: 'Description', value: 'description', sortable: false, filterable: true },
    { text: 'Fichier', value: 'media.type', sortable: false, filterable: true, class: 'file', cellClass: 'file' },
];

export const eventsHeaderDetails = (): DataTableHeader[] => [
    { text: 'Date', value: 'date', sortable: true, filterable: true },
    { text: 'Action ou rapport', value: 'label', sortable: true, filterable: true },
    { text: 'Compléments', value: 'details', sortable: true, filterable: true },
];
