import { DataTableHeader } from 'vuetify';

export const workSiteHeader = (): DataTableHeader[] => [
    { text: 'N° Chantier', align: 'start', value: 'id', sortable: false, filterable: true },
    { text: 'N° de site', value: 'siteReference', sortable: false, filterable: true },
    { text: 'Site(s)', value: 'siteName', sortable: true, filterable: true },
    { text: 'Ville(s)', value: 'city', sortable: true, filterable: true },
    { text: 'Date de début prévue', value: 'expectedStartDate', sortable: true, filterable: false },
    { text: 'Date de fin prévue', value: 'expectedEndDate', sortable: true, filterable: false },
    { text: 'Avancement', value: 'progress', sortable: true, filterable: false },
    { text: 'Activité', value: 'activity', sortable: true, filterable: false },
    { text: 'Détail', value: 'detail', sortable: false, filterable: false },
];

export const workSiteHeaderMobile = (): DataTableHeader[] => [
    { text: 'N° Chantier', align: 'start', value: 'worksite', sortable: false, filterable: false },
    { text: 'N° de site', value: 'siteNumber', sortable: false, filterable: false },
    { text: 'Site(s)', value: 'site', sortable: false, filterable: true },
    { text: 'Ville(s)', value: 'city', sortable: false, filterable: true },
    { text: 'Date de début prévue', value: 'startDate', sortable: false, filterable: true },
    { text: 'Date de fin prévue', value: 'endDate', sortable: false, filterable: true },
    { text: 'Avancement', value: 'progress', sortable: false, filterable: true },
    { text: 'Activité', value: 'activity', sortable: false, filterable: true },
    { text: 'Détail', value: 'detail', sortable: false, filterable: false },
];
