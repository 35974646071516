export interface Contact {
    name: string;
    phone: string;
    phone2?: string;
    phone3?: string;
    phone4?: string;
    email: string;
    isReadonly: boolean;
}

export const emptyContact = (): Contact => ({
    name: '',
    phone: '',
    email: '',
    isReadonly: false,
});
