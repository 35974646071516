import { IPlannedDetailInspection } from '@/domain/inspection/IPlannedDetailInspection';
import { IMonths, IPlannedInspection } from '@/domain/inspection/IPlannedInspection';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export interface RestPlannedInspection {
    InspectionsByMonth: [Inspections];
}

export interface RestPlannedDetailInspection {
    ContractNumber: string;
    SiteReference: string;
    ApplicationDate: string;
    ClientCode: string;
    Activity: string;
    SiteName: string;
    City: string;
    InspectionsByMonth: InspectionsBool[];
}

export interface Inspections {
    Expected: number;
    Planned: number;
    Completed: number;
}

export interface InspectionsBool {
    Expected: boolean;
    Planned: boolean;
    Completed: boolean;
}

export const toInspectionSummary = (summary: RestPlannedInspection): IPlannedInspection => ({
    expected: getMonthValues(summary.InspectionsByMonth.map(insp => insp.Expected)),
    planned: getMonthValues(summary.InspectionsByMonth.map(insp => insp.Planned)),
    completed: getMonthValues(summary.InspectionsByMonth.map(insp => insp.Completed)),
});

export const toInspectionDetailSummary = (summary: RestPlannedDetailInspection): IPlannedDetailInspection => ({
    id: summary.ContractNumber,
    siteReference: summary.SiteReference,
    applicationDate: summary.ApplicationDate,
    clientNumber: summary.ClientCode,
    activity: summary.Activity,
    siteName: stringCleaner(summary.SiteName),
    city: summary.City,
    inspections: getInspectionValues(summary.InspectionsByMonth),
});

const getInspectionValues = (values: InspectionsBool[]) => {
    const months: IMonths = {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
        total: 0,
    };
    const keys = Object.keys(months);
    values.forEach((value, index) => {
        if (value.Expected && !value.Planned && !value.Completed) months[keys[index]] = 'icon-expected';
        if ((value.Expected && value.Planned && !value.Completed) || (!value.Expected && value.Planned && !value.Completed))
            months[keys[index]] = 'icon-planned';
        if ((value.Expected || value.Planned) && value.Completed) months[keys[index]] = 'icon-completed';
    });
    return months;
};

const getMonthValues = (values: number[]) => {
    const months: IMonths = {
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        total: 0,
    };

    const keys = Object.keys(months);

    values.forEach((value, index) => {
        months[keys[index]] = Math.abs(value);
        months['total'] += Math.abs(value);
    });
    return months;
};
