import { DataTableHeader } from 'vuetify';

export const contactCallListModalHeader: DataTableHeader[] = [
    { text: '', value: '' },
    {
        text: "Ordre d'appel",
        value: 'order',
    },
    {
        text: 'Contacts à prévenir',
        value: 'name',
    },
];

export const contactCallListModalHeaderReview: DataTableHeader[] = [
    {
        text: "Ordre d'appel",
        value: 'order',
    },
    {
        text: 'Contacts à prévenir',
        value: 'name',
    },
    {
        text: 'Conditions',
        value: 'condition',
    },
];

export const contactCallListModalHeaderReviewCreated: DataTableHeader[] = [
    {
        text: 'Contacts',
        value: 'name',
    },
    {
        text: 'Téléphone',
        value: 'phone',
    },
];

export const prestationCallListModalHeader: DataTableHeader[] = [
    {
        text: 'Prestations',
        value: 'label',
    },
];
