import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

import { IRule } from '@/domain/rules/IRule';

@Component
export class FormBasePage extends Vue {
    public allRulesPassed(rules: Array<IRule>, value = '', update = false): boolean {
        let ok = true;
        for (const rule of rules) {
            if (update) {
                rule.check(value);
            }

            ok = ok && rule.passed;
        }

        return ok;
    }

    public getRulesToDisplay(rules: Array<IRule>): Array<IRule> {
        return rules.filter(r => r.showDescription);
    }

    public getRules(rules: Array<IRule>): Array<(v: string) => boolean | string> {
        return rules.map(r => (v: string) => r.check(v));
    }
}
