import { Device } from '@/domain/user/Device';
import { DeviceType } from '@/domain/user/DeviceType';

export interface RestDevice {
    Serial: string;
    Type: DeviceType;
    FirstConnection?: Date;
    LastConnection?: Date;
    Activation?: Date;
    Deactivation?: Date;
    ActivationState: number;
    ActivationStateValue: string;
}

export const toDevice = (restDevice: RestDevice): Device => ({
    id: restDevice.Serial,
    type: restDevice.Type,
    firstConnexion: restDevice.FirstConnection,
    lastConnexion: restDevice.LastConnection,
    activatedAt: restDevice.Activation,
    deactivatedAt: restDevice.Deactivation,
    state: restDevice.ActivationState == 1,
});
