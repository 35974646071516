import { DoubleAuthType } from '@/domain/userManagement/DoubleAuthType';
import { ManagedUser } from '@/domain/userManagement/ManagedUser';
import { Profil } from '@/domain/userManagement/Profil';
import { RestSimplifiedSite, toRestSimplifiedSite } from '@/secondary/userManagement/RestSimplifiedSite';

export interface RestManagedUser {
    FirstName: string;
    LastName: string;
    Email: string;
    MobilePhoneNumber: string;
    Profile: number;
    TwoFactorAuthenticationMode: number;
    VisibleSites: RestSimplifiedSite[];
}

const fromProfilMap = new Map<Profil, number>([
    [Profil.Unknown, 0],
    [Profil.Reader, 1],
    [Profil.Manager, 2],
    [Profil.Administrator, 3],
]);

const fromDoubleAuthMap = new Map<DoubleAuthType, number>([
    [DoubleAuthType.NO_DOUBLE_AUTH, 0],
    [DoubleAuthType.SMS, 1],
    [DoubleAuthType.MAIL, 2],
]);

export const toRestManagedUser = (user: ManagedUser): RestManagedUser => ({
    FirstName: user.firstName,
    LastName: user.lastName,
    Email: user.email,
    MobilePhoneNumber: user.phone,
    Profile: fromProfilMap.get(user.profil)!,
    TwoFactorAuthenticationMode: fromDoubleAuthMap.get(user.doubleAuthType)!,
    VisibleSites: user.sites.map(toRestSimplifiedSite),
});
