import { MailAlertSubscription } from '@/domain/user/MailAlertSubscription';
import { UserAlerts } from '@/domain/user/UserAlerts';
import { emptyUserMailAlert, UserMailAlert } from '@/domain/user/UserMailAlert';
import { fromRestMailAlertFrequency, RestMailAlertFrequency, toRestMailAlertFrequency } from '@/secondary/restUser/RestMailAlertFrequency';
import {
    fromRestMailAlertSubscription,
    RestMailAlertSubscription,
    toRestMailAlertSubscription,
} from '@/secondary/restUser/RestMailAlertSubscription';
import { fromRestMailAlertType, RestMailAlertType, toRestMailAlertType } from '@/secondary/restUser/RestMailAlertType';

export interface RestUserAlert {
    Subscription: RestMailAlertSubscription;
    Frequency: RestMailAlertFrequency;
    MailType: RestMailAlertType;
    IsActive: boolean;
}

export const toRestUserAlerts = (userAlerts: UserAlerts): RestUserAlert[] => {
    const result: RestUserAlert[] = [];
    addRowIfSubscribed(result, userAlerts.alarm);
    addRowIfSubscribed(result, userAlerts.missingTests);
    addRowIfSubscribed(result, userAlerts.abnormalCommissioning);
    addRowIfSubscribed(result, userAlerts.missingCommissioning);
    return result;
};

export const toUserAlerts = (restUserAlerts: RestUserAlert[]): UserAlerts => {
    const userMailAlerts: UserMailAlert[] = restUserAlerts.map(toUserAlert);

    return {
        missingTests: getMissingTests(userMailAlerts, MailAlertSubscription.TST),
        missingCommissioning: getMissingTests(userMailAlerts, MailAlertSubscription.MES),
        abnormalCommissioning: getMissingTests(userMailAlerts, MailAlertSubscription.MHS),
        alarm: getMissingTests(userMailAlerts, MailAlertSubscription.ALA),
    };
};

const toUserAlert = (restUserAlert: RestUserAlert): UserMailAlert => ({
    alertType: fromRestMailAlertSubscription(restUserAlert.Subscription),
    frequency: fromRestMailAlertFrequency(restUserAlert.Frequency),
    type: fromRestMailAlertType(restUserAlert.MailType),
    subscribed: restUserAlert.IsActive,
});

const getMissingTests = (userMailAlerts: UserMailAlert[], mailAlertSubscription: MailAlertSubscription) =>
    userMailAlerts.find(userMailAlert => userMailAlert.alertType == mailAlertSubscription) ?? emptyUserMailAlert(mailAlertSubscription);

const toRestUserAlert = (userMailAlert: UserMailAlert): RestUserAlert => ({
    Subscription: toRestMailAlertSubscription(userMailAlert.alertType),
    Frequency: toRestMailAlertFrequency(userMailAlert.frequency),
    MailType: toRestMailAlertType(userMailAlert.type),
    IsActive: userMailAlert.subscribed,
});

const addRowIfSubscribed = (result: RestUserAlert[], userAlert: UserMailAlert): void => {
    if (userAlert.subscribed) result.push(toRestUserAlert(userAlert));
};
