import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';

import { Right } from '@/domain/menu/Right';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { Tab } from '@/primary/components/navbar/Tab';
import TabsNavigationBus from '@/primary/components/navbar/TabsNavigationBus';
import TabsNumberNavigationBus from '@/primary/components/navbar/TabsNumberNavigationBus';
import { SettingsVue } from '@/primary/components/settings';
import { UserManagementPageVue } from '@/primary/pages/userManagement';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
        UserManagementPageVue,
        SettingsVue,
    },
})
export default class SettingsPageComponent extends mixins(RightMixin) {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private tabsNavigationBus!: () => TabsNavigationBus;

    @Inject()
    private tabsNumberNavigationBus!: () => TabsNumberNavigationBus;

    tabs: Tab[] = [];
    tabsNumberNavigationBusId = 0;
    tab = 0;

    mounted() {
        if (this.hasRight(Right.VIEW_USER)) this.tabs = [{ name: 'Mon compte' }, { name: 'Gestion utilisateurs' }];
        this.pageNameBus().add('Mes paramètres');

        this.tabsNavigationBus().add(this.tabs);
        this.tabsNumberNavigationBusId = this.tabsNumberNavigationBus().subscribe(value => {
            this.tab = value!;
        });
    }

    destroyed() {
        this.tabsNavigationBus().add([]);
        this.tabsNumberNavigationBus().unsubscribe(this.tabsNumberNavigationBusId);
    }
}
