import { Patrol } from '@/domain/video/Patrol';

export interface RestPatrol {
    Reference: string;
    Name: string;
}

export const toPatrol = (restPatrol: RestPatrol): Patrol => ({
    reference: restPatrol.Reference,
    name: restPatrol.Reference,
});
