import { ContactInformationsModify } from '@/domain/contact/ContactInformationsModify';
import { Site } from '@/domain/contact/Site';
import { RestContact, toRestContact } from '@/secondary/restContact/RestContact';
import { RestReplacedContact, toRestReplacedContact } from '@/secondary/restContact/RestReplacedContact';

export interface RestContactInformationsModify {
    Sites: string[];
    ContactsToAdd: RestContact[];
    ContactsToRemove: RestContact[];
    ContactsReplacements: RestReplacedContact[];
    ValidityStart: string;
    ValidityEnd: string;
    UserLastName: string;
    UserFirstName: string;
    UserPhoneNumber: string;
}

export const toRestContactInformationsModify = (contactInformationsModify: ContactInformationsModify): RestContactInformationsModify => ({
    Sites: toRestContactInformationsSiteModify(contactInformationsModify.sites),
    ContactsToAdd: contactInformationsModify.replacedContact
        ? contactInformationsModify.replacedContact
              .filter(contact => contact.isNew)
              .map(contact => contact.new)
              .map(toRestContact)
        : [],
    ContactsToRemove: contactInformationsModify.removedContact ? contactInformationsModify.removedContact.map(toRestContact) : [],
    ContactsReplacements: contactInformationsModify.replacedContact
        ? contactInformationsModify.replacedContact.map(toRestReplacedContact)
        : [],
    ValidityStart: contactInformationsModify.dateStart,
    ValidityEnd: contactInformationsModify.dateEnd,
    UserLastName: contactInformationsModify.userInformation ? contactInformationsModify.userInformation.lastName : '',
    UserFirstName: contactInformationsModify.userInformation ? contactInformationsModify.userInformation.firstName : '',
    UserPhoneNumber: contactInformationsModify.userInformation ? contactInformationsModify.userInformation.phone : '',
});

const toRestContactInformationsSiteModify = (sites: Site[]): string[] => {
    const updatedSites: string[] = [];

    sites.forEach(site => {
        updatedSites.push(site.reference);
    });

    return updatedSites;
};
