import { Component, Inject, Vue } from 'vue-property-decorator';

import { Contact } from '@/domain/contact/Contact';
import { ContactRepository } from '@/domain/contact/ContactRepository';
import { Site } from '@/domain/contact/Site';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { TableContact, toTableContact } from '@/primary/pages/contact/TableContact';

@Component({
    components: {
        IconVue,
    },
})
export default class ContactPageComponent extends Vue {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private contactRepository!: () => ContactRepository;

    sites: Site[] = [];
    siteSelected: Site[] = [];

    contactsSelected: TableContact[] = [];
    search = '';

    reloadContactTable = 0;
    headers = [
        {
            text: 'CHUBB Delta',
            value: '',
        },
        {
            text: '',
            value: '',
        },
    ];

    mounted() {
        this.pageNameBus().add('Nous contacter');
        this.loadContact();
    }

    get contactsSelectedFiltered() {
        return this.contactsSelected.filter(
            contact =>
                contact.name.toLowerCase().includes(this.search.toLowerCase()) ||
                contact.contact.toLowerCase().includes(this.search.toLowerCase())
        );
    }

    reloadContact() {
        this.contactsBySelectedSite(this.siteSelected);
    }

    private loadContact() {
        this.contactRepository()
            .getContactsDelta()
            .then(data => {
                this.fromDomain(data);
            });
    }

    private fromDomain(sites: Site[]) {
        this.sites = sites;
        this.siteSelected = sites;
        this.contactsBySelectedSite(sites);
    }

    private contactsBySelectedSite(sites: Site[]) {
        const contacts: Contact[] = [];
        sites.forEach(site => {
            contacts.push(...site.contacts);
        });
        this.contactsSelected = contacts.flatMap(toTableContact);
        this.reloadContactTable++;
    }
}
