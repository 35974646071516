export interface User {
    firstName: string;
    lastName: string;
    phone: string;
}

export const emptyUser = (): User => ({
    firstName: '',
    lastName: '',
    phone: '',
});
