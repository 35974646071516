import moment from 'moment';
import { Component, Emit, Inject, Vue } from 'vue-property-decorator';

import { User } from '@/domain/user/User';
import { UserRepository } from '@/domain/user/UserRepository';
import { IconVue } from '@/primary/components/common/icon';
import {
    CallListModifyPrimary,
    emptyCallListModifyPrimary,
} from '@/primary/components/modifyCallListModal/modifyCallListModalFirstStep/CallListModifyPrimary';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import { WarningInformationsVue } from '@/primary/components/ModifyContactModal/WarningInformations';
import { ModifyPhoneContactVue } from '@/primary/components/modifyPhoneContact';
import { CallListStoreRepository } from '@/secondary/store/callListStore/CallListStoreRepository';

@Component({
    components: {
        IconVue,
        WarningInformationsVue,
        ModifyPhoneContactVue,
    },
})
export default class ModifyCallListModalSecondStepComponent extends Vue {
    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private callListStoreRepository!: () => CallListStoreRepository;

    @Inject()
    private userRepository!: () => UserRepository;

    @Emit()
    private closeModal() {}

    asSoonAsPossible = true;
    permanent = true;
    startDateMenu = false;
    endDateMenu = false;
    customStartDateSelected = false;
    customStartDateSelectedData = '';
    customEndDateSelected = false;
    customEndDateSelectedData = '';
    selectedStartDate = '';
    selectedEndDate = '';
    user: User = { firstName: '', lastName: '', phone: '' };
    activeStep = 2;
    informationsText = `Les demandes de modification sont prises en compte sous 72h par le centre de télésurveillance.
La mise en application de votre demande nécessite au préalable une validation de la part de nos services.
Ceux-ci pourront éventuellement vous contacter au numéro de téléphone demandé dans ce formulaire.`;
    callList: CallListModifyPrimary = emptyCallListModifyPrimary;

    get formatCustomStartDate() {
        return this.customStartDateSelectedData.length > 0
            ? moment(new Date(this.customStartDateSelectedData)).locale('fr').format('L')
            : '';
    }

    get formatCustomEndDate() {
        return this.customEndDateSelectedData.length > 0 ? moment(new Date(this.customEndDateSelectedData)).locale('fr').format('L') : '';
    }

    mounted() {
        const callList = this.callListStoreRepository().getCallListModify();
        if (callList) {
            this.callList = callList;
            if (callList.ValidityStart.length != 0) {
                this.setValidityStartDate();
                this.setValidityEndDate();
            }
        }
        this.userRepository()
            .get()
            .then(user => {
                this.user = user;
            });
    }

    nextStep() {
        this.setValues();
        this.setComplementaryCallListInformations();
        this.activeStep++;
        this.navigationButtonBus().add(this.activeStep);
    }

    prevStep() {
        this.activeStep--;
        this.navigationButtonBus().add(this.activeStep);
    }

    soonAsPossibleWasSelected() {
        this.customStartDateSelected = false;
    }

    permanentWasSelected() {
        this.customEndDateSelected = false;
    }

    startDateWasSelected() {
        this.asSoonAsPossible = false;
    }

    endDateWasSelected() {
        this.permanent = false;
    }

    updatePhone(phone: string) {
        this.user.phone = phone;
    }

    private setValidityStartDate() {
        if (this.callList.ValidityStart.includes('Au plus tôt')) this.asSoonAsPossible = true;
        else {
            this.asSoonAsPossible = false;
            this.customStartDateSelected = true;
            this.customEndDateSelectedData = moment(this.callList.ValidityStart, 'DD/MM/YYYY').format('YYYY-MM-DD').toString();
        }
    }

    private setValidityEndDate() {
        if (this.callList.ValidityEnd.includes('Permanente')) this.permanent = true;
        else {
            this.permanent = false;
            this.customEndDateSelected = true;
            this.customEndDateSelectedData = moment(this.callList.ValidityEnd, 'DD/MM/YYYY').format('YYYY-MM-DD').toString();
        }
    }

    private setValues() {
        if (this.asSoonAsPossible) this.selectedStartDate = 'Au plus tôt';
        else {
            this.selectedStartDate = moment(new Date(this.customStartDateSelectedData)).locale('fr').format('L');
        }

        if (this.permanent) this.selectedEndDate = 'Permanente';
        else {
            this.selectedEndDate = moment(new Date(this.customEndDateSelectedData)).locale('fr').format('L');
            console.log(this.customEndDateSelectedData);
        }
    }

    private setComplementaryCallListInformations() {
        const callList: CallListModifyPrimary = {
            ...this.callList,
            UserFirstName: this.user.firstName,
            UserLastName: this.user.lastName,
            UserPhoneNumber: this.user.phone,
            ValidityStart: this.selectedStartDate,
            ValidityEnd: this.selectedEndDate,
        };

        this.callListStoreRepository().modifyCallListModify(callList);
    }
}
