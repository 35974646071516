import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';

import { IConfigurationRepository } from '@/domain/configuration/IConfigurationRepository';
import { Routes } from '@/domain/Routes';
import { Step } from '@/domain/step/Step';
import { Theme } from '@/domain/theme/Theme';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import { IconVue } from '@/primary/components/common/icon';
import { FooterVue } from '@/primary/components/footer';
import { FormPasswordPage } from '@/primary/pages/formPasswordPage';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

@Component({
    components: {
        IconVue,
        FooterVue,
        VueHcaptcha,
    },
})
export default class ActivateAccountPageComponent extends mixins(FormPasswordPage) {
    @Inject()
    private configurationRepository!: () => IConfigurationRepository;

    theme!: Theme;
    clickArea1 = '';
    clickArea2 = '';
    clickToCall = '';
    supportEmail = '';
    email = '';
    lastName = '';
    firstName = '';
    phone = '';
    code = '';
    token = '';
    captchaCompleted = false;
    step = 0;
    steps: Step[] = [
        { value: 1, text: 'Validation de votre adresse email' },
        { value: 2, text: 'Définition de votre mot de passe' },
        { value: 3, text: 'Confirmation de votre identité' },
    ];
    isContactModalOpen = false;
    isLoading = true;
    isMinimumPassed = false;

    created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;
    }

    mounted() {
        this.getConfigLinks();
        setTimeout(() => (this.isMinimumPassed = true), 2000);
        if (this.$route.query.emailId) this.getUserInfo(this.$route.query.emailId as string);
        else this.sendError();
    }

    captchaCheck(): void {
        this.captchaCompleted = true;
    }

    savePassword(): void {
        this.hasError = false;
        if (this.isFormValid())
            this.accountRepository()
                .sendSms(this.$route.query.emailId as string)
                .then(newPassword => {
                    this.step = 3;
                    this.phone = newPassword.phone;
                    this.token = newPassword.token;
                })
                .catch(() => {
                    this.hasError = true;
                    this.loginError = "Erreur de l'api";
                });
        else {
            this.hasError = true;
            this.loginError = 'Formulaire invalide';
        }
    }

    confirmPhone(): void {
        this.hasError = false;
        this.accountRepository()
            .confirmPhone(this.$route.query.emailId as string, this.code, this.password, this.token, this.confirmationPassword)
            .then(() => {
                this.step = 4;
            })
            .catch(() => {
                this.hasError = true;
                this.loginError = "Erreur de l'api";
            });
    }

    async toLogin() {
        await this.$router.push({ name: Routes.LoginPage });
    }

    private sendError() {
        this.toLogin();
        this.toastBus().add({ status: ToastStatus.ERROR, title: 'Erreur', text: 'Accès refusé' });
    }

    private getConfigLinks(): void {
        this.configurationRepository()
            .getConfigLinks()
            .then(data => {
                if (this.theme == Theme.ITESIS) {
                    this.clickArea1 = data.itesisClickArea1;
                    this.clickArea2 = data.itesisClickArea2;
                    this.clickToCall = data.itesisSupportClickToCall;
                    this.supportEmail = data.itesisSupportEmail;
                } else {
                    this.clickArea1 = data.surwayClickArea1;
                    this.clickArea2 = data.surwayClickArea2;
                    this.clickToCall = data.surwaySupportClickToCall;
                    this.supportEmail = data.surwaySupportEmail;
                }
            });
    }

    private getUserInfo(emailId: string) {
        this.accountRepository()
            .getOnboarding(emailId)
            .then(user => {
                this.email = user.email;
                this.lastName = user.lastName;
                this.firstName = user.firstName;
                this.isLoading = false;
            })
            .catch(() => this.sendError());
    }
}
