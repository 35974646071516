import moment from 'moment';

import { EventHistoryMediaDetail } from '@/domain/event/Event';

export interface RestEventAttachmentDetail {
    Details: string;
    Label: string;
    OccurredOn: string;
}

export const toEventHistoryMediaDetail = (restEventAttachmentDetail: RestEventAttachmentDetail): EventHistoryMediaDetail => ({
    details: restEventAttachmentDetail.Details,
    label: restEventAttachmentDetail.Label,
    date: moment(new Date(restEventAttachmentDetail.OccurredOn)).locale('fr').format('DD/MM/YYYY HH:mm'),
});
