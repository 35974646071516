import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';

import { ContactRepository } from '@/domain/contact/ContactRepository';
import {
    contactCallListModalHeaderReview,
    contactCallListModalHeaderReviewCreated,
    prestationCallListModalHeader,
} from '@/domain/header/modalCallListHeader';
import { IconVue } from '@/primary/components/common/icon';
import {
    CallListModifyPrimary,
    emptyCallListModifyPrimary,
} from '@/primary/components/modifyCallListModal/modifyCallListModalFirstStep/CallListModifyPrimary';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import { WarningInformationsVue } from '@/primary/components/ModifyContactModal/WarningInformations';
import { CallListStoreRepository } from '@/secondary/store/callListStore/CallListStoreRepository';

@Component({
    components: {
        IconVue,
        WarningInformationsVue,
    },
})
export default class ModifyCallListModalThirdStepComponent extends Vue {
    @Prop({ required: true })
    private siteReference!: string;

    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private callListStoreRepository!: () => CallListStoreRepository;

    @Inject()
    private contactRepository!: () => ContactRepository;

    @Emit()
    private closeModal() {}

    activeStep = 3;
    callList: CallListModifyPrimary = emptyCallListModifyPrimary;
    headerContact = contactCallListModalHeaderReview;
    headerContactCreated = contactCallListModalHeaderReviewCreated;
    headerPrestation = prestationCallListModalHeader;

    mounted() {
        const callList = this.callListStoreRepository().getCallListModify();
        if (callList) this.callList = callList;
    }

    nextStep() {
        this.callList.SiteReference = this.siteReference;
        this.contactRepository()
            .updateCallList(this.callList)
            .then(() => {
                this.activeStep++;
                this.navigationButtonBus().add(this.activeStep);
            })
            .catch(error => {
                console.log(error);
            });
    }

    prevStep() {
        this.activeStep--;
        this.navigationButtonBus().add(this.activeStep);
    }

    get createdContactsInModification() {
        return this.callList.CallList.Contacts.filter(contact => contact.created);
    }
}
