import { ITroubleshootingMaintenance } from '@/domain/troubleshooting/ITroubleshootingMaintenance';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export type RestTroubleshootingMaintenance = {
    TicketNumber: string;
    SiteReference: string;
    Activity: string;
    SiteName: string;
    City: string;
    Cause: string;
    CallDate: string;
    InterventionDate: string;
    IsOngoing: boolean;
    Billable: boolean;
};

export const toTroubleshootingMaintenance = (restMaintenance: RestTroubleshootingMaintenance): ITroubleshootingMaintenance => ({
    id: restMaintenance.TicketNumber,
    siteReference: restMaintenance.SiteReference,
    activity: restMaintenance.Activity,
    siteName: stringCleaner(restMaintenance.SiteName),
    city: restMaintenance.City,
    cause: restMaintenance.Cause,
    responseDate: restMaintenance.InterventionDate,
    interventionDate: restMaintenance.CallDate,
    isOngoing: restMaintenance.IsOngoing,
    billable: restMaintenance.Billable,
});
