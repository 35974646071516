import { Component, Vue } from 'vue-property-decorator';
import { VApp, VMain } from 'vuetify/lib';

import { CommonFooterVue } from '@/primary/components/commonFooter';
import { GuestHeaderVue } from '@/primary/components/guestHeader';

//import { HeaderPrimaryVue } from '@/primary/components/header/headerPrimary';

@Component({
    components: {
        VApp,
        VMain,
        GuestHeaderVue,
        CommonFooterVue,
    },
})
export default class GuestLayout extends Vue {}
