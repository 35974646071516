import { render, staticRenderFns } from "./videoCheckUpPage.html?vue&type=template&id=be3ce896&external"
import script from "./videoCheckUpPage.component.ts?vue&type=script&lang=ts&external"
export * from "./videoCheckUpPage.component.ts?vue&type=script&lang=ts&external"
import style0 from "./videoCheckUpPage.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports