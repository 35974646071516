import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';

import { PageNameIcon } from '@/domain/pageName/PageName';
import { Routes } from '@/domain/Routes';
import { UserRepository } from '@/domain/user/UserRepository';
import { IconVue } from '@/primary/components/common/icon';
import MenuBus from '@/primary/components/menu/MenuBus';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { Tab } from '@/primary/components/navbar/Tab';
import TabsNavigationBus from '@/primary/components/navbar/TabsNavigationBus';
import TabsNumberNavigationBus from '@/primary/components/navbar/TabsNumberNavigationBus';
import { RightMixin } from '@/primary/utils/rights/RightMixin';
import { StoreRepository } from '@/secondary/store/localeStore/StoreRepository';

@Component({
    components: {
        IconVue,
    },
})
export default class NavbarComponent extends mixins(RightMixin) {
    @Inject()
    private menuMobileBus!: () => MenuBus;

    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private tabsNavigationBus!: () => TabsNavigationBus;

    @Inject()
    private tabsNumberNavigationBus!: () => TabsNumberNavigationBus;

    @Inject()
    private storeRepository!: () => StoreRepository;

    @Inject()
    private userRepository!: () => UserRepository;

    icon = '';
    pageName = '';
    username = '';
    reload = 0;
    tab = 0;
    tabs: Tab[] = [];

    mounted() {
        this.pageNameBus().subscribe(page => {
            if (page) {
                this.pageName = page;
                this.icon = PageNameIcon.get(page)!;
            } else {
                this.pageName = '';
                this.icon = '';
            }
            this.reload++;
        });
        this.tabsNavigationBus().subscribe(tabs => {
            if (tabs) this.tabs = tabs;
            this.tab = this.getFirstTabWithRights();
            if (this.tab != 0) this.updateTabs();
        });
        this.userRepository()
            .get()
            .then(user => {
                this.username = user.firstName + ' ' + user.lastName;
            });
        this.userRepository().setHasSiteWithContract();
    }

    showMenuMobile() {
        this.menuMobileBus().add(true);
    }

    updateTabs() {
        this.tabsNumberNavigationBus().add(this.tab);
    }

    goToParameter() {
        this.$router.push({ name: Routes.SettingsPage });
    }

    logout() {
        this.storeRepository().removeUser();
        this.$router.push({ name: Routes.LoginPage });
    }

    private getFirstTabWithRights() {
        return this.tabs.findIndex(tab => this.hasRight(tab.right));
    }
}
