import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { File } from '@/domain/file/File';
import { FileRepository } from '@/domain/file/FileRepository';
import { downloadFile } from '@/secondary/DownloadFile';
import { RestDocument, toDocument } from '@/secondary/restFile/RestDocument';
import { RestFile } from '@/secondary/restFile/RestFile';
import { RestFolder, toFile } from '@/secondary/restFile/RestFolder';

export class RestFileRepository implements FileRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getList(): Promise<File[]> {
        return this.axiosInstance
            .get('/Documents')
            .then((response: AxiosResponse<RestFile[]>) =>
                response.data.map(d => (d.Type == 0 ? toDocument(d as RestDocument) : toFile(d as RestFolder)))
            );
    }

    download(id: string): Promise<void> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance.get('/Document/' + id, params).then(downloadFile);
    }
}
