import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';

import { IConfigurationRepository } from '@/domain/configuration/IConfigurationRepository';
import { Routes } from '@/domain/Routes';
import { Theme } from '@/domain/theme/Theme';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import { IconVue } from '@/primary/components/common/icon';
import { FooterVue } from '@/primary/components/footer';
import { FormPasswordPage } from '@/primary/pages/formPasswordPage';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

@Component({
    components: {
        IconVue,
        FooterVue,
    },
})
export default class ForgottenPasswordPageComponent extends mixins(FormPasswordPage) {
    @Inject()
    private configurationRepository!: () => IConfigurationRepository;

    theme!: Theme;
    clickArea1 = '';
    clickArea2 = '';

    created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;
    }

    mounted() {
        this.getConfigLinks();
        if (this.$route.query.proceed)
            this.accountRepository()
                .validateForgottenToken(this.$route.query.proceed as string)
                .catch(this.sendError);
        else this.sendError();
    }

    private async sendError() {
        await this.$router.push({ name: Routes.LoginPage });
        this.toastBus().add({ status: ToastStatus.ERROR, title: 'Erreur', text: 'Accès refusé' });
    }

    resetPassword(): void {
        this.hasError = false;
        if (this.isFormValid())
            this.accountRepository()
                .resetPassword(this.password, this.$route.query.proceed as string, this.confirmationPassword)
                .then(() => {
                    this.$router.push({ name: Routes.LoginPage });
                    this.toastBus().add({ status: ToastStatus.SUCCESS, title: 'Succès', text: 'Modification effectuée avec succès' });
                })
                .catch(() => {
                    this.hasError = true;
                    this.loginError = "Erreur de l'api";
                });
        else {
            this.hasError = true;
            this.loginError = 'Formulaire invalide';
        }
    }

    private getConfigLinks(): void {
        this.configurationRepository()
            .getConfigLinks()
            .then(data => {
                if (this.theme == Theme.ITESIS) {
                    this.clickArea1 = data.itesisClickArea1;
                    this.clickArea2 = data.itesisClickArea2;
                } else {
                    this.clickArea1 = data.surwayClickArea1;
                    this.clickArea2 = data.surwayClickArea2;
                }
            });
    }
}
