export interface SiteDetailsContract {
    clientNumber: string;
    subscriptionDate: string;
    remoteMonitoringContract: string;
    remoteSecurityContract: string;
}

export const emptySiteDetailsContract = (): SiteDetailsContract => ({
    clientNumber: '',
    subscriptionDate: '',
    remoteMonitoringContract: '',
    remoteSecurityContract: '',
});
