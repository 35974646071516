import { emptySiteDetailsAddress, SiteDetailsAddress } from '@/domain/site/siteDetails/SiteDetailsAddress';
import { emptySiteDetailsContract, SiteDetailsContract } from '@/domain/site/siteDetails/SiteDetailsContract';
import { emptySiteDetailsDescription, SiteDetailsDescription } from '@/domain/site/siteDetails/SiteDetailsDescription';
import { emptySiteDetailsEquipment, SiteDetailsEquipment } from '@/domain/site/siteDetails/SiteDetailsEquipment';
import { emptySiteDetailsResponsible, SiteDetailsResponsible } from '@/domain/site/siteDetails/SiteDetailsResponsible';

export interface SiteDetails {
    description: SiteDetailsDescription;
    address: SiteDetailsAddress;
    responsible: SiteDetailsResponsible;
    contract: SiteDetailsContract;
    equipment: SiteDetailsEquipment;
}

export const emptySiteDetails = (): SiteDetails => ({
    description: emptySiteDetailsDescription(),
    address: emptySiteDetailsAddress(),
    responsible: emptySiteDetailsResponsible(),
    contract: emptySiteDetailsContract(),
    equipment: emptySiteDetailsEquipment(),
});
