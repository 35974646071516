import Component, { mixins } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

import { AccountRepository } from '@/domain/account/AccountRepository';
import { MinCharMatchRule } from '@/domain/rules/minCharMatchRule';
import { MinLengthRule } from '@/domain/rules/minLengthRule';
import { RequiredRule } from '@/domain/rules/requiredRule';
import { ValueMatchRule } from '@/domain/rules/valueMatchRule';
import { FormBasePage } from '@/primary/pages/formBasePage';
import ToastBus from '@/primary/utils/ToastBus';

@Component
export class FormPasswordPage extends mixins(FormBasePage) {
    @Inject()
    protected accountRepository!: () => AccountRepository;

    @Inject()
    protected toastBus!: () => ToastBus;

    password = '';
    confirmationPassword = '';

    specialCharacters = '!/;:?,%';
    passwordRules = [
        new RequiredRule(false),
        new MinLengthRule(8, true).withDescriptionMobile('8 car'),
        new MinCharMatchRule(1, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'Majuscule', true, 'Maj'),
        new MinCharMatchRule(1, 'abcdefghijklmnopqrstuvwxyz', 'Minuscule', true, 'Min'),
        new MinCharMatchRule(1, '0123456789', 'Chiffre', true, '0,1,2..'),
        new MinCharMatchRule(1, this.specialCharacters, 'Spécial ' + this.specialCharacters, true, '!/;:?,%'),
    ];
    passwordConfirmRules = [
        new RequiredRule(false),
        new ValueMatchRule(() => this.getPassword(), 'Les mots de passe ne correspondent pas', false),
    ];
    hasError = false;
    loginError = '';

    showPassword = false;
    showConformationPassword = false;

    isFormValid(): boolean {
        return (
            this.allRulesPassed(this.passwordRules, this.password, true) &&
            this.allRulesPassed(this.passwordConfirmRules, this.confirmationPassword, true)
        );
    }

    getPassword(): string {
        return this.password;
    }
}
