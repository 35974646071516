import { AxiosError } from 'axios';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { troubleshootingMaintenanceHeader, troubleshootingMaintenanceHeaderMobile } from '@/domain/header/troubleshootingMaintenanceHeader';
import { Right } from '@/domain/menu/Right';
import { ExportTroubleshootingMaintenanceParam } from '@/domain/troubleshooting/ExportTroubleshootingMaintenanceParam';
import { ITroubleshootingMaintenance } from '@/domain/troubleshooting/ITroubleshootingMaintenance';
import { ITroubleshootingMaintenanceRepository } from '@/domain/troubleshooting/ITroubleshootingMaintenanceRepository';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { Tab } from '@/primary/components/navbar/Tab';
import TabsNavigationBus from '@/primary/components/navbar/TabsNavigationBus';
import TabsNumberNavigationBus from '@/primary/components/navbar/TabsNumberNavigationBus';
import { RemoteMaintenanceVue } from '@/primary/components/remoteMaintenance';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
        RemoteMaintenanceVue,
    },
})
export default class TroubleshootingPage extends mixins(RightMixin) {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private tabsNavigationBus!: () => TabsNavigationBus;

    @Inject()
    private tabsNumberNavigationBus!: () => TabsNumberNavigationBus;

    @Inject()
    private troubleshootingMaintenanceRepository!: () => ITroubleshootingMaintenanceRepository;

    headers: DataTableHeader[] = troubleshootingMaintenanceHeader();
    headersMobile: DataTableHeader[] = troubleshootingMaintenanceHeaderMobile();

    search = '';
    tab = 0;
    tabs: Tab[] = [
        { name: 'Maintenance', right: Right.RS_DEPH_LST },
        { name: 'Télémaintenance', right: Right.RS_TLM_LST },
    ];
    tabsNumberNavigationBusId = 0;
    maintenanceRecords: ITroubleshootingMaintenance[] = [];

    interventionRangeDates = [];
    responseRangeDates = [];

    activities: string[] = [];
    selectedActivities: string[] = [];
    sites: string[] = [];
    selectedSites: string[] = [];
    cities: string[] = [];
    selectedCities: string[] = [];
    billable: Array<any> = [
        { text: 'oui', value: true },
        { text: 'non', value: false },
    ];
    billableSelected: boolean[] = [];

    isInterventionDateOpen = false;
    isResponseDateOpen = false;
    isInterventionDateMobileOpen = false;
    isResponseDateMobileOpen = false;

    get formatCustomInterventionRangeDates() {
        if (this.interventionRangeDates.length > 0) {
            const dates = this.interventionRangeDates.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get formatCustomResponseRangeDates() {
        if (this.responseRangeDates.length > 0) {
            const dates = this.responseRangeDates.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get siteOrdered() {
        return this.sites
            .sort((a, b) => a.localeCompare(b))
            .sort((a, b) => {
                const hasA = this.selectedSites.find(site => site === a) !== undefined;
                const hasB = this.selectedSites.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    async mounted() {
        this.pageNameBus().add('Mon SAV');
        this.tabsNavigationBus().add(this.tabs);

        this.tabsNumberNavigationBusId = this.tabsNumberNavigationBus().subscribe(value => {
            this.tab = value!;
        });
        await this.troubleshootingMaintenanceRepository()
            .get()
            .then(data => {
                this.fromDomain(data);
            })
            .catch((error: Error) => {
                console.log(error.message);
            });
    }

    destroyed() {
        this.tabsNavigationBus().add([]);
        this.tabsNumberNavigationBus().unsubscribe(this.tabsNumberNavigationBusId);
    }

    exportData(type: string) {
        this.troubleshootingMaintenanceRepository()
            .getExport(type, this.toExportMaintenanceParams())
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    getDetailsReport(maintenanceItem: ITroubleshootingMaintenance) {
        if (maintenanceItem.isOngoing) {
            this.troubleshootingMaintenanceRepository()
                .getOngoingDetailsReport(maintenanceItem.id)
                .catch((error: AxiosError) => {
                    console.log(error.message);
                });
        } else {
            this.troubleshootingMaintenanceRepository()
                .getCompletedDetailsReport(maintenanceItem.id)
                .catch((error: AxiosError) => {
                    console.log(error.message);
                });
        }
    }

    clearTable(table: []) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        table = [];
    }

    get updateData() {
        return this.maintenanceRecords.filter(record => {
            return (
                this.siteUpdated(record) &&
                this.billableUpdated(record) &&
                this.reponseDateUpdated(record) &&
                this.interventionDateUpdated(record)
            );
        });
    }

    private toExportMaintenanceParams(): ExportTroubleshootingMaintenanceParam {
        let troubleshootingMaintenanceParams: ExportTroubleshootingMaintenanceParam = {
            Activities: this.selectedActivities,
            Cities: this.selectedCities,
            Sites: this.selectedSites,
            Billable: this.billableSelected,
        };
        if (this.interventionRangeDates.length > 0) {
            troubleshootingMaintenanceParams = {
                ...troubleshootingMaintenanceParams,
                FromInterventionDate: new Date(this.interventionRangeDates[0]).toISOString(),
                ToInterventionDate: new Date(this.interventionRangeDates[1]).toISOString(),
            };
        }
        if (this.responseRangeDates.length > 0) {
            troubleshootingMaintenanceParams = {
                ...troubleshootingMaintenanceParams,
                FromCallDate: new Date(this.responseRangeDates[0]).toISOString(),
                ToCallDate: this.responseRangeDates[1] ? new Date(this.responseRangeDates[1]).toISOString() : '',
            };
        }

        return troubleshootingMaintenanceParams;
    }

    private fromDomain(maintenanceRecords: ITroubleshootingMaintenance[]) {
        this.maintenanceRecords = maintenanceRecords;
        this.createSiteFilter(maintenanceRecords);
        this.createCityFilter(maintenanceRecords);
        this.createActivityFilter(maintenanceRecords);
    }

    private createActivityFilter(maintenanceRecords: ITroubleshootingMaintenance[]) {
        maintenanceRecords.forEach(record => {
            this.activities.push(record.activity);
        });
        this.activities = [...new Set(this.activities)];
    }

    private createSiteFilter(maintenanceRecords: ITroubleshootingMaintenance[]) {
        maintenanceRecords.forEach(record => {
            this.sites.push(record.siteName);
        });
        this.sites = [...new Set(this.sites)];
    }

    private createCityFilter(maintenanceRecords: ITroubleshootingMaintenance[]) {
        maintenanceRecords.forEach(record => {
            this.cities.push(record.city);
        });
        this.cities = [...new Set(this.cities)];
    }

    private siteUpdated(maintenanceRecord: ITroubleshootingMaintenance) {
        if (this.selectedSites.length > 0) return this.selectedSites.indexOf(maintenanceRecord.siteName) > -1;
        return true;
    }

    private billableUpdated(maintenanceRecord: ITroubleshootingMaintenance) {
        if (this.billableSelected.length > 0) return this.billableSelected.includes(maintenanceRecord.billable);
        return true;
    }

    private reponseDateUpdated(maintenanceRecord: ITroubleshootingMaintenance) {
        if (this.responseRangeDates.length > 0)
            return moment(new Date(maintenanceRecord.responseDate).toDateString()).isBetween(
                new Date(this.responseRangeDates[0]).toDateString(),
                new Date(this.responseRangeDates[1]).toDateString(),
                undefined,
                '[]'
            );
        if (this.responseRangeDates.length > 0)
            return moment(new Date(maintenanceRecord.responseDate).toDateString()).isSameOrAfter(
                new Date(this.responseRangeDates[0]).toDateString()
            );
        if (this.responseRangeDates.length > 1)
            return moment(new Date(maintenanceRecord.responseDate).toDateString()).isSameOrBefore(
                new Date(this.responseRangeDates[1]).toDateString()
            );
        return true;
    }

    private interventionDateUpdated(maintenanceRecord: ITroubleshootingMaintenance) {
        if (this.interventionRangeDates.length > 0)
            return moment(new Date(maintenanceRecord.interventionDate).toDateString()).isBetween(
                new Date(this.interventionRangeDates[0]).toDateString(),
                new Date(this.interventionRangeDates[1]).toDateString(),
                undefined,
                '[]'
            );
        if (this.interventionRangeDates.length > 0)
            return moment(new Date(maintenanceRecord.interventionDate).toDateString()).isSameOrAfter(
                new Date(this.interventionRangeDates[0]).toDateString()
            );
        if (this.interventionRangeDates.length > 1)
            return moment(new Date(maintenanceRecord.interventionDate).toDateString()).isSameOrBefore(
                new Date(this.interventionRangeDates[1]).toDateString()
            );
        return true;
    }
}
