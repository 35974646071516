import { FileType } from '@/domain/file/FileType';
import { Folder } from '@/domain/file/Folder';
import { RestDocument, toDocument } from '@/secondary/restFile/RestDocument';
import { RestFile } from '@/secondary/restFile/RestFile';

export interface RestFolder extends RestFile {
    Type: FileType.FOLDER;
    Children: (RestDocument | RestFolder)[];
}

export const toFile = (restFolder: RestFolder): Folder => ({
    type: FileType.FOLDER,
    id: restFolder.Id,
    label: restFolder.Label,
    child: restFolder.Children.map(d => (d.Type == 0 ? toDocument(d as RestDocument) : toFile(d))),
});
