import { AxiosInstance, AxiosResponse } from 'axios';

import { Patrol } from '@/domain/video/Patrol';
import { VideoInstruction } from '@/domain/video/VideoInstruction';
import { VideoInstructionRepository } from '@/domain/video/VideoInstructionRepository';
import { ErrorMessage } from '@/secondary/error/ErrorMessage';
import { RestPatrol, toPatrol } from '@/secondary/restVideo/RestPatrol';
import { RestVideoInstruction, toVideoInstruction } from '@/secondary/restVideo/RestVideoInstruction';

export class RestVideoInstructionRepository implements VideoInstructionRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getInstructions(siteReference: string, patrolReference: string): Promise<VideoInstruction[]> {
        return this.axiosInstance
            .get('/Site/' + siteReference + '/Patrol/' + patrolReference)
            .then((response: AxiosResponse<RestVideoInstruction[]>) => {
                return response.data.map(toVideoInstruction);
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }

    getPatrols(siteReference: string): Promise<Patrol[]> {
        return this.axiosInstance
            .get('/Site/' + siteReference + '/Patrols')
            .then((response: AxiosResponse<RestPatrol[]>) => {
                return response.data.map(toPatrol);
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }
}
