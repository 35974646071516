import { AxiosInstance, AxiosResponse } from 'axios';

import { Device } from '@/domain/user/Device';
import { FullUser } from '@/domain/user/FullUser';
import { User } from '@/domain/user/User';
import { UserAlerts } from '@/domain/user/UserAlerts';
import { UserRepository } from '@/domain/user/UserRepository';
import { RestDevice, toDevice } from '@/secondary/restUser/RestDevice';
import { RestUser, toFullUser, toRestUserFull, toUser } from '@/secondary/restUser/RestUser';
import { RestUserAlert, toRestUserAlerts, toUserAlerts } from '@/secondary/restUser/RestUserAlert';
import { StoreRepository } from '@/secondary/store/localeStore/StoreRepository';

export class RestUserRepository implements UserRepository {
    constructor(
        private axiosInstance: AxiosInstance,
        private storeRepository: StoreRepository
    ) {}

    get(): Promise<User> {
        return this.axiosInstance.get('/UserInfos').then((response: AxiosResponse<RestUser>) => {
            this.storeRepository.modifyUserRights(response.data.Rights!);
            return toUser(response.data);
        });
    }

    setHasSiteWithContract(): Promise<void> {
        return this.axiosInstance
            .get('/UserInfos/HasSiteWithTsvContract')
            .then((response: AxiosResponse<boolean>) => this.storeRepository.modifyHasSites(response.data));
    }

    getUserAlerts(): Promise<UserAlerts> {
        return this.axiosInstance.get('/Subscriptions').then((response: AxiosResponse<RestUserAlert[]>) => toUserAlerts(response.data));
    }

    getModificationAlert(): Promise<boolean> {
        return this.axiosInstance
            .get('/UserInfos/Settings')
            .then((response: AxiosResponse<{ ManagementReceipt: boolean }>) => response.data.ManagementReceipt);
    }

    updatePassword(oldPassword: string, password: string): Promise<void> {
        return this.axiosInstance.patch('/UserInfos/Password', { OldPassword: oldPassword, NewPassword: password });
    }

    updateUserAlerts(userAlerts: UserAlerts): Promise<void> {
        return this.axiosInstance.put('/Subscriptions', toRestUserAlerts(userAlerts));
    }

    getFullUser(): Promise<FullUser> {
        return this.axiosInstance.get('/UserInfos').then((response: AxiosResponse<RestUser>) => toFullUser(response.data));
    }

    getUserDevices(): Promise<Device[]> {
        return this.axiosInstance.get('/Devices').then((response: AxiosResponse<RestDevice[]>) => response.data.map(toDevice));
    }

    updateUser(user: FullUser): Promise<void> {
        return this.axiosInstance.patch('/UserInfos', toRestUserFull(user));
    }

    updateUserDevice(id: string, state: boolean): Promise<void> {
        return this.axiosInstance.patch('/Device/' + id + '/' + (state ? 'Enabled' : 'Disabled'));
    }

    updateModificationAlerts(modificationAlert: boolean): Promise<void> {
        return this.axiosInstance.patch('/UserInfos/Settings', { ManagementReceipt: modificationAlert });
    }

    startEdit(userId: number): Promise<void> {
        return this.axiosInstance.put(`/ManagedUsers/${userId}/StartEdit`);
    }
}
