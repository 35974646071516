import { Component, Emit, Inject, Vue } from 'vue-property-decorator';

import { emptyContactInformationsModify } from '@/domain/contact/ContactInformationsModify';
import { IconVue } from '@/primary/components/common/icon';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import ModifyInformationsContactBus from '@/primary/pages/Instructions/ModifyInformationsContactBus';

@Component({
    components: {
        IconVue,
    },
})
export default class ModifyContactModalFourthStepComponent extends Vue {
    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private modifyInformationsContactBus!: () => ModifyInformationsContactBus;

    @Emit()
    closeModal() {
        this.navigationButtonBus().add(1);
        this.modifyInformationsContactBus().add(emptyContactInformationsModify());
    }
}
