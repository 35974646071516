export class Responsive {
    constructor(private globalWindow?: Window) {}

    get isMobile(): boolean {
        if (!this.globalWindow) {
            return false;
        }

        return this.globalWindow.innerWidth < 1216;
    }
}
