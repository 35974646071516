export interface SiteMap {
    reference: string;
    name: string;
    state: string;
    city: string;
    lastAlarmEventDate: string;
    anomaly: string;
}

export const emptySite = (): SiteMap => ({
    reference: '',
    name: '',
    state: '',
    city: '',
    lastAlarmEventDate: '',
    anomaly: '',
});
