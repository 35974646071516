import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { RestRemoteMaintenance, toRemoteMaintenance } from './RestRemoteMaintenance';

import { ExportRemoteMaintenanceParam } from '@/domain/troubleshooting/ExportRemoteMaintenanceParams';
import { IRemoteMaintenance } from '@/domain/troubleshooting/IRemoteMaintenance';
import { IRemoteMaintenanceRepository } from '@/domain/troubleshooting/IRemoteMaintenanceRepository';
import { downloadFile } from '@/secondary/DownloadFile';

export class RestRemoteMaintenanceRepository implements IRemoteMaintenanceRepository {
    constructor(private axiosInstance: AxiosInstance) {}
    getRemoteMaintenance(): Promise<IRemoteMaintenance[]> {
        return this.axiosInstance
            .get('/Troubleshootings/Maintenances/Remote')
            .then((response: AxiosResponse<RestRemoteMaintenance[]>) => {
                return response.data.map(toRemoteMaintenance);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getExport(type: string, exportParams: ExportRemoteMaintenanceParam): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .post(`/Troubleshootings/Maintenances/Remote/Export/${type}`, exportParams, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }
}
