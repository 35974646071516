import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { videoCreditHeader } from '@/domain/header/videoHeader';
import { Routes } from '@/domain/Routes';
import { VideoCreditRepository } from '@/domain/video/VideoCreditRepository';
import { IconVue } from '@/primary/components/common/icon';
import { TableVideoCredit, toTableVideoCredit } from '@/primary/components/videoCredit/TableVideoCredit';

@Component({
    components: {
        IconVue,
    },
})
export default class VideoCreditComponent extends Vue {
    @Prop({ required: true })
    private siteReference!: string;

    @Inject()
    private videoCreditRepository!: () => VideoCreditRepository;

    videoCredits: TableVideoCredit[] = [];
    headers: DataTableHeader[] = videoCreditHeader();

    @Watch('siteReference')
    getCredits() {
        this.videoCreditRepository()
            .getCredits(this.siteReference)
            .then(videoCredits => {
                this.videoCredits = videoCredits.map(toTableVideoCredit);
            });
    }

    goToContact() {
        this.$router.push({ name: Routes.Contact });
    }
}
