import { CallListModify, CallListModifyDetail } from '@/domain/contact/CallListModify';
import { ContactCallList } from '@/domain/contact/ContactCallList';
import { Prestation } from '@/domain/contact/Prestation';
import { RestContact, toRestContact } from '@/secondary/restContact/RestContact';

export interface RestCallListModify {
    CallList: RestCallListModifyDetail;
    ValidityStart: string;
    ValidityEnd: string;
    UserLastName: string;
    UserFirstName: string;
    UserPhoneNumber: string;
    SiteReference: string;
}

interface RestCallListModifyDetail {
    Code: string;
    Services: Prestation[];
    Contacts: RestContactCallList[];
}

export interface RestContactCallList extends RestContact {
    Order: number;
    IsNew: boolean;
}

export const toRestCallListModify = (callListModify: CallListModify): RestCallListModify => ({
    CallList: toRestCallListModifyDetail(callListModify.CallList),
    ValidityStart: callListModify.ValidityStart,
    ValidityEnd: callListModify.ValidityEnd,
    UserLastName: callListModify.UserLastName,
    UserFirstName: callListModify.UserFirstName,
    UserPhoneNumber: callListModify.UserPhoneNumber,
    SiteReference: callListModify.SiteReference,
});

const toRestCallListModifyDetail = (callListModifyDetail: CallListModifyDetail): RestCallListModifyDetail => ({
    Code: callListModifyDetail.Code,
    Services: callListModifyDetail.Prestations,
    Contacts: callListModifyDetail.Contacts.map(toRestContactCallList),
});

const toRestContactCallList = (contactCallList: ContactCallList): RestContactCallList => ({
    ...toRestContact(contactCallList),
    Order: contactCallList.order,
    IsNew: contactCallList.created,
});
