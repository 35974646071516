import { AxiosError } from 'axios';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Inject, Component } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { quotesHeader, quotesHeaderMobile } from '@/domain/header/quotesHeader';
import { IExportQuoteParam } from '@/domain/quotes/IExportQuoteParam';
import { IQuote } from '@/domain/quotes/IQuote';
import { IQuoteRepository } from '@/domain/quotes/IQuoteRepository';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { Responsive } from '@/primary/utils/Responsive';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
    },
})
export default class QuotesPageComponent extends mixins(RightMixin) {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private responsive!: () => Responsive;

    @Inject()
    private quoteRepository!: () => IQuoteRepository;

    headers: DataTableHeader[] = quotesHeader();
    headersMobile: DataTableHeader[] = quotesHeaderMobile();

    activities: string[] = [];
    selectedActivities: string[] = [];
    sites: string[] = [];
    selectedSites: string[] = [];
    cities: string[] = [];
    selectedCities: string[] = [];
    datesRange = [new Date('01/01/' + (new Date().getFullYear() - 1)).toISOString(), new Date().toISOString()];
    quotes: IQuote[] = [];
    amountRange: number[] = [0, 10000];
    minAmount = 0;
    maxAmount = 10000;
    search = '';

    isDateRangeOpen = false;
    isDateRangeMobileOpen = false;

    get formatCustomRangeDates() {
        if (this.datesRange.length > 0) {
            const dates = this.datesRange.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get updateData() {
        return this.quotes.filter(quote => {
            return (
                this.siteUpdated(quote) &&
                this.cityUpdated(quote) &&
                this.activityUpdated(quote) &&
                this.dateUpdated(quote) &&
                this.amountUpdated(quote)
            );
        });
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    get siteOrdered() {
        return this.sites
            .sort((a, b) => a.localeCompare(b))
            .sort((a, b) => {
                const hasA = this.selectedSites.find(site => site === a) !== undefined;
                const hasB = this.selectedSites.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    mounted() {
        this.pageNameBus().add('Mes Devis en attente');

        this.quoteRepository()
            .getQuotes()
            .then(data => {
                this.fromDomain(data);
            });
    }

    getDetailsReport(quoteId: number) {
        this.quoteRepository()
            .getQuoteDetailReport(quoteId)
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    private fromDomain(quotes: IQuote[]) {
        const quoteAmounts = quotes.map(quote => quote.cost ?? 0);
        this.minAmount = Math.min(...quoteAmounts);
        this.maxAmount = Math.max(...quoteAmounts);
        this.amountRange[0] = this.minAmount;
        this.amountRange[1] = this.maxAmount;
        this.quotes = quotes;
        this.createSiteFilter(quotes);
        this.createCityFilter(quotes);
        this.createActivityFilter(quotes);
    }

    private createSiteFilter(quotes: IQuote[]) {
        quotes.forEach(quote => {
            this.sites.push(quote.siteName);
        });
        this.sites = [...new Set(this.sites)];
    }

    private createCityFilter(quotes: IQuote[]) {
        quotes.forEach(quote => {
            if (quote.city) {
                this.cities.push(quote.city);
            }
        });
        this.cities = [...new Set(this.cities)];
    }

    private createActivityFilter(quotes: IQuote[]) {
        quotes.forEach(quote => {
            this.activities.push(quote.activity);
        });
        this.cities = [...new Set(this.cities)];
    }

    private dateUpdated(quote: IQuote) {
        if (this.datesRange.length > 1)
            return moment(this.dateToString(quote.date)).isBetween(
                new Date(this.datesRange[0]).toDateString(),
                new Date(this.datesRange[1]).toDateString(),
                undefined,
                '[]'
            );
        if (this.datesRange.length > 0) return moment(this.dateToString(quote.date)).isSameOrAfter(this.dateToString(this.datesRange[0]));
        return true;
    }

    private dateToString(date: string) {
        const result = new Date(date);
        result.setHours(0, 0, 0, 0);
        return result.toDateString();
    }

    exportData(type: string) {
        this.quoteRepository()
            .getExport(type, this.toExportQuoteParams())
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    private toExportQuoteParams(): IExportQuoteParam {
        let quoteMaintenanceParams: IExportQuoteParam = {
            Activities: this.selectedActivities,
            Cities: this.selectedCities,
            Sites: this.selectedSites,
            FromCost: this.amountRange[0],
            ToCost: this.amountRange[1],
        };
        if (this.datesRange.length > 0) {
            quoteMaintenanceParams = {
                ...quoteMaintenanceParams,
                FromDate: new Date(this.datesRange[0]).toISOString(),
                ToDate: new Date(this.datesRange[1]).toISOString(),
            };
        }

        return quoteMaintenanceParams;
    }

    private siteUpdated(quote: IQuote) {
        if (this.selectedSites.length > 0) return this.selectedSites.indexOf(quote.siteName) > -1;
        return true;
    }

    private cityUpdated(quote: IQuote) {
        if (this.selectedCities.length > 0 && quote.city) return this.selectedCities.indexOf(quote.city) > -1;
        return true;
    }

    private activityUpdated(quote: IQuote) {
        if (this.selectedActivities.length > 0) return this.selectedActivities.indexOf(quote.activity) > -1;
        return true;
    }

    private amountUpdated(quotes: IQuote) {
        return quotes.cost == null || (quotes.cost >= this.amountRange[0] && quotes.cost <= this.amountRange[1]);
    }
}
