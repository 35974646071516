import VueRouter, { RouteConfig } from 'vue-router';

import { InspectionPageVue } from './primary/pages/inspection';
import { QuotesPageVue } from './primary/pages/quotes';
import { WorkSiteVue } from './primary/pages/workSite';

import { Right } from '@/domain/menu/Right';
import { Routes } from '@/domain/Routes';
import { ClientLayoutVue } from '@/primary/layouts/clientLayout';
import { GuestLayoutVue } from '@/primary/layouts/guestLayout';
import { ActivateAccountPageVue } from '@/primary/pages/activateAccount';
import { ContactPageVue } from '@/primary/pages/contact';
import { DashboardPageVue } from '@/primary/pages/dashboard';
import { DocumentPageVue } from '@/primary/pages/document';
import { ForgottenPasswordPageVue } from '@/primary/pages/forgottenPassword';
import { HistoryPageVue } from '@/primary/pages/history';
import { InstructionsPageVue } from '@/primary/pages/Instructions';
import { LoginPageVue } from '@/primary/pages/login';
import { SettingsPageVue } from '@/primary/pages/settings';
import { SitePageVue } from '@/primary/pages/site';
import { TroubleshootingPageVue } from '@/primary/pages/troubleshooting';
import { VideoCheckUpPageVue } from '@/primary/pages/videoCheckUp';

const routes: RouteConfig[] = [
    {
        path: '/',
        name: '',
        redirect: '/client/dashboard',
        component: GuestLayoutVue,
        children: [
            {
                name: Routes.LoginPage,
                path: '/login',
                component: LoginPageVue,
            },
            {
                name: Routes.ForgottenPasswordPage,
                path: '/forgotten',
                component: ForgottenPasswordPageVue,
            },
            {
                name: Routes.ActivateAccountPage,
                path: '/activate',
                component: ActivateAccountPageVue,
            },
        ],
    },
    {
        path: '/client',
        name: '',
        redirect: '/client/dashboard/',
        component: ClientLayoutVue,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'dashboard',
                name: Routes.DashboardPage,
                component: DashboardPageVue,
            },
            {
                path: 'document',
                name: Routes.DocumentPage,
                component: DocumentPageVue,
            },
            {
                path: 'settings',
                name: Routes.SettingsPage,
                component: SettingsPageVue,
                meta: {
                    name: 'Mes paramètres',
                    icon: 'icon-settings',
                },
            },
            {
                path: 'historique',
                name: Routes.HistoryPage,
                component: HistoryPageVue,
                meta: {
                    name: 'Mes Historiques',
                    icon: 'icon-history',
                    authorize: [Right.CNS_HIS, Right.CNS_ALA],
                },
            },
            {
                path: 'consignes',
                name: Routes.InstructionsPage,
                component: InstructionsPageVue,
                meta: {
                    name: 'Mes Consignes',
                    icon: 'icon-consignes',
                    authorize: [Right.CNS_CSG, Right.CNS_HOR],
                },
            },
            {
                path: 'video',
                name: Routes.VideoCheckUpPage,
                component: VideoCheckUpPageVue,
                meta: {
                    name: 'Demande de rondes vidéos',
                    icon: 'icon-video',
                    authorize: [Right.CNS_PAT],
                },
            },
            {
                path: 'contact',
                name: Routes.Contact,
                component: ContactPageVue,
                meta: {
                    name: 'Contact',
                    icon: 'icon-info-menu',
                },
            },
            {
                path: 'site',
                name: Routes.SitePage,
                component: SitePageVue,
                meta: {
                    name: 'Mes Sites',
                    icon: 'icon-building',
                    authorize: [Right.RS_SIT_LST],
                },
            },
            {
                path: 'depannages',
                name: Routes.TroubleshootingPage,
                component: TroubleshootingPageVue,
                meta: {
                    authorize: [Right.RS_DEPH_LST, Right.RS_TLM_LST],
                    name: 'Mes Depannages',
                },
            },
            {
                path: 'monentretien',
                name: Routes.MyInspection,
                component: InspectionPageVue,
                meta: {
                    authorize: [Right.RS_VEP_LST, Right.RS_VEH_LST],
                    name: 'Mon entretien',
                },
            },
            {
                path: 'chantiers',
                name: Routes.WorkSitePage,
                component: WorkSiteVue,
                meta: {
                    name: 'Mes Chantiers',
                    authorize: [Right.RS_TN_AVCHA],
                },
            },
            {
                path: 'devis',
                name: Routes.QuotesPage,
                component: QuotesPageVue,
                meta: {
                    name: 'Mes devis en attente',
                    icon: 'icon-receipt',
                    authorize: [Right.RS_DEVA_LST],
                },
            },
        ],
    },
];

export const router = new VueRouter({
    mode: 'history',
    routes,
});
