import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { RestInspection, toInspection } from './RestInspection';
import {
    RestPlannedDetailInspection,
    RestPlannedInspection,
    toInspectionDetailSummary,
    toInspectionSummary,
} from './RestInspectionSummary';

import { ExportRealisedParam } from '@/domain/inspection/ExportRealisedParam';
import { ICompletedInspection } from '@/domain/inspection/ICompletedInspection';
import { IInspectionRepository } from '@/domain/inspection/IInspectionRepository';
import { IPlannedDetailInspection } from '@/domain/inspection/IPlannedDetailInspection';
import { IPlannedInspection } from '@/domain/inspection/IPlannedInspection';
import { downloadFile } from '@/secondary/DownloadFile';

export class RestInspectionRepository implements IInspectionRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getInspections(): Promise<ICompletedInspection[]> {
        return this.axiosInstance
            .get('/Inspections')
            .then((response: AxiosResponse<RestInspection[]>) => {
                return response.data.map(toInspection);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getSynthesisInspectionSummary(year: number): Promise<IPlannedInspection> {
        return this.axiosInstance
            .get(`/Inspections/${year}/Synthesis`)
            .then((response: AxiosResponse<RestPlannedInspection>) => {
                return toInspectionSummary(response.data);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getDetailInspectionSummary(year: number): Promise<IPlannedDetailInspection[]> {
        return this.axiosInstance
            .get(`/Inspections/${year}/Synthesis/Details`)
            .then((response: AxiosResponse<RestPlannedDetailInspection[]>) => {
                return response.data.map(toInspectionDetailSummary);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getDetailInspectionExport(type: string, year: string): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .get(`/Inspections/${year}/Synthesis/Details/Export/${type}`, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getRealisedInspectionExport(inspectionNumber: string): Promise<void> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance.get(`/Inspection/${inspectionNumber}/Export`, params).then(downloadFile);
    }

    getExportRealised(fileType: string, exportRealisedParam: ExportRealisedParam): Promise<void> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance.post(`/Inspections/Export/${fileType}`, exportRealisedParam, params).then(downloadFile);
    }
}
