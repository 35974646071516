import { AxiosInstance, AxiosResponse } from 'axios';

import { TermsOfService } from '@/domain/termsOfService/TermsOfService';
import { TermsOfServiceRepository } from '@/domain/termsOfService/TermsOfServiceRepository';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';
import { RestUser } from '@/secondary/restUser/RestUser';

export class RestTermsOfServiceRepository implements TermsOfServiceRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    get(langage: string): Promise<TermsOfService> {
        return this.axiosInstance
            .get('/TermsOfService/' + RestThemeRepository.getNameOfSite(), { headers: { 'Accept-Language': langage } })
            .then(result => ({
                lastModified: new Date(result.headers['last-modified'] as string),
                data: result.data,
            }));
    }

    hasUserValidated(lastModified: Date): Promise<boolean> {
        return this.axiosInstance
            .get('/UserInfos')
            .then(
                (user: AxiosResponse<RestUser>) =>
                    user.data.TermsOfServiceValidationDate != undefined &&
                    user.data.TermsOfServiceValidationDate > lastModified.toISOString()
            );
    }

    validate(): Promise<void> {
        return this.axiosInstance.put('/TermsOfService/Validate');
    }
}
