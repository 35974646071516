import { ContactCallList } from '@/domain/contact/ContactCallList';
import { RestContact, toContact } from '@/secondary/restContact/RestContact';

export interface RestContactCallList extends RestContact {
    Order: number;
    Conditions?: string;
    IsNew: boolean;
}

export const toContactCallList = (restContactCallList: RestContactCallList): ContactCallList => ({
    ...toContact(restContactCallList),
    order: restContactCallList.Order,
    condition: restContactCallList.Conditions ? restContactCallList.Conditions : '',
    created: false,
});
