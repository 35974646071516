export interface SiteDetailsAddress {
    address: string;
    postalCode: string;
    city: string;
}

export const emptySiteDetailsAddress = (): SiteDetailsAddress => ({
    address: '',
    postalCode: '',
    city: '',
});
