import { Component, Vue } from 'vue-property-decorator';
import { VApp, VMain } from 'vuetify/lib';

import { Theme } from '@/domain/theme/Theme';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

@Component({
    components: {
        VApp,
        VMain,
    },
})
export default class GuestHeader extends Vue {
    theme!: Theme;

    created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;
    }
}
