export interface ExportEventParam {
    SiteReferences?: string[];
    FromDate?: string;
    ToDate?: string;
    EventTypes?: number[];
    AttachmentTypes?: number[];
}

export enum TypeEvent {
    'Pdf',
    'Excel',
    'Csv',
}

export enum TypePrimary {
    'Information',
    'Alarm',
    'Warning',
}

export const typePrimary = (): Map<string, number> =>
    new Map([
        ['Information', 0],
        ['Alarme', 1],
        ['Avertissement', 2],
    ]);

export const mediaTypePrimary = (): Map<string, number> =>
    new Map([
        ['Aucun', 0],
        ['Rapport Télésurveillance', 1],
        ['Rapport Télésurveillance avec envoi intervenant', 2],
        ['Rapport Télémaintenance', 3],
        ['Photo', 4],
        ['Video', 5],
    ]);
