import { IRule } from './IRule';

export class RequiredRule implements IRule {
    passed: boolean;
    description: string;
    descriptionMobile: string;
    showDescription: boolean;

    constructor(showDescription: boolean) {
        this.description = 'Champ requis';
        this.descriptionMobile = 'Champ requis';
        this.passed = false;
        this.showDescription = showDescription;
    }

    check(value: string): boolean | string {
        this.passed = value != undefined && value != null && value.length > 0;

        return this.passed ? this.passed : this.description;
    }
}
