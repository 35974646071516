import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { mobileAccessHeader } from '@/domain/header/mobileAccessHeader';
import { IRule } from '@/domain/rules/IRule';
import { MinCharMatchRule } from '@/domain/rules/minCharMatchRule';
import { MinLengthRule } from '@/domain/rules/minLengthRule';
import { RequiredRule } from '@/domain/rules/requiredRule';
import { ValueMatchRule } from '@/domain/rules/valueMatchRule';
import { IToast } from '@/domain/toast/IToast';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import { Device } from '@/domain/user/Device';
import { emptyFullUser, FullUser } from '@/domain/user/FullUser';
import { MailAlertFrequency } from '@/domain/user/MailAlertFrequency';
import { MailAlertType } from '@/domain/user/MailAlertType';
import { emptyUserAlerts, UserAlerts } from '@/domain/user/UserAlerts';
import { UserRepository } from '@/domain/user/UserRepository';
import { IconVue } from '@/primary/components/common/icon';
import { Tab } from '@/primary/components/navbar/Tab';
import { Responsive } from '@/primary/utils/Responsive';
import { RightMixin } from '@/primary/utils/rights/RightMixin';
import ToastBus from '@/primary/utils/ToastBus';

@Component({
    components: {
        IconVue,
    },
})
export default class SettingsComponent extends mixins(RightMixin) {
    @Inject()
    private toastBus!: () => ToastBus;

    @Inject()
    private userRepository!: () => UserRepository;

    @Inject()
    private responsive!: () => Responsive;

    user: FullUser = emptyFullUser();

    newNumber = '';
    numberConfirmation = '';
    telephoneNumberRules = /^((\+)33|0)[1-9](\d{2}){4}$/;
    isTelephoneNumberValid = true;

    newMail = '';
    mailConfirmation = '';
    mailRules =
        /^(([^#<>()\[\]\\.,;:\s@À-ÖØ-öø-ÿ"]+(\.[^#<>()\[\]\\.,;:\s@À-ÖØ-öø-ÿ"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isMailValid = true;
    userAlerts: UserAlerts = emptyUserAlerts();
    comboAlertFrequency: MailAlertFrequency[] = Object.values(MailAlertFrequency);
    comboAlertType: MailAlertType[] = Object.values(MailAlertType);

    tabs: Tab[] = [];
    tabsNumberNavigationBusId = 0;
    tab = 0;

    oldPassword = '';
    password = '';
    passwordConfirm = '';
    showPassword = false;
    showNewPassword = false;
    showPasswordConfirm = false;
    specialCharacters = '!/;:?,%';
    passwordRules = [
        new RequiredRule(false),
        new MinLengthRule(8, true),
        new MinCharMatchRule(1, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'Majuscule', true, 'Maj'),
        new MinCharMatchRule(1, 'abcdefghijklmnopqrstuvwxyz', 'Minuscule', true, 'Min'),
        new MinCharMatchRule(1, '0123456789', 'Chiffre', true, 'Chiffre'),
        new MinCharMatchRule(1, this.specialCharacters, 'Spécial ' + this.specialCharacters, true, 'Spécial'),
    ];
    passwordConfirmRules = [
        new RequiredRule(false),
        new ValueMatchRule(() => this.getPassword(), 'Les mots de passe ne correspondent pas', false),
    ];

    devices: Device[] = [];
    headers: DataTableHeader[] = mobileAccessHeader();

    modificationAlert = false;
    isEditMode = false;
    oldUser = { ...this.user };
    newTwoFactorAuth = this.user.twoFactorAuthentificationMode;

    mounted() {
        this.userRepository()
            .getFullUser()
            .then(user => {
                this.user = user;
                this.newTwoFactorAuth = this.user.twoFactorAuthentificationMode;
            });
        this.userRepository()
            .getUserAlerts()
            .then(userAlerts => (this.userAlerts = userAlerts));
        this.userRepository()
            .getModificationAlert()
            .then(modificationAlert => (this.modificationAlert = modificationAlert));
        this.getUserDevices();
    }

    isFormValid(): boolean {
        return (
            this.allRulesPassed(this.passwordRules, this.password, true) &&
            this.allRulesPassed(this.passwordConfirmRules, this.passwordConfirm, true)
        );
    }

    allRulesPassed(rules: IRule[], value = '', update = false): boolean {
        let ok = true;
        for (const rule of rules) {
            if (update) rule.check(value);
            ok = ok && rule.passed;
        }
        return ok;
    }

    getRulesToDisplay(rules: IRule[]): IRule[] {
        return rules.filter(r => r.showDescription);
    }

    getColumnSize(text: string): number {
        if (text.length > 18) return 4;
        if (text.length > 10) return 3;
        if (text.length > 6) return 2;
        return 1;
    }

    getRules(rules: IRule[]): ((v: string) => boolean | string)[] {
        return rules.map(r => (v: string) => r.check(v));
    }

    getPassword(): string {
        return this.password;
    }

    toEditMode(): void {
        this.isEditMode = true;
        this.oldUser = { ...this.user };
    }

    cancelUpdate(): void {
        this.isEditMode = false;
        this.user = { ...this.oldUser };
    }

    updateUser(): void {
        this.isEditMode = false;
        this.userRepository().updateUser(this.user);
    }

    updatePhone() {
        this.user.phone = this.newNumber;
        this.userRepository()
            .updateUser(this.user)
            .then(() => {
                this.newNumber = '';
                this.numberConfirmation = '';
            });
    }

    updateTwoFactorAuth() {
        this.user.twoFactorAuthentificationMode = this.newTwoFactorAuth;
        this.userRepository()
            .updateUser(this.user)
            .then(() => {
                this.newTwoFactorAuth = this.user.twoFactorAuthentificationMode;
            });
    }

    updateMail() {
        this.user.mail = this.newMail;
        this.userRepository()
            .updateUser(this.user)
            .then(() => {
                this.newMail = '';
                this.mailConfirmation = '';
            });
    }

    updatePassword() {
        this.userRepository()
            .updatePassword(this.oldPassword, this.password)
            .then(() => {
                const toast: IToast = { status: ToastStatus.SUCCESS, text: 'Mot de passe modifié' };
                this.toastBus().add(toast);

                this.oldPassword = '';
                this.password = '';
                this.passwordConfirm = '';
                // eslint-disable-next-line
                (this.$refs.mdp as any).reset();
                // eslint-disable-next-line
                (this.$refs.mdpConfirm as any).reset();
            });
    }

    checkMailValidation(): void {
        this.isMailValid = this.newMail.length == 0 || this.mailRules.test(this.newMail);
    }

    checkNumberValidation(): void {
        this.isTelephoneNumberValid = this.newNumber.length == 0 || this.telephoneNumberRules.test(this.newNumber);
    }

    updateAlerts(): void {
        this.userRepository().updateUserAlerts(this.userAlerts);
    }

    updateModificationAlerts(): void {
        this.userRepository().updateModificationAlerts(this.modificationAlert);
    }

    saveDeviceState(device: Device): void {
        this.userRepository()
            .updateUserDevice(device.id, device.state)
            .then(() => this.getUserDevices());
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    private getUserDevices() {
        this.userRepository()
            .getUserDevices()
            .then(devices => (this.devices = devices));
    }
}
