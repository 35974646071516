import moment from 'moment';
import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';

import { ContactInformationsModify } from '@/domain/contact/ContactInformationsModify';
import { User } from '@/domain/user/User';
import { UserRepository } from '@/domain/user/UserRepository';
import { IconVue } from '@/primary/components/common/icon';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import { WarningInformationsVue } from '@/primary/components/ModifyContactModal/WarningInformations';
import { ModifyPhoneContactVue } from '@/primary/components/modifyPhoneContact';
import ModifyInformationsContactBus from '@/primary/pages/Instructions/ModifyInformationsContactBus';

@Component({
    components: {
        IconVue,
        WarningInformationsVue,
        ModifyPhoneContactVue,
    },
})
export default class ModifyContactModalSecondStepComponent extends Vue {
    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private modifyInformationsContactBus!: () => ModifyInformationsContactBus;

    @Inject()
    private userRepository!: () => UserRepository;

    @Prop({ required: true })
    private contactInformations!: ContactInformationsModify;

    @Emit()
    closeModal() {}

    asSoonAsPossible = true;
    permanent = true;
    startDateMenu = false;
    endDateMenu = false;
    customStartDateSelected = false;
    customStartDateSelectedData = '';
    customEndDateSelected = false;
    customEndDateSelectedData = '';
    selectedStartDate = '';
    selectedEndDate = '';
    user: User = { firstName: '', lastName: '', phone: '' };
    activeStep = 2;
    informationsText = `Les demandes de modification sont prises en compte sous 72h par le centre de télésurveillance.
                                La mise en application de votre demande nécessite au préalable une validation de la part de nos services.
                                Ceux-ci pourront éventuellement vous contacter au numéro de téléphone demandé dans ce formulaire.`;

    get formatCustomStartDate() {
        return this.customStartDateSelectedData.length > 0
            ? moment(new Date(this.customStartDateSelectedData)).locale('fr').format('L')
            : '';
    }

    get formatCustomEndDate() {
        return this.customEndDateSelectedData.length > 0 ? moment(new Date(this.customEndDateSelectedData)).locale('fr').format('L') : '';
    }

    mounted() {
        this.userRepository()
            .get()
            .then(user => {
                this.user = user;
            });
    }

    nextStep() {
        this.setValues();
        this.setComplementaryContactInformations();
        this.activeStep++;
        this.navigationButtonBus().add(this.activeStep);
    }

    prevStep() {
        this.activeStep--;
        this.navigationButtonBus().add(this.activeStep);
    }

    soonAsPossibleWasSelected() {
        this.customStartDateSelected = false;
    }

    permanentWasSelected() {
        this.customEndDateSelected = false;
    }

    startDateWasSelected() {
        this.asSoonAsPossible = false;
    }

    endDateWasSelected() {
        this.permanent = false;
    }

    updatePhone(phone: string) {
        this.user.phone = phone;
    }

    private setValues() {
        if (this.asSoonAsPossible) this.selectedStartDate = 'Au plus tôt';
        else this.selectedStartDate = moment(new Date(this.customStartDateSelectedData)).locale('fr').format('L');

        if (this.permanent) this.selectedEndDate = 'Permanente';
        else this.selectedEndDate = moment(new Date(this.customEndDateSelectedData)).locale('fr').format('L');
    }

    private setComplementaryContactInformations() {
        const contactInformations: ContactInformationsModify = { ...this.contactInformations };
        const user: User = { firstName: this.user.firstName, lastName: this.user.lastName, phone: this.user.phone };
        contactInformations.dateStart = this.selectedStartDate;
        contactInformations.dateEnd = this.selectedEndDate;
        contactInformations.userInformation = user;
        this.modifyInformationsContactBus().add(contactInformations);
    }
}
