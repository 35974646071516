import { Store } from 'vuex';

import { CallListModifyPrimary } from '@/primary/components/modifyCallListModal/modifyCallListModalFirstStep/CallListModifyPrimary';
import { CallListStoreRepository } from '@/secondary/store/callListStore/CallListStoreRepository';

export class LocaleCallListStoreRepository implements CallListStoreRepository {
    constructor(private store: Store<any>) {}

    getCallListModify(): CallListModifyPrimary | undefined {
        return this.store.getters['callListModuleModal/callList'].CallList == undefined
            ? undefined
            : this.store.getters['callListModuleModal/callList'];
    }

    modifyCallListModify(callList: CallListModifyPrimary): void {
        this.store.commit('callListModuleModal/modifyCallList', callList);
    }

    removeCallListModify(): void {
        this.store.commit('callListModuleModal/modifyCallList', {});
    }
}
