export const userManagementHeaders = () => [
    {
        text: '',
        value: 'select',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Nom',
        value: 'lastName',
        sortable: true,
        filterable: true,
    },
    {
        text: 'Prénom',
        value: 'firstName',
        sortable: true,
        filterable: true,
    },
    {
        text: 'Email',
        value: 'email',
        sortable: true,
        filterable: true,
    },
    {
        text: 'Téléphone',
        value: 'phone',
        sortable: true,
        filterable: true,
    },
    {
        text: 'Droits',
        value: 'profil',
        sortable: true,
        filterable: false,
    },
    {
        text: 'Double auth.',
        value: 'doubleAuthType',
        sortable: true,
        filterable: false,
    },
    {
        text: 'Sites',
        value: 'sites',
        sortable: false,
        filterable: false,
    },
    {
        text: '',
        value: 'modify',
        sortable: false,
        filterable: false,
    },
];
