import { VideoPatrol } from '@/domain/video/VideoPatrol';

export interface RestVideoPatrol {
    Id: string;
    Code: string;
    Slot: number;
    From: string;
    To: string;
    FixedHours: boolean;
    Type: number;
}

export const toVideoPatrol = (restVideoPatrol: RestVideoPatrol): VideoPatrol => ({
    id: restVideoPatrol.Id,
    title: restVideoPatrol.Code,
    permanent: restVideoPatrol.Type != 2,
    start: new Date(restVideoPatrol.From),
    end: restVideoPatrol.FixedHours ? undefined : new Date(restVideoPatrol.To),
    slot: restVideoPatrol.Slot - 1,
});
