import { Prestation } from '@/domain/contact/Prestation';

export interface RestPrestation {
    Code: string;
    Instructions: string[];
    Label: string;
}

export const toPrestation = (restPrestation: RestPrestation): Prestation => ({
    code: restPrestation.Code,
    instruction: restPrestation.Instructions.length > 0 ? restPrestation.Instructions[0] : undefined,
    label: restPrestation.Label.replace('PRESTATION :', '').toLowerCase(),
});
