import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';

import { emptySite, SiteMap } from '@/domain/site/SiteMap';
import { SiteRepository } from '@/domain/site/SiteRepository';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { VideoCreditVue } from '@/primary/components/videoCredit';
import { VideoInstructionVue } from '@/primary/components/videoInstruction';
import { VideoPatrolVue } from '@/primary/components/videoPatrol';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
        VideoCreditVue,
        VideoInstructionVue,
        VideoPatrolVue,
    },
})
export default class VideoCheckUpPageComponent extends mixins(RightMixin) {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private siteRepository!: () => SiteRepository;

    sites: SiteMap[] = [];
    siteSelected: SiteMap = emptySite();

    mounted() {
        this.pageNameBus().add('Demande de rondes vidéos');
        this.siteRepository()
            .getSiteWithTvi()
            .then(sites => {
                this.sites = sites;
                this.siteSelected = this.sites.length > 0 ? this.sites[0] : emptySite();
            });
    }
}
