import { Store } from 'vuex';

import { StoreAccount } from '@/secondary/restAccount/StoreAccount';
import { StoreRepository } from '@/secondary/store/localeStore/StoreRepository';

export class LocaleStoreRepository implements StoreRepository {
    constructor(private store: Store<any>) {}

    getUser(): StoreAccount | undefined {
        return this.store.getters['localStorageModule/user'].userToken == undefined
            ? undefined
            : this.store.getters['localStorageModule/user'];
    }

    getHasSites(): boolean | undefined {
        return this.store.getters['localStorageModule/hasSites'];
    }

    getUserToken(): string | undefined {
        const user = this.getUser();
        return user ? user.userToken : undefined;
    }

    modifyUser(storeAccount: StoreAccount): void {
        this.store.commit('localStorageModule/modifyUser', storeAccount);
    }

    modifyUserRights(rights: string[]): void {
        this.store.commit('localStorageModule/modifyUserRights', rights);
    }

    modifyHasSites(hasSite: boolean): void {
        this.store.commit('localStorageModule/modifyHasSites', hasSite);
    }

    removeUser(): void {
        this.store.commit('localStorageModule/modifyUser', {});
    }

    isConnected(): boolean {
        return this.getUser() != undefined;
    }
}
