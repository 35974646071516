import { AxiosError } from 'axios';
import moment from 'moment';
import { Component, Vue, Inject } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { remoteMaintenanceHeader, remoteMaintenanceHeaderMobile } from '@/domain/header/remoteMaintenanceHeader';
import { ExportRemoteMaintenanceParam } from '@/domain/troubleshooting/ExportRemoteMaintenanceParams';
import { IRemoteMaintenance } from '@/domain/troubleshooting/IRemoteMaintenance';
import { IRemoteMaintenanceRepository } from '@/domain/troubleshooting/IRemoteMaintenanceRepository';
import { IconVue } from '@/primary/components/common/icon';
import { Responsive } from '@/primary/utils/Responsive';

@Component({
    components: {
        IconVue,
    },
})
export default class RemoteMaintenance extends Vue {
    @Inject()
    private responsive!: () => Responsive;

    @Inject()
    private remoteMaintenanceRepository!: () => IRemoteMaintenanceRepository;

    headers: DataTableHeader[] = remoteMaintenanceHeader();
    headersMobile: DataTableHeader[] = remoteMaintenanceHeaderMobile();

    remoteMaintenances: IRemoteMaintenance[] = [];

    activities: string[] = [];
    selectedActivities: string[] = [];
    sites: string[] = [];
    selectedSites: string[] = [];
    cities: string[] = [];
    selectedCities: string[] = [];
    applicationDateRange = [];
    closingDateRange = [];
    statuses: string[] = [];
    selectedStatuses: string[] = [];

    isStartDateOpen = false;
    isEndDateOpen = false;

    isStartDateMobileOpen = false;
    isEndDateMobileOpen = false;

    search = '';

    get applicationDateModel() {
        if (this.applicationDateRange.length > 0) {
            const dates = this.applicationDateRange.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get closingDateModel() {
        if (this.closingDateRange.length > 0) {
            const dates = this.closingDateRange.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    get updateData() {
        return this.remoteMaintenances.filter(record => {
            return (
                this.siteUpdated(record) &&
                this.cityUpdated(record) &&
                this.applicationDateUpdated(record) &&
                this.closingDateUpdated(record) &&
                this.statusUpdated(record)
            );
        });
    }

    get siteOrdered() {
        return this.sites
            .sort((a, b) => a.localeCompare(b))
            .sort((a, b) => {
                const hasA = this.selectedSites.find(site => site === a) !== undefined;
                const hasB = this.selectedSites.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    mounted() {
        this.remoteMaintenanceRepository()
            .getRemoteMaintenance()
            .then(data => {
                this.remoteMaintenances = data;
                this.fromDomain(this.remoteMaintenances);
            })
            .catch((error: AxiosError) => {
                console.log(error);
            });
    }

    exportData(type: string) {
        this.remoteMaintenanceRepository()
            .getExport(type, this.toExportMaintenanceParams())
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    private toExportMaintenanceParams(): ExportRemoteMaintenanceParam {
        let remoteMaintenanceParams: ExportRemoteMaintenanceParam = {
            Cities: this.selectedCities,
            Sites: this.selectedSites,
            Statuses: this.selectedStatuses,
        };
        if (this.applicationDateRange.length > 0) {
            remoteMaintenanceParams = {
                ...remoteMaintenanceParams,
                FromApplicationDate: new Date(this.applicationDateRange[0]).toISOString(),
                ToApplicationDate: new Date(this.applicationDateRange[1]).toISOString(),
            };
        }
        if (this.closingDateRange.length > 0) {
            remoteMaintenanceParams = {
                ...remoteMaintenanceParams,
                FromClosingDate: new Date(this.closingDateRange[0]).toISOString(),
                ToClosingDate: this.closingDateRange[1] ? new Date(this.closingDateRange[1]).toISOString() : '',
            };
        }

        return remoteMaintenanceParams;
    }

    private fromDomain(maintenanceRecords: IRemoteMaintenance[]) {
        this.remoteMaintenances = maintenanceRecords;
        this.createSiteFilter(maintenanceRecords);
        this.createCityFilter(maintenanceRecords);
        this.createStatusFilter(maintenanceRecords);
    }

    private createSiteFilter(maintenanceRecords: IRemoteMaintenance[]) {
        maintenanceRecords.forEach(record => {
            this.sites.push(record.site);
        });
        this.sites = [...new Set(this.sites)];
    }

    private createCityFilter(maintenanceRecords: IRemoteMaintenance[]) {
        maintenanceRecords.forEach(record => {
            this.cities.push(record.city);
        });
        this.cities = [...new Set(this.cities)];
    }

    private createStatusFilter(maintenanceRecords: IRemoteMaintenance[]) {
        maintenanceRecords.forEach(record => {
            this.statuses.push(record.status);
        });
        this.statuses = [...new Set(this.statuses)];
    }

    private siteUpdated(maintenanceRecord: IRemoteMaintenance) {
        if (this.selectedSites.length > 0) return this.selectedSites.indexOf(maintenanceRecord.site) > -1;
        return true;
    }

    private cityUpdated(maintenanceRecord: IRemoteMaintenance) {
        if (this.selectedCities.length > 0) return this.selectedCities.indexOf(maintenanceRecord.city) > -1;
        return true;
    }

    private statusUpdated(maintenanceRecord: IRemoteMaintenance) {
        if (this.selectedStatuses.length > 0) return this.selectedStatuses.indexOf(maintenanceRecord.status) > -1;
        return true;
    }

    private applicationDateUpdated(maintenanceRecord: IRemoteMaintenance) {
        if (this.applicationDateRange.length > 0)
            return moment(new Date(maintenanceRecord.applicationDate).toDateString()).isBetween(
                new Date(this.applicationDateRange[0]).toDateString(),
                new Date(this.applicationDateRange[1]).toDateString(),
                undefined,
                '[]'
            );
        if (this.applicationDateRange.length > 0)
            return moment(new Date(maintenanceRecord.applicationDate).toDateString()).isSameOrAfter(
                new Date(this.applicationDateRange[0]).toDateString()
            );
        if (this.applicationDateRange.length > 1)
            return moment(new Date(maintenanceRecord.applicationDate).toDateString()).isSameOrBefore(
                new Date(this.applicationDateRange[1]).toDateString()
            );
        return true;
    }

    private closingDateUpdated(maintenanceRecord: IRemoteMaintenance) {
        if (this.closingDateRange.length > 0)
            return moment(new Date(maintenanceRecord.closingDate).toDateString()).isBetween(
                new Date(this.closingDateRange[0]).toDateString(),
                new Date(this.closingDateRange[1]).toDateString()
            );
        if (this.closingDateRange.length > 0)
            return moment(new Date(maintenanceRecord.closingDate).toDateString()).isSameOrAfter(
                new Date(this.closingDateRange[0]).toDateString()
            );
        if (this.closingDateRange.length > 1)
            return moment(new Date(maintenanceRecord.closingDate).toDateString()).isSameOrBefore(
                new Date(this.closingDateRange[1]).toDateString()
            );
        return true;
    }
}
