import { Contact } from '@/domain/contact/Contact';
import { ReplacedContact } from '@/domain/contact/ReplacedContact';
import { Site } from '@/domain/contact/Site';
import { emptyUser, User } from '@/domain/user/User';

export interface ContactInformationsModify {
    sites: Site[];
    removedContact?: Contact[];
    addedContact?: Contact[];
    replacedContact?: ReplacedContact[];
    dateStart: string;
    dateEnd: string;
    userInformation?: User;
}

export const emptyContactInformationsModify = (): ContactInformationsModify => ({
    sites: [],
    removedContact: [],
    addedContact: [],
    replacedContact: [],
    dateStart: '',
    dateEnd: '',
    userInformation: emptyUser(),
});
