import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';

import { ContactInformationsModify } from '@/domain/contact/ContactInformationsModify';
import { ContactRepository } from '@/domain/contact/ContactRepository';
import { IconVue } from '@/primary/components/common/icon';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';

@Component({
    components: {
        IconVue,
    },
})
export default class ModifyContactModalThirdStepComponent extends Vue {
    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private contactRepository!: () => ContactRepository;

    @Prop({ required: true })
    private contactInformations!: ContactInformationsModify;

    @Emit()
    private closeModal() {}

    activeStep = 3;
    headersContacts: any = [
        {
            text: 'Nom Prénom',
            value: 'name',
        },
        {
            text: 'Numéro de téléphone',
            value: 'phone',
        },
    ];
    headersNewContacts: any = [
        {
            text: 'Contact à remplacer',
            value: 'old',
        },
        {
            text: 'Contact remplaçant',
            value: 'new',
        },
    ];
    error = '';

    nextStep() {
        this.contactRepository()
            .updateContacts(this.contactInformations)
            .then(() => {
                this.activeStep++;
                this.navigationButtonBus().add(this.activeStep);
            })
            .catch((error: Error) => {
                this.error = error.message;
            });
    }

    prevStep() {
        this.activeStep--;
        this.navigationButtonBus().add(this.activeStep);
    }

    get contactAddedByReplaced() {
        return this.contactInformations.replacedContact?.filter(contact => contact.isNew).map(contact => contact.new);
    }
}
