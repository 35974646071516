import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Site } from '@/domain/contact/Site';
import { TimeGridRepository } from '@/domain/timeGrid/TimeGridRepository';
import { TimeGridSiteGroup } from '@/domain/timeGrid/TimeGridSiteGroup';
import { IconVue } from '@/primary/components/common/icon';
import { ContractGridTimeVue } from '@/primary/components/contractGridTime';
import { SiteWithGroups } from '@/primary/components/gridTimeList/siteWithGroups';
import { WeekGridTimeVue } from '@/primary/components/weekGridTime';

@Component({
    components: {
        ContractGridTimeVue,
        WeekGridTimeVue,
        IconVue,
    },
})
export default class GridTimeListComponent extends Vue {
    @Inject()
    private timeGridRepository!: () => TimeGridRepository;

    @Prop({ required: true })
    private sites!: Site[];

    groups: TimeGridSiteGroup[][] = [];
    sitesWithGroups: SiteWithGroups[] = [];
    collapsed: boolean[] = [];
    reload: number[] = [];
    loading: boolean[] = [];

    mounted() {
        this.sites.forEach((site, index) => {
            this.sitesWithGroups.push({
                ...site,
                groups: [],
                weekKey: 0,
            });
            this.collapsed[index] = true;
            this.reload[index] = 0;
            this.loading[index] = false;
        });
    }

    swapCollaps(index: number) {
        this.collapsed[index] = !this.collapsed[index];
        if (this.collapsed) this.getTimeGridGroup(index);
        this.reload[index]++;
    }

    reloadWeekGrid(siteReference: string) {
        const result = this.sitesWithGroups.find(s => s.reference == siteReference);
        result!.weekKey++;
    }

    hasGroup(site: Site) {
        const result = this.sitesWithGroups.find(s => site.reference == s.reference);
        return result && result.groups.length > 0;
    }

    getGroups(site: Site) {
        return this.sitesWithGroups.find(s => s.reference === site.reference)!.groups;
    }

    private getTimeGridGroup(index: number) {
        this.sitesWithGroups.forEach((site, i) => {
            if (index === i) {
                this.loading[index] = true;
                this.timeGridRepository()
                    .getGroups(site.reference)
                    .then(timeGridGroup => (site.groups = timeGridGroup))
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading[index] = false;
                    });
            }
        });
    }
}
