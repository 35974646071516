import { Component, Prop, Vue } from 'vue-property-decorator';

import { IconVue } from '@/primary/components/common/icon';

@Component({
    components: {
        IconVue,
    },
})
export default class WarningInformationsComponent extends Vue {
    @Prop({ required: true })
    private text!: string;
}
