import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { RestWorkSite, toWorkSite } from './RestWorkSite';

import { ExportWorksitesParam } from '@/domain/workSite/ExportWorkSitesParam';
import { IWorkSite } from '@/domain/workSite/IWorkSite';
import { IWorkSiteRepository } from '@/domain/workSite/IWorkSiteRepository';
import { downloadFile } from '@/secondary/DownloadFile';
import { ErrorMessage } from '@/secondary/error/ErrorMessage';

export class RestWorkSiteRepository implements IWorkSiteRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getWorkSites(): Promise<IWorkSite[]> {
        return this.axiosInstance
            .get('/Worksites')
            .then((response: AxiosResponse<RestWorkSite[]>) => {
                return response.data.map(toWorkSite);
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }

    getExport(type: string, exportParams: ExportWorksitesParam): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .post(`/Worksites/Export/${type}`, exportParams, params)
            .then(downloadFile)
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }

    getWorkSiteDetailsReport(worksiteNumber: string): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .get(`/WorkSites/${worksiteNumber}/Details/Export`, params)
            .then(downloadFile)
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }
}
