import { Component, Emit, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { Contact, emptyContact } from '@/domain/contact/Contact';
import { ContactInformationsModify } from '@/domain/contact/ContactInformationsModify';
import { ReplacedContact } from '@/domain/contact/ReplacedContact';
import { Site } from '@/domain/contact/Site';
import { IconVue } from '@/primary/components/common/icon';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import { WarningInformationsVue } from '@/primary/components/ModifyContactModal/WarningInformations';
import ModifyInformationsContactBus from '@/primary/pages/Instructions/ModifyInformationsContactBus';

@Component({
    components: {
        IconVue,
        WarningInformationsVue,
    },
})
export default class ModifyContactModalFirstStepComponent extends Vue {
    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private modifyInformationsContactBus!: () => ModifyInformationsContactBus;

    @Emit()
    closeModal() {
        this.clearAll();
    }

    @Prop({ required: true })
    private contactInformations!: ContactInformationsModify;

    @Prop({ required: true })
    private sites!: Site[];

    activeStep = 1;
    contactModifyInformations: Contact = emptyContact();
    substituteContactModel: Contact = emptyContact();
    replacedContactModel: Contact = emptyContact();
    replacedContactsSelected: ReplacedContact[] = [];
    siteSelected: Site[] = [];
    allSites = false;
    contactSelections: Contact[] = [];
    removeContactModel: Contact = emptyContact();
    removeContactsSelected: Contact[] = [];
    addContactName = '';
    addContactPhone = '';
    addContactPhoneSecondary = '';
    addContactList: Contact[] = [];
    headersContacts: DataTableHeader[] = [
        {
            text: 'Nom Prénom',
            value: 'name',
        },
        {
            text: 'Numéro de téléphone',
            value: 'phone',
        },
    ];
    headersNewContacts: any = [
        {
            text: 'Contact à remplacer',
            value: 'old',
        },
        {
            text: 'Contact remplaçant',
            value: 'new',
        },
    ];
    sitesSelectedHasEmpty = false;
    noDataToBeChanged = false;
    errorPhone = { state: false, message: 'Format invalide' };
    errorName = { state: false, message: 'Champs obligatoire' };
    NEW_NAME = 'Nouveau contact';
    newContactMod = false;

    get contactSelectionsOrdered() {
        return this.contactSelections.sort((a, b) => a.name.localeCompare(b.name));
    }

    get contactSelectionsWithNew() {
        const newContact: Contact = { name: this.NEW_NAME, phone: '', email: '', isReadonly: false };
        return [...this.contactSelectionsOrdered, newContact];
    }

    @Watch('substituteContactModel')
    handleNewContact() {
        if (this.substituteContactModel == null) {
            this.substituteContactModel = emptyContact();
            this.newContactMod = false;
        } else if (this.substituteContactModel.name === this.NEW_NAME) {
            this.newContactMod = true;
        }
    }

    mounted() {
        this.initData();
    }

    addContact() {
        this.clearError();
        if (this.addContactName.length == 0) {
            this.errorName.state = true;
            return;
        }
        if (!/[0-9]{10}/g.test(this.addContactPhone)) {
            this.errorPhone.state = true;
            return;
        }
        const contact: Contact = { name: this.addContactName, phone: this.addContactPhone, email: '', isReadonly: false };
        this.errorPhone.state = false;
        this.errorName.state = false;
        this.addContactList.push(contact);
        this.contactSelections.push(contact);
        this.clearAddedContact();
    }

    phoneCheck(value: string) {
        return /^((\+)33|0)[1-9](\d{2}){4}$/g.test(value) || 'Format invalide';
    }

    addRemoveContact() {
        const item = this.removeContactModel;
        if (item.name.length > 0) {
            this.removeContactsSelected.push(item);
            this.contactSelections.splice(this.contactSelections.indexOf(item), 1);
        }
        this.clearRemovedContact();
    }

    addReplacedContact() {
        if (
            this.replacedContactModel.name == emptyContact().name ||
            this.substituteContactModel.name == emptyContact().name ||
            (this.substituteContactModel.name == this.NEW_NAME &&
                (this.addContactName == '' || this.addContactPhone == '' || this.phoneCheck(this.addContactPhone) != true))
        )
            return;
        else {
            if (this.substituteContactModel.name !== this.NEW_NAME) {
                const item: ReplacedContact = {
                    old: this.replacedContactModel,
                    new: this.substituteContactModel,
                    isNew: false,
                };
                this.replacedContactsSelected.push(item);
            } else {
                let newContact: Contact = {
                    name: this.addContactName,
                    phone: this.addContactPhone,
                    email: '',
                    isReadonly: false,
                };
                if (this.addContactPhoneSecondary.length > 0) newContact = { ...newContact, phone2: this.addContactPhoneSecondary };
                const item: ReplacedContact = {
                    old: this.replacedContactModel,
                    new: newContact,
                    isNew: true,
                };
                this.replacedContactsSelected.push(item);
            }
            this.clearRemplacedContact();
        }
    }

    selectRemoveContact(item: Contact) {
        this.removeContactModel = item;
    }

    selectReplacedContact(item: Contact) {
        this.replacedContactModel = item;
    }

    selectSubstituteContact(item: Contact) {
        this.substituteContactModel = item;
    }

    removeDeletedContact(index: number) {
        this.contactSelections.push(this.removeContactsSelected[index]);
        this.removeContactsSelected.splice(index, 1);
    }

    removeReplacedContact(index: number) {
        this.replacedContactsSelected.splice(index, 1);
    }

    removeAddedContact(index: number, item: Contact) {
        this.addContactList.splice(index, 1);
        this.contactSelections.splice(this.contactSelections.indexOf(item), 1);
    }

    selectAllSites() {
        if (this.allSites) this.siteSelected = [...this.sites];
        else this.siteSelected = [];
        this.reloadContact();
    }

    nextStep() {
        if (this.siteSelected.length == 0) {
            this.sitesSelectedHasEmpty = true;
        } else if (
            this.siteSelected.length > 0 &&
            this.addContactList.length == 0 &&
            this.removeContactsSelected.length == 0 &&
            this.replacedContactsSelected.length == 0
        ) {
            this.noDataToBeChanged = true;
        } else {
            this.setContactInformations();
            this.sitesSelectedHasEmpty = false;
            this.noDataToBeChanged = false;
            this.activeStep++;
            this.navigationButtonBus().add(this.activeStep);
        }
    }

    prevStep() {
        this.activeStep--;
        this.navigationButtonBus().add(this.activeStep);
    }

    reloadContact() {
        this.contactsBySelectedSite(this.siteSelected);
    }

    private clearError() {
        this.errorPhone.state = false;
        this.errorName.state = false;
    }

    private initData() {
        this.addContactList = this.contactInformations.addedContact ? this.contactInformations.addedContact : [];
        this.removeContactsSelected = this.contactInformations.removedContact ? this.contactInformations.removedContact : [];
        this.replacedContactsSelected = this.contactInformations.replacedContact ? this.contactInformations.replacedContact : [];
        this.siteSelected = this.contactInformations.sites;
    }

    private setContactInformations() {
        const contactInformations: ContactInformationsModify = { ...this.contactInformations };
        contactInformations.addedContact = this.addContactList;
        contactInformations.removedContact = this.removeContactsSelected;
        contactInformations.replacedContact = this.replacedContactsSelected;
        contactInformations.sites = this.siteSelected;

        this.modifyInformationsContactBus().add(contactInformations);
    }

    private clearAddedContact() {
        this.addContactName = '';
        this.addContactPhone = '';
    }

    private clearRemovedContact() {
        this.removeContactModel = emptyContact();
    }

    private clearRemplacedContact() {
        this.substituteContactModel = emptyContact();
        this.replacedContactModel = emptyContact();
        this.addContactName = '';
        this.addContactPhone = '';
        this.addContactPhoneSecondary = '';
    }

    private contactsBySelectedSite(sites: Site[]) {
        const contacts: Contact[] = [];
        const contactNames: string[] = [];
        sites.forEach(site => {
            site.contacts.forEach(contact => {
                if (contactNames.find(c => c === contact.name) == undefined) {
                    contactNames.push(contact.name);
                    contacts.push(contact);
                }
            });
        });
        this.contactSelections = contacts;
    }

    private clearAll() {
        this.siteSelected = [];
        this.clearAddedContact();
        this.clearRemplacedContact();
        this.clearRemovedContact();
        this.clearError();
        this.addContactList.length = 0;
        this.removeContactsSelected.length = 0;
        this.replacedContactsSelected.length = 0;
    }
}
