import { TimeGridSiteGroup } from '@/domain/timeGrid/TimeGridSiteGroup';

export interface RestTimeGridSiteGroup {
    SiteReference: string;
    GroupName?: string;
    GroupReference: string;
    Channels: string[];
}

export const toTimeGridSiteGroup = (restTimeGrisSiteGroup: RestTimeGridSiteGroup): TimeGridSiteGroup => ({
    siteReference: restTimeGrisSiteGroup.SiteReference,
    groupName: restTimeGrisSiteGroup.GroupName,
    groupReference: restTimeGrisSiteGroup.GroupReference,
    channels: restTimeGrisSiteGroup.Channels,
});
