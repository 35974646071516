import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Component, Emit, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import { CallList, emptyCallList } from '@/domain/contact/CallList';
import { Contact, emptyContact } from '@/domain/contact/Contact';
import { ContactCallList } from '@/domain/contact/ContactCallList';
import { emptyPrestation, Prestation } from '@/domain/contact/Prestation';
import { contactCallListModalHeader, prestationCallListModalHeader } from '@/domain/header/modalCallListHeader';
import { IconVue } from '@/primary/components/common/icon';
import { CallListModifyPrimary } from '@/primary/components/modifyCallListModal/modifyCallListModalFirstStep/CallListModifyPrimary';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import { WarningInformationsVue } from '@/primary/components/ModifyContactModal/WarningInformations';
import { CallListStoreRepository } from '@/secondary/store/callListStore/CallListStoreRepository';

@Component({
    components: {
        IconVue,
        WarningInformationsVue,
    },
})
export default class ModifyCallListModalFirstStepComponent extends Vue {
    @Inject()
    private navigationButtonBus!: () => NavigationButtonBus;

    @Inject()
    private callListStoreRepository!: () => CallListStoreRepository;

    @Prop({ required: true })
    private callLists!: CallList[];

    @Prop({ required: true })
    private remainingContacts!: Contact[];

    @Emit()
    private closeModal() {}

    activeStep = 1;
    mode = 'modify';
    callListSelected: CallList = emptyCallList();
    remainingServices: Prestation[] = [];
    remainingServicesSelected: Prestation = emptyPrestation();
    remainingContactsSelected: Contact = emptyContact();
    headerContact = contactCallListModalHeader;
    headerPrestation = prestationCallListModalHeader;
    errorPrestations = '';
    errorContacts = '';
    errorName = '';
    globalError = '';
    callListCreated: CallList = emptyCallList();
    indexCallListSelected = 0;
    newContactMod = false;
    newName = '';
    newNameError = false;
    newPhone = '';
    newPhoneError = false;
    newPhoneSecondary = '';

    NEW_NAME = 'Nouveau contact';

    get isCreated() {
        return this.mode === 'create';
    }

    get remainingContactsWithNew() {
        const newContact: Contact = { name: this.NEW_NAME, phone: '', email: '', isReadonly: false };
        return [...this.remainingContacts.sort((a, b) => a.name.localeCompare(b.name)), newContact];
    }

    get remainingServicesNotSelected() {
        return this.remainingServices
            .filter(service => this.callListSelected.prestations.find(prestation => prestation.code == service.code) == undefined)
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    @Watch('remainingContactsSelected')
    handleNewContact() {
        if (this.remainingContactsSelected == null) {
            this.remainingContactsSelected = emptyContact();
            this.newContactMod = true;
        }
        if (this.remainingContactsSelected && this.remainingContactsSelected.name === this.NEW_NAME) {
            this.newContactMod = true;
        }
    }

    mounted() {
        if (this.callLists.length === 0) this.mode = 'create';
        const callListStore = this.callListStoreRepository().getCallListModify();
        if (callListStore) this.setupData(callListStore);
        this.callListSelected = cloneDeep(this.callLists[0]);
        this.remainingServices = cloneDeep(this.callListSelected.availablePrestations);
    }

    changeMode() {
        if (this.isCreated) {
            this.callListSelected = emptyCallList();
            this.callListSelected.contacts = this.callListCreated.contacts;
            this.callListSelected.prestations = this.callListCreated.prestations;
        } else this.callListSelected = cloneDeep(this.callLists[0]);
    }

    nextStep() {
        this.clearError();
        if (this.isCreated) {
            if (this.callListCreated.prestations.length == 0) this.errorPrestations = 'Prestations concernées: Champs obligatoire';
            if (this.callListCreated.contacts.length == 0) this.errorContacts = "Liste d'appels: Champs obligatoire";
            if (this.callListCreated.prestations.length != 0 && this.callListCreated.contacts.length != 0) {
                this.activeStep++;
                this.setCallListStorageCreated();
                this.navigationButtonBus().add(this.activeStep);
            }
        } else {
            if (this.checkIfCallListAreEqual(this.callListSelected, this.callLists[this.indexCallListSelected]))
                this.globalError = "Veuillez faire au moins une modification avant de passer à l'étape suivante";
            if (this.callListSelected.prestations.length == 0) this.errorPrestations = 'Prestations concernées: Champs obligatoire';
            if (this.callListSelected.contacts.length == 0) this.errorContacts = "Liste d'appels: Champs obligatoire";
            if (
                !this.checkIfCallListAreEqual(this.callListSelected, this.callLists[this.indexCallListSelected]) &&
                this.callListSelected.prestations.length != 0 &&
                this.callListSelected.contacts.length != 0
            ) {
                this.activeStep++;
                this.setCallListStorageSelected();
                this.navigationButtonBus().add(this.activeStep);
            }
        }
    }

    setIndex(callList: CallList) {
        this.indexCallListSelected = this.callLists.indexOf(callList);
        this.callListSelected = cloneDeep(this.callLists[this.indexCallListSelected]);
    }

    prevStep() {
        this.activeStep--;
        this.navigationButtonBus().add(this.activeStep);
    }

    addService() {
        if (this.isCreated) this.callListCreated.prestations.push(this.remainingServicesSelected);
        else this.callListSelected.prestations.push(this.remainingServicesSelected);
        this.remainingServicesSelected = emptyPrestation();
    }

    removeService(index: number) {
        if (this.isCreated) this.callListCreated.prestations.splice(index, 1);
        else this.callListSelected.prestations.splice(index, 1);
    }

    removeContact(index: number) {
        if (this.isCreated) this.callListCreated.contacts.splice(index, 1);
        else this.callListSelected.contacts.splice(index, 1);
    }

    addContact() {
        if (this.remainingContactsSelected.name !== this.NEW_NAME) {
            if (this.remainingContactsSelected.name.length == 0) return;
            const contact: ContactCallList = {
                ...this.remainingContactsSelected!,
                order: this.callListSelected.contacts.length,
                condition: '',
                created: false,
            };
            if (this.isCreated) this.callListCreated.contacts.push(contact);
            else this.callListSelected.contacts.push(contact);
        } else {
            this.newNameError = this.newName.length == 0;
            this.newPhoneError = this.newPhone.length == 0;
            if (this.newNameError || this.newPhoneError || this.phoneCheck(this.newPhone) != true) return;
            let contact: ContactCallList = {
                name: this.newName,
                email: '',
                phone: this.newPhone,
                order: this.callListSelected.contacts.length,
                condition: '',
                created: true,
                isReadonly: false,
            };
            if (this.newPhoneSecondary.length > 0) {
                if (this.phoneCheck(this.newPhoneSecondary) != true) return;
                contact = {
                    ...contact,
                    phone2: this.newPhoneSecondary,
                };
            }
            if (this.isCreated) this.callListCreated.contacts.push(contact);
            else this.callListSelected.contacts.push(contact);
            this.newName = '';
            this.newPhone = '';
            this.newPhoneSecondary = '';
        }
        this.remainingContactsSelected = emptyContact();
    }

    up(index: number) {
        this.moveArrayItemToNewIndex(index, index - 1, true);
    }

    down(index: number) {
        this.moveArrayItemToNewIndex(index, index + 1, false);
    }

    phoneCheck(value: string) {
        return /^((\+)33|0)[1-9](\d{2}){4}$/g.test(value) || 'Format invalide';
    }

    private setupData(callList: CallListModifyPrimary) {
        if (callList.created) {
            this.mode = 'create';
            this.callListCreated.contacts = callList.CallList.Contacts;
            this.callListCreated.prestations = callList.CallList.Prestations;
        } else {
            this.callListSelected.contacts = callList.CallList.Contacts;
            this.callListSelected.prestations = callList.CallList.Prestations;
        }
    }

    private moveArrayItemToNewIndex(oldIndex: number, newIndex: number, up: boolean) {
        if (up) this.callListSelected.contacts[oldIndex - 1].order++;
        else this.callListSelected.contacts[oldIndex + 1].order--;
        this.callListSelected.contacts[oldIndex].order = newIndex;
        this.callListSelected.contacts.splice(newIndex, 0, this.callListSelected.contacts.splice(oldIndex, 1)[0]);
    }

    private checkIfCallListAreEqual(firstCallList: CallList, secondCallList: CallList): boolean {
        return isEqual(firstCallList, secondCallList);
    }

    private clearError() {
        this.errorContacts = '';
        this.errorName = '';
        this.errorPrestations = '';
        this.globalError = '';
    }

    private setCallListStorageCreated() {
        const callList: CallListModifyPrimary = {
            CallList: {
                Code: '',
                Prestations: this.callListCreated.prestations,
                Contacts: this.callListCreated.contacts,
            },
            SiteReference: '',
            ValidityStart: '',
            ValidityEnd: '',
            UserFirstName: '',
            UserLastName: '',
            UserPhoneNumber: '',
            created: true,
            index: 0,
        };
        this.callListStoreRepository().modifyCallListModify(callList);
    }

    private setCallListStorageSelected() {
        const callList: CallListModifyPrimary = {
            CallList: {
                Code: this.callListSelected.code,
                Prestations: this.callListSelected.prestations,
                Contacts: this.callListSelected.contacts,
            },
            SiteReference: this.callListSelected.reference,
            ValidityStart: '',
            ValidityEnd: '',
            UserFirstName: '',
            UserLastName: '',
            UserPhoneNumber: '',
            created: false,
            index: this.callLists.findIndex(cl => cl.code === this.callListSelected.code) + 1,
        };
        this.callListStoreRepository().modifyCallListModify(callList);
    }
}
