import { ICompletedInspection } from '@/domain/inspection/ICompletedInspection';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export type RestInspection = {
    InspectionNumber: string;
    ContractNumber: string;
    SiteReference: string;
    SiteName: string;
    Activity: string;
    ClientCode: string;
    City: string;
    Date: string;
    Billable: boolean;
};

export const toInspection = (restInspection: RestInspection): ICompletedInspection => ({
    id: restInspection.InspectionNumber,
    contractNumber: restInspection.ContractNumber,
    siteReference: restInspection.SiteReference,
    clientNumber: restInspection.ClientCode,
    siteName: stringCleaner(restInspection.SiteName),
    activity: restInspection.Activity,
    city: restInspection.City,
    date: restInspection.Date,
    billable: restInspection.Billable,
});
