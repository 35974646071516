import { MailAlertFrequency } from '@/domain/user/MailAlertFrequency';

export enum RestMailAlertFrequency {
    A = 'A',
    Q = 'Q',
    R = 'R',
}

export const toRestMailAlertFrequency = (mailAlertFrequency: MailAlertFrequency): RestMailAlertFrequency => {
    if (mailAlertFrequency == MailAlertFrequency.A) return RestMailAlertFrequency.A;
    if (mailAlertFrequency == MailAlertFrequency.Q) return RestMailAlertFrequency.Q;
    return RestMailAlertFrequency.R;
};

export const fromRestMailAlertFrequency = (restMailAlertFrequency: RestMailAlertFrequency): MailAlertFrequency => {
    if (restMailAlertFrequency == RestMailAlertFrequency.A) return MailAlertFrequency.A;
    if (restMailAlertFrequency == RestMailAlertFrequency.Q) return MailAlertFrequency.Q;
    return MailAlertFrequency.R;
};
