import { MailAlertSubscription } from '@/domain/user/MailAlertSubscription';
import { emptyUserMailAlert, UserMailAlert } from '@/domain/user/UserMailAlert';

export interface UserAlerts {
    missingTests: UserMailAlert;
    missingCommissioning: UserMailAlert;
    abnormalCommissioning: UserMailAlert;
    alarm: UserMailAlert;
}

export const emptyUserAlerts = (): UserAlerts => ({
    missingTests: emptyUserMailAlert(MailAlertSubscription.TST),
    missingCommissioning: emptyUserMailAlert(MailAlertSubscription.MES),
    abnormalCommissioning: emptyUserMailAlert(MailAlertSubscription.MHS),
    alarm: emptyUserMailAlert(MailAlertSubscription.ALA),
});
