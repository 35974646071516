import { AxiosInstance, AxiosResponse } from 'axios';

import { IConfigurationRepository, Links } from '@/domain/configuration/IConfigurationRepository';
import { ErrorMessage } from '@/secondary/error/ErrorMessage';

export class RestConfigurationRepository implements IConfigurationRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getConfigLinks(): Promise<Links> {
        return this.axiosInstance
            .get('/Configurations/Links')
            .then((response: AxiosResponse<RestLinks>) => {
                return toLinks(response.data);
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }
}

export interface RestLinks {
    ItesisClickArea1: string;
    ItesisClickArea2: string;
    SurwayClickArea1: string;
    SurwayClickArea2: string;
    ItesisSupportClickToCall1: string;
    SurwaySupportClickToCall1: string;
    ItesisSupportEmail: string;
    SurwaySupportEmail: string;
}

export const toLinks = (restLinks: RestLinks): Links => ({
    itesisClickArea1: restLinks.ItesisClickArea1,
    itesisClickArea2: restLinks.ItesisClickArea2,
    surwayClickArea1: restLinks.SurwayClickArea1,
    surwayClickArea2: restLinks.SurwayClickArea2,
    itesisSupportClickToCall: restLinks.ItesisSupportClickToCall1,
    surwaySupportClickToCall: restLinks.SurwaySupportClickToCall1,
    itesisSupportEmail: restLinks.ItesisSupportEmail,
    surwaySupportEmail: restLinks.SurwaySupportEmail,
});
