import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { EventHistoryMediaDetail } from '@/domain/event/Event';
import { EventHistoryWrapper } from '@/domain/event/EventHistoryWrapper';
import { EventRepository } from '@/domain/event/EventRepository';
import { ExportEventParam } from '@/domain/event/ExportEventParam';
import { downloadFile } from '@/secondary/DownloadFile';
import { RestEvent, toEvent, toEventDetails } from '@/secondary/restEvent/RestEvent';
import { RestEventWrapper } from '@/secondary/restEvent/RestEventWrapper';

export class RestEventRepository implements EventRepository {
    controller = new AbortController();

    constructor(private axiosInstance: AxiosInstance) {}

    get(siteSelected: string[], fromDate?: string, toDate?: string): Promise<EventHistoryWrapper> {
        this.controller.abort();
        this.controller = new AbortController();
        let data = {};

        if (fromDate) data = { ...data, FromDate: fromDate };
        if (toDate) data = { ...data, ToDate: toDate };
        if (siteSelected.length > 0) data = { ...data, SiteReferences: siteSelected };

        return this.axiosInstance
            .post('/Events', data, {
                signal: this.controller.signal,
            })
            .then((response: AxiosResponse<RestEventWrapper>) => {
                return { events: response.data.Events.map(toEvent), warnings: Object.values(response.data.Warnings) };
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    hasRecentAlarm(): Promise<boolean> {
        let query = '/Events?';
        query = this.generateQuery24h(query);

        return this.axiosInstance.get(query).then((response: AxiosResponse<{ Events: RestEvent[] }>) => {
            return response.data.Events.filter(event => event.Type == 1).length > 0;
        });
    }

    getExport(type: string, exportParams: ExportEventParam): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .post(`/Events/Export/${type}`, exportParams, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getEventDetail(id: string): Promise<EventHistoryMediaDetail[]> {
        return this.axiosInstance
            .get(`/Event/${id}`)
            .then((response: AxiosResponse<RestEvent>) => {
                return toEventDetails(response.data);
            })
            .catch(error => {
                throw Error(error);
            });
    }

    getEventImage(id: string): Promise<any> {
        return this.getAttachment(id, 'Image');
    }

    getEventVideo(id: string): Promise<any> {
        return this.getAttachment(id, 'Video');
    }

    private generateQuery24h(query: string) {
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 1);
        query = query.concat(`fromDate=${fromDate.toISOString()}`, '&');
        return query.concat(`toDate=${toDate.toISOString()}`, '&');
    }

    private getAttachment(id: string, type: string) {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .get(`/Event/${id}`)
            .then((response: AxiosResponse<RestEvent>) => {
                return this.axiosInstance
                    .get(`/Event/${type}/${response.data.Attachment!.Id}`, params)
                    .then((r: AxiosResponse<any>) => {
                        const blob =
                            type == 'Video' ? new Blob([r.data], { type: 'video/mp4' }) : new Blob([r.data], { type: 'image/jpeg' });
                        return URL.createObjectURL(blob);
                    })
                    .catch(error => {
                        throw Error(error);
                    });
            })
            .catch(error => {
                throw Error(error);
            });
    }
}
