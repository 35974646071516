import { AxiosInstance, AxiosResponse } from 'axios';

import { PatrolCreator } from '@/domain/video/PatrolCreator';
import { VideoPatrolRepository } from '@/domain/video/VideoPatrolRepository';
import { WeeklyPatrolCreator } from '@/domain/video/WeeklyPatrolCreator';
import { toRestPatrolCreator } from '@/secondary/restVideo/RestPatrolCreator';
import { RestVideoPatrol, toVideoPatrol } from '@/secondary/restVideo/RestVideoPatrol';
import { toRestWeeklyPatrolCreator } from '@/secondary/restVideo/RestWeeklyPatrolCreator';

export class RestVideoPatrolRepository implements VideoPatrolRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getPatrols(siteReference: string, from: Date, to: Date): Promise<any[]> {
        return this.axiosInstance
            .get('/Site/' + siteReference + '/Patrols/Scheduled?From=' + this.formatDate(from) + '&To=' + this.formatDate(to))
            .then((response: AxiosResponse<RestVideoPatrol[]>) => {
                return response.data.map(toVideoPatrol);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    createPatrol(siteReference: string, patrol: PatrolCreator): Promise<void> {
        return this.axiosInstance.post('/Site/' + siteReference + '/Patrols/Scheduled', toRestPatrolCreator(patrol));
    }

    createWeeklyPatrol(siteReference: string, patrol: WeeklyPatrolCreator): Promise<void> {
        return this.axiosInstance.post('/Site/' + siteReference + '/Patrols/Scheduled', toRestWeeklyPatrolCreator(patrol));
    }

    deletePatrol(siteReference: string, id: string): Promise<void> {
        return this.axiosInstance.delete('/Site/' + siteReference + '/Patrol/' + id);
    }

    private formatDate(date: Date) {
        return this.addZero(date.getMonth() + 1) + '%2F' + this.addZero(date.getDate()) + '%2F' + date.getFullYear();
    }

    private addZero(n: number): string {
        return n < 9 ? '0' + n : n.toString();
    }
}
