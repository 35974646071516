export abstract class Bus<T> {
    private busId = 0;
    private subscribers = new Map<number, (generic: T | undefined) => void>();
    protected constructor(
        private bus: Vue,
        private readonly busName: string
    ) {}

    public add(genericValue: T | undefined) {
        this.bus.$emit(this.busName, genericValue);
    }

    public subscribe(callback: (generic: T | undefined) => void): number {
        this.busId++;
        this.subscribers.set(this.busId, callback);
        this.bus.$on(this.busName, callback);
        return this.busId;
    }

    public unsubscribe(subscribedId: number): boolean {
        if (!this.subscribers.has(subscribedId)) return false;
        const callback = this.subscribers.get(subscribedId);
        this.bus.$off(this.busName, callback);
        this.subscribers.delete(subscribedId);
        return true;
    }
}
