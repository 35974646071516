import { AxiosError } from 'axios';
import { Vue } from 'vue-property-decorator';

import { IRestError } from '../IRestError';

import { IToast } from '@/domain/toast/IToast';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import ToastBus from '@/primary/utils/ToastBus';

export default class GuestInterceptor extends Vue {
    constructor(private toastBus: ToastBus) {
        super();
    }
    onReject(error: AxiosError<IRestError>) {
        console.log(error.response);
        if (error.response!.status == 400) {
            throw Error('Login ou mot de passe incorrect.');
        } else if (error.response!.status >= 400 && error.response!.status < 500) {
            const errorToast: IToast = {
                status: ToastStatus.ERROR,
                text: error?.response?.data.Value?.Error.Message,
            };
            this.toastBus.add(errorToast);
            throw Error(error?.response?.data.Value?.Error.Message);
        } else {
            if (error.message) {
                console.log(error.message);
            } else if (error.response?.data.Value) {
                console.log(error.response?.data.Value?.Error.Message);
            }
            this.toastBus.add({ status: ToastStatus.ERROR, text: error.response?.data.Value?.Error.Message });
            throw Error('Une erreur inconnue est survenue veuillez réessayer ultérieurement');
        }
    }
}
