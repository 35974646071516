import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { RestHumanSupervision, toHumanSupervision } from './RestHumanSupervision';

import { HumanSupervision } from '@/domain/humanSupervision/HumanSupervision';
import { HumanSupervisionExportEvent } from '@/domain/humanSupervision/HumanSupervisionExportEvent';
import { HumanSupervisionRepository } from '@/domain/humanSupervision/HumanSupervisionRepository';
import { downloadFile } from '@/secondary/DownloadFile';

export class RestHumanSupervisionRepository implements HumanSupervisionRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getInterventions(): Promise<HumanSupervision[]> {
        return this.axiosInstance.get('/Interventions').then((response: AxiosResponse<RestHumanSupervision[]>) => {
            return response.data.map(toHumanSupervision);
        });
    }

    export(type: string, exportEventParams: HumanSupervisionExportEvent): Promise<void> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance.post(`/Interventions/Export/${type}`, exportEventParams, params).then(downloadFile);
    }
}
