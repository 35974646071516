import { DataTableHeader } from 'vuetify';

export const inspectionSummaryHeader = (): DataTableHeader[] => [
    { text: 'Type', sortable: false, value: 'type' },
    { text: 'Jan', sortable: false, align: 'start', value: 'jan' },
    { text: 'Fév', sortable: false, align: 'start', value: 'feb' },
    { text: 'Mar', sortable: false, align: 'start', value: 'mar' },
    { text: 'Avr', sortable: false, align: 'start', value: 'apr' },
    { text: 'Mai', sortable: false, align: 'start', value: 'may' },
    { text: 'Jui', sortable: false, align: 'start', value: 'jun' },
    { text: 'Jui', sortable: false, align: 'start', value: 'jul' },
    { text: 'Aoû', sortable: false, align: 'start', value: 'aug' },
    { text: 'Sep', sortable: false, align: 'start', value: 'sep' },
    { text: 'Oct', sortable: false, align: 'start', value: 'oct' },
    { text: 'Nov', sortable: false, align: 'start', value: 'nov' },
    { text: 'Déc', sortable: false, align: 'start', value: 'dec' },
    { text: 'Total', sortable: false, align: 'start', value: 'total' },
];

export const inspectionDetailHeader = (): DataTableHeader[] => [
    { text: 'N° Contrat', align: 'start', value: 'id', sortable: true, filterable: true },
    { text: 'N° Client', align: 'start', value: 'clientNumber', sortable: true, filterable: true },
    { text: "Date d'effet", align: 'start', value: 'applicationDate', sortable: true, filterable: false },
    { text: 'Activité', value: 'activity', sortable: false, filterable: true },
    { text: 'Site(s)', value: 'siteName', sortable: false, filterable: true },
    { text: 'Ville(s)', value: 'city', sortable: false, filterable: true },
    { text: 'Jan', sortable: false, align: 'start', value: 'jan', class: 'col-month' },
    { text: 'Fév', sortable: false, align: 'start', value: 'feb', class: 'col-month' },
    { text: 'Mar', sortable: false, align: 'start', value: 'mar', class: 'col-month' },
    { text: 'Avr', sortable: false, align: 'start', value: 'apr', class: 'col-month' },
    { text: 'Mai', sortable: false, align: 'start', value: 'may', class: 'col-month' },
    { text: 'Jui', sortable: false, align: 'start', value: 'jun', class: 'col-month' },
    { text: 'Jui', sortable: false, align: 'start', value: 'jul', class: 'col-month' },
    { text: 'Aoû', sortable: false, align: 'start', value: 'aug', class: 'col-month' },
    { text: 'Sep', sortable: false, align: 'start', value: 'sep', class: 'col-month' },
    { text: 'Oct', sortable: false, align: 'start', value: 'oct', class: 'col-month' },
    { text: 'Nov', sortable: false, align: 'start', value: 'nov', class: 'col-month' },
    { text: 'Déc', sortable: false, align: 'start', value: 'dec', class: 'col-month' },
];

export const inspectionDetailHeaderMobile = (): DataTableHeader[] => [
    { text: 'N° Contrat', align: 'start', value: 'id', sortable: true, filterable: false },
    { text: 'N° Client', align: 'start', value: 'clientNumber', sortable: true, filterable: false },
    { text: "Date d'effet", align: 'start', value: 'applicationDate', sortable: true, filterable: false },
    { text: 'Activité', value: 'activity', sortable: false, filterable: true },
    { text: 'Site(s)', value: 'siteName', sortable: false, filterable: true },
    { text: 'Ville(s)', value: 'city', sortable: false, filterable: true },
    { text: 'Jan', sortable: false, align: 'start', value: 'jan', class: 'col-month' },
    { text: 'Fév', sortable: false, align: 'start', value: 'feb', class: 'col-month' },
    { text: 'Mar', sortable: false, align: 'start', value: 'mar', class: 'col-month' },
    { text: 'Avr', sortable: false, align: 'start', value: 'apr', class: 'col-month' },
    { text: 'Mai', sortable: false, align: 'start', value: 'may', class: 'col-month' },
    { text: 'Jui', sortable: false, align: 'start', value: 'jun', class: 'col-month' },
    { text: 'Jui', sortable: false, align: 'start', value: 'jul', class: 'col-month' },
    { text: 'Aoû', sortable: false, align: 'start', value: 'aug', class: 'col-month' },
    { text: 'Sep', sortable: false, align: 'start', value: 'sep', class: 'col-month' },
    { text: 'Oct', sortable: false, align: 'start', value: 'oct', class: 'col-month' },
    { text: 'Nov', sortable: false, align: 'start', value: 'nov', class: 'col-month' },
    { text: 'Déc', sortable: false, align: 'start', value: 'dec', class: 'col-month' },
];
