import { IRule } from './IRule';

export class MinCharMatchRule implements IRule {
    passed: boolean;
    description: string;
    descriptionMobile: string;
    min: number;
    allowedCharacters: string;
    showDescription: boolean;

    constructor(min: number, allowedCharacters: string, description: string, showDescription: boolean, descriptionMobile: string) {
        this.description = description;
        this.min = min;
        this.allowedCharacters = allowedCharacters;
        this.passed = false;
        this.showDescription = showDescription;
        this.descriptionMobile = descriptionMobile;
    }

    check(value: string): boolean | string {
        const matches = new RegExp('[' + this.allowedCharacters + ']', 'mg').exec(value);
        this.passed = matches != null && matches.length >= this.min;

        return this.passed ? this.passed : this.description;
    }
}
