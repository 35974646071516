import { PatrolCreator } from '@/domain/video/PatrolCreator';

export interface RestPatrolCreator {
    SiteReference: string;
    Code: string;
    Date: Date;
    Type: number;
    FixedTime?: string;
    Weekly: false;
}

export const toRestPatrolCreator = (patrolCreator: PatrolCreator): RestPatrolCreator => ({
    SiteReference: patrolCreator.siteReference,
    Code: patrolCreator.roundCode,
    Date: patrolCreator.date,
    Type: patrolCreator.type,
    FixedTime: patrolCreator.fixedTime,
    Weekly: false,
});
