import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { AccountRepository } from '@/domain/account/AccountRepository';
import { ActivationUser } from '@/domain/account/ActivationUser';
import { NewPassword } from '@/domain/account/NewPassword';
import { RestAccountToken } from '@/secondary/restAccount/RestAccountToken';
import { RestAccountTokenDoubleAuthentification } from '@/secondary/restAccount/RestAccountTokenDoubleAuthentification';
import { RestActivationUser, toActivationUser } from '@/secondary/restAccount/RestActivationUser';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';
import { StoreRepository } from '@/secondary/store/localeStore/StoreRepository';

export class RestAccountRepository implements AccountRepository {
    constructor(
        private axiosInstance: AxiosInstance,
        private storeRepository: StoreRepository
    ) {}

    resetPassword(password: string, token: string, confirmPassword: string): Promise<void> {
        return this.axiosInstance.post('/Authentication/PasswordRecovery/' + RestThemeRepository.getNameOfSite() + '/' + token, {
            Application: RestThemeRepository.getNameOfSite(),
            Token: token,
            NewPassword: password,
            ConfirmPassword: confirmPassword,
        });
    }

    validateForgottenToken(token: string): Promise<void> {
        return this.axiosInstance.get('/Authentication/PasswordRecovery/' + RestThemeRepository.getNameOfSite() + '/' + token);
    }

    login(email: string, password: string): Promise<number> {
        return this.axiosInstance
            .post('/Authentication/login', {
                Application: RestThemeRepository.getNameOfSite(),
                Login: email,
                Password: password,
            })
            .then((response: AxiosResponse<RestAccountToken>) => {
                this.storeRepository.modifyUser({
                    userToken: response.data.Token,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    rights: [],
                });
                return response.data.Type;
            })
            .catch((error: AxiosError<Error>) => {
                console.log(error);
                throw Error(error?.response?.data?.message);
            });
    }

    loginDoubleAuthentification(code: string): Promise<void> {
        return this.axiosInstance
            .post('/Authentication/otp', {
                Application: RestThemeRepository.getNameOfSite(),
                Token: this.storeRepository.getUserToken(),
                OneTimePassword: code,
            })
            .then((response: AxiosResponse<RestAccountTokenDoubleAuthentification>) => {
                this.storeRepository.modifyUser({
                    userToken: response.data.Token,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    rights: [],
                });
            })
            .catch((error: AxiosError<Error>) => {
                throw Error(error.response!.data.message);
            });
    }

    loginPostTelephoneNumber(number: string): Promise<void> {
        return this.axiosInstance
            .post('/Authentication/demo', {
                Application: RestThemeRepository.getNameOfSite(),
                Token: this.storeRepository.getUserToken(),
                PhoneNumber: number,
            })
            .then((response: AxiosResponse<RestAccountTokenDoubleAuthentification>) => {
                this.storeRepository.modifyUser({
                    userToken: response.data.Token,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    rights: [],
                });
            })
            .catch((error: AxiosError<Error>) => {
                throw Error(error.response!.data.message);
            });
    }

    forgottenPassword(email: string): Promise<void> {
        return this.axiosInstance.post('/Authentication/PasswordRecovery', {
            Application: RestThemeRepository.getNameOfSite(),
            Login: email,
        });
    }

    getOnboarding(emailId: string): Promise<ActivationUser> {
        return this.axiosInstance
            .get(`/Onboarding/${emailId}`)
            .then((response: AxiosResponse<RestActivationUser>) => toActivationUser(response.data));
    }

    sendSms(emailId: string): Promise<NewPassword> {
        // return Promise.resolve({ phone: '061234567', token: 'token' });
        return this.axiosInstance
            .post(`/Onboarding/${emailId}/StartNewPassword`, {
                ApplicationName: RestThemeRepository.getNameOfSite(),
            })
            .then((result: AxiosResponse<{ TargetPhoneNumber: string; Token: string }>) => ({
                phone: result.data.TargetPhoneNumber,
                token: result.data.Token,
            }));
    }

    confirmPhone(emailId: string, code: string, password: string, token: string, confirmationPassword: string): Promise<void> {
        // return Promise.resolve();
        return this.axiosInstance
            .post(`/Onboarding/${emailId}/ConfirmPassword`, {
                ApplicationName: RestThemeRepository.getNameOfSite(),
                Token: token,
                TwoFactorCode: code,
                Password: password,
                ConfirmPassword: confirmationPassword,
            })
            .then();
    }
}
