import { DataTableHeader } from 'vuetify';

export const completedInspectionHeader = (): DataTableHeader[] => [
    { text: 'N° Contrat', align: 'start', value: 'contractNumber', sortable: true, filterable: true },
    { text: 'N° Client', align: 'start', value: 'clientNumber', sortable: true, filterable: true },
    { text: 'N° Bon', align: 'start', value: 'id', sortable: false, filterable: true },
    { text: 'Activité', value: 'activity', sortable: true, filterable: true },
    { text: 'Site(s)', value: 'siteName', sortable: true, filterable: true },
    { text: 'Ville', value: 'city', sortable: true, filterable: true },
    { text: 'Date intervention', value: 'date', sortable: true, filterable: false },
    { text: 'Facturable', value: 'billable', sortable: true, filterable: false },
    { text: 'Détail', value: 'detail', sortable: false, filterable: false },
];

export const completedInspectionHeaderMobile = (): DataTableHeader[] => [
    { text: 'N° Contrat', align: 'start', value: 'contractNumber', sortable: true, filterable: true },
    { text: 'N° Client', align: 'start', value: 'clientNumber', sortable: true, filterable: true },
    { text: 'N° Bon', align: 'start', value: 'id', sortable: false, filterable: true },
    { text: 'Activité', value: 'activity', sortable: true, filterable: true },
    { text: 'Site(s)', value: 'siteName', sortable: true, filterable: true },
    { text: 'Ville', value: 'city', sortable: false, filterable: true },
    { text: 'Date intervention', value: 'date', sortable: true, filterable: false },
    { text: 'Facturable', value: 'billable', sortable: true, filterable: false },
    { text: 'Détail', value: 'detail', sortable: false, filterable: false },
];
