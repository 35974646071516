import { AxiosInstance, AxiosResponse } from 'axios';

import { VideoCredit } from '@/domain/video/VideoCredit';
import { VideoCreditRepository } from '@/domain/video/VideoCreditRepository';
import { RestVideoCredit, toVideoCredits } from '@/secondary/restVideo/RestVideoCredit';

export class RestVideoCreditRepository implements VideoCreditRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getCredits(siteReference: string): Promise<VideoCredit[]> {
        return this.axiosInstance
            .get('/Site/' + siteReference + '/Credits')
            .then((response: AxiosResponse<RestVideoCredit[]>) => {
                return response.data.map(toVideoCredits);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }
}
