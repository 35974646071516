import { Component, Inject, Vue } from 'vue-property-decorator';

import { File } from '@/domain/file/File';
import { FileRepository } from '@/domain/file/FileRepository';
import { FileType } from '@/domain/file/FileType';
import { Folder } from '@/domain/file/Folder';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';

interface TreeNode {
    id: string;
    name: string;
    children?: TreeNode[];
}

@Component({
    components: {
        IconVue,
    },
})
export default class DocumentPageComponent extends Vue {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private fileRepository!: () => FileRepository;

    selection: TreeNode[] = [];
    files: File[] = [];
    search = '';
    isLoading = false;

    mounted() {
        this.pageNameBus().add('Mes documents');
        this.isLoading = true;
        this.fileRepository()
            .getList()
            .then(files => (this.files = files))
            .finally(() => (this.isLoading = false));
    }

    download() {
        this.selection.forEach(file => {
            this.fileRepository().download(file.id);
        });
    }

    get filesToTreeNode() {
        return this.files.map(this.toTreeNode);
    }

    private toTreeNode(file: File): TreeNode {
        return {
            id: file.id,
            name: file.label,
            children: file.type == FileType.FOLDER ? (file as Folder).child.map(this.toTreeNode) : undefined,
        };
    }
}
