import { ContactCallList } from '@/domain/contact/ContactCallList';
import { Prestation } from '@/domain/contact/Prestation';

export interface CallList {
    reference: string;
    code: string;
    generalInstruction?: string;
    prestations: Prestation[];
    availablePrestations: Prestation[];
    contacts: ContactCallList[];
}

export const emptyCallList = (): CallList => ({
    reference: '',
    code: '',
    prestations: [],
    availablePrestations: [],
    contacts: [],
});
