import moment from 'moment';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { HumanSupervision } from '@/domain/humanSupervision/HumanSupervision';
import { HumanSupervisionExportEvent } from '@/domain/humanSupervision/HumanSupervisionExportEvent';
import { HumanSupervisionRepository } from '@/domain/humanSupervision/HumanSupervisionRepository';
import { IconVue } from '@/primary/components/common/icon';

@Component({
    components: {
        IconVue,
    },
})
export default class HumanSupervisionComponent extends Vue {
    @Inject()
    private humanSupervisionRepository!: () => HumanSupervisionRepository;

    search = '';
    interventions: HumanSupervision[] = [];
    headers: DataTableHeader[] = [
        {
            text: 'Site(s)',
            value: 'sites',
        },
        {
            text: 'Ville(s)',
            value: 'cities',
        },
        {
            text: 'N° intervention',
            value: 'interventionNumber',
            sortable: false,
        },
        {
            text: 'Date intervention',
            value: 'interventionDate',
            filterable: false,
        },
        {
            text: "Heure d'appel",
            value: 'callHour',
            filterable: false,
        },
        {
            text: "Heure d'arrivée",
            value: 'arrivingHour',
            filterable: false,
        },
        {
            text: 'Heure de départ',
            value: 'goingHour',
            filterable: false,
        },
        {
            text: 'Etat facturation',
            value: 'facturationState',
            filterable: false,
        },
        {
            text: "Rapport d'interventions",
            value: 'interventionReport',
            sortable: false,
        },
    ];

    sites: string[] = [];
    siteSelected: string[] = [];
    sitesReferences: string[] = [];

    cities: string[] = [];
    citySelected: string[] = [];

    billingStates: string[] = [];
    billingStateSelected: string[] = [];

    startDateOpen = false;
    endDateOpen = false;
    startDateOpenMobile = false;
    endDateOpenMobile = false;
    startDate = new Date(Date.now() - 24 * 60 * 60 * 1000 * 30).toISOString();
    endDate = new Date().toISOString();

    get siteOrdered() {
        return this.sites
            .sort((a, b) => a.localeCompare(b))
            .sort((a, b) => {
                const hasA = this.siteSelected.find(site => site === a) !== undefined;
                const hasB = this.siteSelected.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    created() {
        this.humanSupervisionRepository()
            .getInterventions()
            .then(interventions => {
                this.interventions = interventions;
                this.sites = this.interventions.map(intervention => intervention.sites);
                this.sitesReferences = this.interventions.map(intervention => intervention.sitesReferences);
                this.cities = this.interventions.map(intervention => intervention.cities);
                this.billingStates = this.interventions.map(intervention => intervention.facturationState);
            });
    }

    exportData(type: string) {
        this.humanSupervisionRepository().export(type, this.toExportEventParams());
    }

    get interventionsFiltered(): HumanSupervision[] {
        return this.interventions
            .filter(intervention => this.siteSelected.includes(intervention.sites) || this.siteSelected.length == 0)
            .filter(intervention => this.citySelected.includes(intervention.cities) || this.citySelected.length == 0)
            .filter(
                intervention =>
                    (this.startDate < this.formatDate(intervention.interventionDate) || this.startDate == '') &&
                    (this.endDate > this.formatDate(intervention.interventionDate) || this.endDate == '')
            )
            .filter(
                intervention => this.billingStateSelected.includes(intervention.facturationState) || this.billingStateSelected.length == 0
            );
    }

    get formatCustomStartDate() {
        return this.startDate.length > 0 ? moment(new Date(this.startDate)).locale('fr').format('L') : '';
    }

    get formatCustomEndDate() {
        return this.endDate.length > 0 ? moment(new Date(this.endDate)).locale('fr').format('L') : '';
    }

    private formatDate(date: string) {
        return moment(date, 'DD/MM/YYYY').toISOString();
    }

    private toExportEventParams(): HumanSupervisionExportEvent {
        return {
            cities: this.citySelected.length > 0 ? this.citySelected : undefined,
            sites: this.siteSelected.length > 0 ? this.getSiteSelectedReferences() : undefined,
            from: this.startDate.length > 0 ? this.startDate : undefined,
            to: this.endDate.length > 0 ? this.endDate : undefined,
            billingStates: this.billingStateSelected.length > 0 ? this.billingStateSelected : undefined,
        };
    }

    private getSiteSelectedReferences() {
        return this.siteSelected.map(site => this.sitesReferences[this.sites.findIndex(s => s == site)]);
    }
}
