import { AxiosError } from 'axios';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { completedInspectionHeader, completedInspectionHeaderMobile } from '@/domain/header/completedInspectionHeader';
import { inspectionDetailHeader, inspectionDetailHeaderMobile, inspectionSummaryHeader } from '@/domain/header/inspectionSummaryHeader';
import { ExportRealisedParam } from '@/domain/inspection/ExportRealisedParam';
import { ICompletedInspection } from '@/domain/inspection/ICompletedInspection';
import { IInspectionRepository } from '@/domain/inspection/IInspectionRepository';
import { IPlannedDetailInspection } from '@/domain/inspection/IPlannedDetailInspection';
import { IPlannedInspection } from '@/domain/inspection/IPlannedInspection';
import { Right } from '@/domain/menu/Right';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { Tab } from '@/primary/components/navbar/Tab';
import TabsNavigationBus from '@/primary/components/navbar/TabsNavigationBus';
import TabsNumberNavigationBus from '@/primary/components/navbar/TabsNumberNavigationBus';
import { datePickerFormat } from '@/primary/utils/Filters/DateFormat';
import { Responsive } from '@/primary/utils/Responsive';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
    },
})
export default class InspectionPage extends mixins(RightMixin) {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private tabsNavigationBus!: () => TabsNavigationBus;

    @Inject()
    private tabsNumberNavigationBus!: () => TabsNumberNavigationBus;

    @Inject()
    private responsive!: () => Responsive;

    @Inject()
    private inspectionRepository!: () => IInspectionRepository;

    tab = 0;
    tabs: Tab[] = [
        { name: 'Prévisionnel', right: Right.RS_VEP_LST },
        { name: 'Réalisé', right: Right.RS_VEH_LST },
    ];
    tabsNumberNavigationBusId = 0;
    inspections: ICompletedInspection[] = [];
    detailInspections: IPlannedDetailInspection[] = [];

    years = [new Date().getFullYear(), new Date().getFullYear() - 1];
    fourWeeks = 2.592e9;
    applicationDateRange = [new Date(Date.now() - this.fourWeeks).toISOString(), new Date().toISOString()];

    detailSearch = '';
    detailSearchRealised = '';

    sites: string[] = [];
    selectedSites: string[] = [];
    cities: string[] = [];
    selectedCities: string[] = [];
    isDateRangeOpen = false;
    isDateRangeOpenMobile = false;
    isEffectiveStartDateOpen = false;
    isEffectiveEndDateOpen = false;
    billableSelected: boolean[] = [];
    billable: Array<any> = [
        { text: 'oui', value: true },
        { text: 'non', value: false },
    ];
    activities: string[] = [];
    activitiesSelected: string[] = [];

    detailSites: string[] = [];
    selectedDetailSites: string[] = [];
    detailCities: string[] = [];
    selectedDetailCities: string[] = [];
    detailActivities: string[] = [];
    selectedDetailActivities: string[] = [];
    effectiveStartDate = '';
    effectiveEndDate = '';

    selectedYear = new Date().getFullYear();
    inspectionSummary: IPlannedInspection[] = [];

    headerInspection: DataTableHeader[] = completedInspectionHeader();
    headersMobile: DataTableHeader[] = completedInspectionHeaderMobile();

    headerInspectionSummary: DataTableHeader[] = inspectionSummaryHeader();

    headerInspectionDetail: DataTableHeader[] = inspectionDetailHeader();
    headerInspectionDetailMobile: DataTableHeader[] = inspectionDetailHeaderMobile();

    get applicationDateModel() {
        if (this.applicationDateRange.length > 0) {
            const dates = this.applicationDateRange.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get effectiveStartDateModel() {
        return this.effectiveStartDate.length > 0 ? datePickerFormat(this.effectiveStartDate) : '';
    }

    get effectiveEndDateModel() {
        return this.effectiveEndDate.length > 0 ? datePickerFormat(this.effectiveEndDate) : '';
    }

    get updateData() {
        return this.inspections.filter(inspection => {
            return (
                this.siteUpdated(inspection) &&
                this.activityUpdated(inspection) &&
                this.cityUpdated(inspection) &&
                this.dateUpdated(inspection) &&
                this.facturableUpdated(inspection)
            );
        });
    }

    get updateDetailData() {
        return this.detailInspections.filter(inspection => {
            return (
                this.siteEffectedUpdated(inspection) &&
                this.cityEffectedUpdated(inspection) &&
                this.activityEffectedUpdated(inspection) &&
                this.effectedDateUpdated(inspection)
            );
        });
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    get siteOrdered() {
        return this.sites
            .sort((a, b) => a.localeCompare(b))
            .sort((a, b) => {
                const hasA = this.selectedSites.find(site => site === a) !== undefined;
                const hasB = this.selectedSites.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    mounted() {
        this.pageNameBus().add('Mon entretien');
        this.tabsNavigationBus().add(this.tabs);
        this.tabsNumberNavigationBusId = this.tabsNumberNavigationBus().subscribe(value => {
            this.tab = value!;
        });

        this.getYearlySummary();

        this.inspectionRepository()
            .getInspections()
            .then(data => {
                this.inspections = data;
                this.sites = data.map(d => d.siteName);
                this.cities = data.map(d => d.city);
                this.activities = data.map(d => d.activity);
            });
    }

    destroyed() {
        this.tabsNavigationBus().add([]);
        this.tabsNumberNavigationBus().unsubscribe(this.tabsNumberNavigationBusId);
    }

    async getYearlySummary() {
        this.inspectionSummary = [];
        this.detailInspections = [];
        const items = await this.inspectionRepository().getSynthesisInspectionSummary(this.selectedYear);
        Object.values(items).forEach(item => this.inspectionSummary.push(item));

        this.detailInspections = await this.inspectionRepository()
            .getDetailInspectionSummary(this.selectedYear)
            .catch(() => []);
        this.fromDomain(this.detailInspections);
    }

    exportData(type: string) {
        this.inspectionRepository()
            .getDetailInspectionExport(type, this.selectedYear.toString())
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    downloadInspection(inspection: ICompletedInspection) {
        this.inspectionRepository().getRealisedInspectionExport(inspection.id);
    }

    exportDataRealised(fileType: string) {
        this.inspectionRepository().getExportRealised(fileType, this.toExportParams(fileType));
    }

    private toExportParams(fileType: string): ExportRealisedParam {
        return {
            type: fileType,
            cities: this.selectedCities.length > 0 ? this.selectedCities : undefined,
            sites: this.selectedSites.length > 0 ? this.selectedSites : undefined,
            activities: this.selectedDetailActivities.length > 0 ? this.selectedDetailActivities : undefined,
            from: this.applicationDateRange.length > 0 ? new Date(this.applicationDateRange[0]) : undefined,
            to: this.applicationDateRange.length > 1 ? new Date(this.applicationDateRange[1]) : undefined,
            billable: this.billableSelected.length < 2 ? this.billableSelected : undefined,
        };
    }

    private fromDomain(inspections: IPlannedDetailInspection[]) {
        this.createDetailCityFilter(inspections);
        this.createDetailSiteFilter(inspections);
        this.createDetailActivityFilter(inspections);
    }

    private createDetailCityFilter(inspections: IPlannedDetailInspection[]) {
        inspections.forEach(inspection => {
            this.detailCities.push(inspection.city);
        });
        this.detailCities = [...new Set(this.detailCities)];
    }

    private createDetailSiteFilter(inspections: IPlannedDetailInspection[]) {
        inspections.forEach(inspection => {
            this.detailSites.push(inspection.siteName);
        });
        this.detailSites = [...new Set(this.detailSites)];
    }

    private createDetailActivityFilter(inspections: IPlannedDetailInspection[]) {
        inspections.forEach(inspection => {
            this.detailActivities.push(inspection.activity);
        });
        this.detailActivities = [...new Set(this.detailActivities)];
    }

    private siteUpdated(inspection: ICompletedInspection) {
        return this.isIncluded(this.selectedSites, inspection.siteName);
    }

    private activityUpdated(inspection: ICompletedInspection) {
        return this.isIncluded(this.activitiesSelected, inspection.activity);
    }

    private cityUpdated(inspection: ICompletedInspection) {
        return this.isIncluded(this.selectedCities, inspection.city);
    }

    private siteEffectedUpdated(inspection: IPlannedDetailInspection) {
        return this.isIncluded(this.selectedDetailSites, inspection.siteName);
    }

    private cityEffectedUpdated(inspection: IPlannedDetailInspection) {
        return this.isIncluded(this.selectedDetailCities, inspection.city);
    }

    private activityEffectedUpdated(inspection: IPlannedDetailInspection) {
        return this.isIncluded(this.selectedDetailActivities, inspection.activity);
    }

    private facturableUpdated(inspection: ICompletedInspection) {
        return this.isIncluded(this.billableSelected, inspection.billable);
    }

    private isIncluded(list: (string | boolean)[], value: string | boolean): boolean {
        return list.length > 0 ? list.includes(value) : true;
    }

    private dateUpdated(inspection: ICompletedInspection) {
        if (this.applicationDateRange.length > 0 && this.applicationDateRange.length > 1)
            return moment(new Date(inspection.date).toDateString()).isBetween(
                new Date(this.applicationDateRange[0]).toDateString(),
                new Date(this.applicationDateRange[1]).toDateString(),
                undefined,
                '[]'
            );
        if (this.applicationDateRange.length > 0)
            return moment(new Date(inspection.date).toDateString()).isSameOrAfter(new Date(this.applicationDateRange[0]).toDateString());
        if (this.applicationDateRange.length > 1)
            return moment(new Date(inspection.date).toDateString()).isSameOrBefore(new Date(this.applicationDateRange[1]).toDateString());
        return true;
    }

    private effectedDateUpdated(inspection: IPlannedDetailInspection) {
        if (this.effectiveStartDate.length > 0 && this.effectiveEndDate.length > 0)
            return moment(new Date(inspection.applicationDate).toDateString()).isBetween(
                new Date(this.effectiveStartDate).toDateString(),
                new Date(this.effectiveEndDate).toDateString(),
                undefined,
                '[]'
            );
        if (this.effectiveStartDate.length > 0)
            return moment(new Date(inspection.applicationDate).toDateString()).isSameOrAfter(
                new Date(this.effectiveStartDate).toDateString()
            );
        if (this.effectiveEndDate.length > 0)
            return moment(new Date(inspection.applicationDate).toDateString()).isSameOrBefore(
                new Date(this.effectiveEndDate).toDateString()
            );
        return true;
    }
}
