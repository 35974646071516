import { UserSite } from '@/domain/site/UserSite';
import { stringCleaner } from '@/secondary/global/StringCleaner';
import { RestUserSite, toUserSite } from '@/secondary/restSite/RestUserSite';

export interface RestSiteUserSite {
    $type: 'SirusSite' | 'SitesGroup';
    Reference: 'string';
    Name: 'string';
    Sites?: RestUserSite[];
}

export const toUserSite2 = (restUserSite: RestSiteUserSite): UserSite => ({
    reference: restUserSite.Reference,
    name: stringCleaner(restUserSite.Name),
    sites: restUserSite.Sites?.map(toUserSite),
});
