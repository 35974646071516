import { AxiosInstance, AxiosResponse } from 'axios';

import { TimeGridRepository } from '@/domain/timeGrid/TimeGridRepository';
import { TimeGridSchedule } from '@/domain/timeGrid/TimeGridSchedule';
import { TimeGridSiteGroup } from '@/domain/timeGrid/TimeGridSiteGroup';
import { RestTimeGridSchedule, toRestTimeGridSchedule, toTimeGridSchedule } from '@/secondary/restTimeGrid/RestTimeGridSchedule';
import { RestTimeGridSiteGroup, toTimeGridSiteGroup } from '@/secondary/restTimeGrid/RestTimeGrisSiteGroup';

export class RestTimeGridRepository implements TimeGridRepository {
    constructor(private axios: AxiosInstance) {}

    getGroups(reference: string): Promise<TimeGridSiteGroup[]> {
        return this.axios
            .get(`/Site/${reference}/Schedules/Groups`)
            .then((response: AxiosResponse<RestTimeGridSiteGroup[]>) => {
                return response.data.map(toTimeGridSiteGroup);
            })
            .catch(error => {
                throw Error(error);
            });
    }

    getGrid(reference: string, group: string, week: number): Promise<TimeGridSchedule[]> {
        return this.axios
            .get(`/Site/${reference}/Schedules/Group/${this.encodeBase64URL(group)}`, {
                params: {
                    Week: week,
                },
            })
            .then((response: AxiosResponse<RestTimeGridSchedule[]>) => {
                return response.data.map(toTimeGridSchedule);
            })
            .catch(error => {
                throw Error(error);
            });
    }

    putGrid(schedule: TimeGridSchedule): Promise<void> {
        return this.axios.put('/Schedules', toRestTimeGridSchedule(schedule));
    }

    private encodeBase64URL(value: string) {
        return window.btoa(value).replace(/\//gm, '_').replace(/\+/gm, '-');
    }
}
