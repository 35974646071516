import { Site } from '@/domain/contact/Site';
import { stringCleaner } from '@/secondary/global/StringCleaner';
import { RestContact, toContact } from '@/secondary/restContact/RestContact';

export interface RestSite {
    SiteReference: string;
    SiteName: string;
    Contacts: RestContact[];
}

export const toSite = (restSite: RestSite): Site => ({
    name: stringCleaner(restSite.SiteName),
    reference: restSite.SiteReference,
    contacts: restSite.Contacts.map(toContact),
});
