import { IQuote } from '@/domain/quotes/IQuote';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export interface RestQuote {
    Number: number;
    ClientCode: number;
    SiteName: string;
    City: string | null;
    Activity: string;
    Date: string;
    Cost: number | null;
}

export const toQuote = (restQuote: RestQuote): IQuote => ({
    id: restQuote.Number,
    siteReference: restQuote.ClientCode,
    siteName: stringCleaner(restQuote.SiteName),
    city: restQuote.City,
    activity: restQuote.Activity,
    date: restQuote.Date,
    cost: restQuote.Cost,
});
