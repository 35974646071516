import { Component, Inject, Vue } from 'vue-property-decorator';

import { Right } from '@/domain/menu/Right';
import { RightRepository } from '@/domain/rights/RightRepository';
import { Routes } from '@/domain/Routes';

@Component
export class RightMixin extends Vue {
    @Inject()
    private rightRepository!: () => RightRepository;

    created() {
        if (this.$route && !this.hasSomeRight(this.$route.meta!.authorize)) this.$router.push({ name: Routes.DashboardPage });
    }

    hasSomeRight(rights?: Right[]): boolean {
        return rights ? rights.some(right => this.rightRepository().hasRight(right)) : true;
    }

    hasRight(right?: Right): boolean {
        return right ? this.rightRepository().hasRight(right) : true;
    }
}
