import { UserSite } from '@/domain/site/UserSite';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export interface RestUserSite {
    Reference: 'string';
    Name: 'string';
}

export const toUserSite = (restUserSite: RestUserSite): UserSite => ({
    reference: restUserSite.Reference,
    name: stringCleaner(restUserSite.Name),
});
