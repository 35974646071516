import { WeeklyPatrolCreator } from '@/domain/video/WeeklyPatrolCreator';

export interface RestWeeklyPatrolCreator {
    SiteReference: string;
    Code: string;
    Type: number;
    FixedTime?: string;
    Weekly: true;
    Days: number[];
    From: Date;
    To: Date;
}

export const toRestWeeklyPatrolCreator = (patrolCreator: WeeklyPatrolCreator): RestWeeklyPatrolCreator => ({
    SiteReference: patrolCreator.siteReference,
    Code: patrolCreator.roundCode,
    Type: patrolCreator.type,
    FixedTime: patrolCreator.fixedTime,
    Weekly: true,
    Days: patrolCreator.days,
    From: patrolCreator.from,
    To: patrolCreator.to,
});
