import { Store } from 'vuex';

import { Right } from '@/domain/menu/Right';
import { RightRepository } from '@/domain/rights/RightRepository';

export class LocalRightsStoreRepository implements RightRepository {
    constructor(private store: Store<any>) {}

    hasRight(right: Right): boolean {
        if (right === Right.HAS_SITES) return this.store.getters['localStorageModule/hasSites'] ?? false;
        return this.getRights().includes(right);
    }

    listenRights(callBack: (rights: Right[]) => void): () => void {
        return this.store.watch(
            (state: any, getters: any) =>
                getters['localStorageModule/user'].userToken == undefined ? undefined : getters['localStorageModule/rights'],
            callBack
        );
    }

    private getRights(): Right[] {
        return this.store.getters['localStorageModule/user'].userToken == undefined ? [] : this.store.getters['localStorageModule/rights'];
    }
}
