import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';

import { Contact, emptyContact } from '@/domain/contact/Contact';
import { ContactRepository } from '@/domain/contact/ContactRepository';
import { IconVue } from '@/primary/components/common/icon';

@Component({
    components: {
        IconVue,
    },
})
export default class ModifyPhoneContactComponent extends Vue {
    @Inject()
    private contactRepository!: () => ContactRepository;

    @Prop()
    private contact?: Contact;

    @Prop({ default: false })
    private isModifyContact?: boolean;

    @Prop({ default: false })
    private showIcon?: boolean;

    hasModifyContact = false;
    newPhoneNumber = '';
    newPhoneNumber2 = '';
    newPhoneNumber3 = '';
    newPhoneNumber4 = '';
    updatedContact: Contact = emptyContact();
    isLoading = false;

    @Emit()
    changePhone() {
        this.isLoading = true;
        if (this.isModifyContact) {
            this.contactRepository()
                .updatePhones(
                    this.contact!.name,
                    this.createTabFromPhones(this.contact!.phone, this.contact!.phone2, this.contact!.phone3, this.contact!.phone4),
                    this.createTabFromPhones(this.newPhoneNumber, this.newPhoneNumber2, this.newPhoneNumber3, this.newPhoneNumber4)
                )
                .then(() => {
                    this.updateContact();
                    this.cancelModify();
                    return this.updatedContact;
                })
                .finally(() => (this.isLoading = false));
        } else {
            this.editPhone();
            return this.newPhoneNumber;
        }
    }

    createTabFromPhones(phone1: string, phone2?: string, phone3?: string, phone4?: string): string[] {
        const phones = [phone1];
        if (phone2 && phone2.length > 0) phones.push(phone2);
        if (phone3 && phone3.length > 0) phones.push(phone3);
        if (phone4 && phone4.length > 0) phones.push(phone4);
        return phones;
    }

    editPhone() {
        if (this.contact) {
            this.newPhoneNumber = this.contact.phone;
            this.newPhoneNumber2 = this.contact.phone2 ? this.contact.phone2 : '';
            this.newPhoneNumber3 = this.contact.phone3 ? this.contact.phone3 : '';
            this.newPhoneNumber4 = this.contact.phone4 ? this.contact.phone4 : '';
        }
        this.hasModifyContact = !this.hasModifyContact;
    }

    cancelModify() {
        this.editPhone();
    }

    get hasError(): boolean {
        return (
            this.phoneCheck(this.newPhoneNumber) != true ||
            this.phoneCheck(this.newPhoneNumber2, true) != true ||
            this.phoneCheck(this.newPhoneNumber3, true) != true ||
            this.phoneCheck(this.newPhoneNumber4, true) != true
        );
    }

    phoneCheck(value: string, soft?: boolean) {
        if (soft && value.length == 0) return true;
        return /^((\+)33|0)[1-9](\d{2}){4}$/g.test(value) || 'Format invalide';
    }

    private updateContact() {
        this.updatedContact.phone = this.newPhoneNumber;
        this.updatedContact.phone2 = this.assignNullablePhone(this.newPhoneNumber2);
        this.updatedContact.phone3 = this.assignNullablePhone(this.newPhoneNumber3);
        this.updatedContact.phone4 = this.assignNullablePhone(this.newPhoneNumber4);
        this.updatedContact.name = this.contact!.name;
        this.contact!.phone = this.newPhoneNumber;
        this.contact!.phone2 = this.assignNullablePhone(this.newPhoneNumber2);
        this.contact!.phone3 = this.assignNullablePhone(this.newPhoneNumber3);
        this.contact!.phone4 = this.assignNullablePhone(this.newPhoneNumber4);
    }

    private assignNullablePhone(newPhoneNumber: string): string | undefined {
        return newPhoneNumber.length > 0 ? newPhoneNumber : undefined;
    }
}
