import { MailAlertType } from '@/domain/user/MailAlertType';

export enum RestMailAlertType {
    P = 'P',
    S = 'S',
}

export const toRestMailAlertType = (mailAlertType: MailAlertType): RestMailAlertType => {
    if (mailAlertType == MailAlertType.P) return RestMailAlertType.P;
    return RestMailAlertType.S;
};

export const fromRestMailAlertType = (restMailAlertType: RestMailAlertType): MailAlertType => {
    if (restMailAlertType == RestMailAlertType.P) return MailAlertType.P;
    return MailAlertType.S;
};
