import { AxiosError } from 'axios';
import { Vue } from 'vue-property-decorator';

import { IRestError } from '../IRestError';

import { Routes } from '@/domain/Routes';
import { IToast } from '@/domain/toast/IToast';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import ToastBus from '@/primary/utils/ToastBus';
import { router } from '@/routes';
import { StoreRepository } from '@/secondary/store/localeStore/StoreRepository';

export default class AuthenticatedInterceptor extends Vue {
    constructor(
        private toastBus: ToastBus,
        private storeRepository: StoreRepository
    ) {
        super();
    }

    onReject(error: AxiosError<IRestError>) {
        const errorToast: IToast = {
            status: ToastStatus.ERROR,
            title: this.getTitle(error),
            text: this.getErrorMessage(error),
        };
        this.toastBus.add(errorToast);
        this.changeRouteIfNeeded(error);
        throw Error(this.getErrorMessage(error));
    }

    private changeRouteIfNeeded(error: AxiosError<IRestError>) {
        if (error.response!.status == 401) {
            this.storeRepository.removeUser();
            router.push({ name: Routes.LoginPage });
        }
    }

    private getErrorMessage(error: AxiosError<IRestError>) {
        if (error.response!.status == 401) return 'Suite à votre inactivité, votre session a expiré.';
        else if (error.response!.status == 403)
            return error.response!.data.Value.Error.Message ?? "Vous n'avez pas les droits requis pour réaliser cette action.";
        else if (error.response!.status == 409) return error.response?.data.Value?.Error.Message!;
        return 'Une erreur inconnue est survenue veuillez réessayer ultérieurement';
    }

    private getTitle(error: AxiosError<IRestError>) {
        if (error.response!.status == 401) return 'Avertissement';
        return 'Erreur';
    }
}
