import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { RestSite, toSiteMap } from './RestSite';

import { ExportSiteParam } from '@/domain/site/ExportSiteParams';
import { SiteDetails } from '@/domain/site/siteDetails/SiteDetails';
import { SiteMap } from '@/domain/site/SiteMap';
import { SiteRepository } from '@/domain/site/SiteRepository';
import { UserSite } from '@/domain/site/UserSite';
import { downloadFile } from '@/secondary/DownloadFile';
import { RestSiteDetails, toSiteDetails } from '@/secondary/restSite/RestSiteDetails';
import { RestSiteUserSite, toUserSite2 } from '@/secondary/restSite/RestSiteUserSite';
import { RestUserSite, toUserSite } from '@/secondary/restSite/RestUserSite';

export class RestSiteRepository implements SiteRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    async getSite(): Promise<SiteMap[]> {
        return this.axiosInstance
            .get('/Sites')
            .then((res: AxiosResponse<RestSite[]>) => res.data.sort((a, b) => b.AnomalySeverity - a.AnomalySeverity).map(toSiteMap))
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    async getUserSite(): Promise<UserSite[]> {
        return this.axiosInstance.get('/User/Sites').then((res: AxiosResponse<RestUserSite[]>) => res.data.map(toUserSite));
    }

    async getSirusUserSite(): Promise<UserSite[]> {
        return this.axiosInstance.get('/User/SirusSites').then((res: AxiosResponse<RestSiteUserSite[]>) => res.data.map(toUserSite2));
    }

    async getSiteDetails(siteReference: string): Promise<SiteDetails> {
        return this.axiosInstance.get('/Site/' + siteReference + '/Details').then((res: AxiosResponse<RestSiteDetails>) => {
            return toSiteDetails(res.data);
        });
    }

    async getSiteWithTvi(): Promise<SiteMap[]> {
        return this.axiosInstance
            .get('/Sites/WithTvi')
            .then((res: AxiosResponse<RestSite[]>) => {
                return res.data.map(toSiteMap);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    // export data sites
    getExport(type: string, exportParams: ExportSiteParam): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        const query = `/Sites/Export/${type}`;
        return this.axiosInstance
            .post(query, exportParams, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }
}
