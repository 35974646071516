export type IconName =
    | 'icon-consignes'
    | 'icon-history'
    | 'icon-building'
    | 'icon-video'
    | 'icon-download'
    | 'icon-location'
    | 'icon-building-alt'
    | 'icon-building1'
    | 'icon-calendar'
    | 'icon-clock'
    | 'icon-video3'
    | 'icon-file'
    | 'icon-image'
    | 'icon-burger'
    | 'icon-trombone'
    | 'icon-user-minus'
    | 'icon-arrow-down'
    | 'icon-minus'
    | 'icon-logout'
    | 'icon-info-menu'
    | 'icon-file-o'
    | 'icon-depannages'
    | 'icon-coin-euro'
    | 'icon-price-tag'
    | 'icon-cone'
    | 'icon-check-circle'
    | 'icon-delete'
    | 'icon-email'
    | 'icon-receipt'
    | 'icon-full-calendar'
    | 'icon-android'
    | 'icon-apple'
    | 'icon-message-phone';

export const IconHTML = new Map([
    ['icon-consignes', 2],
    ['icon-history', 0],
    ['icon-building', 0],
    ['icon-video', 0],
    ['icon-download', 0],
    ['icon-location', 0],
    ['icon-building-alt', 0],
    ['icon-building1', 0],
    ['icon-calendar', 0],
    ['icon-clock', 0],
    ['icon-video3', 0],
    ['icon-file', 5],
    ['icon-file-o', 0],
    ['icon-image', 0],
    ['icon-burger', 0],
    ['icon-trombone', 0],
    ['icon-user-minus', 0],
    ['icon-arrow-down', 0],
    ['icon-minus', 0],
    ['icon-logout', 0],
    ['icon-info-menu', 0],
    ['icon-depannages', 0],
    ['icon-coin-euro', 0],
    ['icon-price-tag', 0],
    ['icon-cone', 0],
    ['icon-check-circle', 0],
    ['icon-delete', 0],
    ['icon-email', 0],
    ['icon-receipt', 0],
    ['icon-full-calendar', 0],
    ['icon-android', 0],
    ['icon-apple', 0],
    ['icon-message-phone', 0],
]);
