import { Component, Inject, Vue } from 'vue-property-decorator';
import { VBtn, VIcon, VImg } from 'vuetify/lib';

import { EventRepository } from '@/domain/event/EventRepository';
import { Right } from '@/domain/menu/Right';
import { RightRepository } from '@/domain/rights/RightRepository';
import { SiteRepository } from '@/domain/site/SiteRepository';
import { Theme } from '@/domain/theme/Theme';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

@Component({
    components: {
        VBtn,
        VIcon,
        VImg,
        IconVue,
    },
})
export default class DashboardPageComponent extends Vue {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private siteRepository!: () => SiteRepository;

    @Inject()
    private eventRepository!: () => EventRepository;

    @Inject()
    private rightRepository!: () => RightRepository;

    inactiveSiteCount = 0;
    activeSiteCount = 0;
    missingSiteCount = 0;
    hasRecentAlarm = false;
    theme!: Theme;
    listenerStopperRight?: () => void;

    created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;
    }

    mounted() {
        this.pageNameBus().add('');
        if (!this.hasRight) this.listenerStopperRight = this.rightRepository().listenRights(this.loadData);
        this.loadData();
    }

    destroyed() {
        if (this.listenerStopperRight) this.listenerStopperRight();
    }

    to(name: string) {
        this.$router.push({ name: name });
    }

    get hasRight(): boolean {
        return this.rightRepository().hasRight(Right.RS_SIT_LST) && this.rightRepository().hasRight(Right.HAS_SITES);
    }

    get hasRightHistory(): boolean {
        return this.rightRepository().hasRight(Right.CNS_HIS) && this.rightRepository().hasRight(Right.HAS_SITES);
    }

    get hasRightMaintenance(): boolean {
        return this.rightRepository().hasRight(Right.RS_DEPH_LST);
    }

    get hasRightCallList(): boolean {
        return this.rightRepository().hasRight(Right.CNS_CSG) && this.rightRepository().hasRight(Right.HAS_SITES);
    }

    private loadData() {
        if (this.hasRight)
            this.siteRepository()
                .getSite()
                .then(sites => {
                    sites.forEach(site => {
                        if (site.state == 'Inactif') this.inactiveSiteCount += 1;
                        else if (site.state == 'Actif') this.activeSiteCount += 1;
                        else if (site.state == 'Absence de test') this.missingSiteCount += 1;
                    });
                });
        if (this.hasRightHistory)
            this.eventRepository()
                .hasRecentAlarm()
                .then(hasRecentAlarm => {
                    this.hasRecentAlarm = hasRecentAlarm;
                });
    }
}
