export interface RestActivationUser {
    UserFirstName: string;
    UserLastName: string;
    UserEmail: string;
}

export const toActivationUser = (restUser: RestActivationUser) => ({
    email: restUser.UserEmail,
    lastName: restUser.UserLastName,
    firstName: restUser.UserFirstName,
});
