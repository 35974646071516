import { SiteMap } from '@/domain/site/SiteMap';

export interface SiteMapPrimary {
    reference: string;
    name: string;
    state: string;
    city: string;
    lastAlarmEventDate: string;
    anomalies: string[];
}

export const emptySite = (): SiteMapPrimary => ({
    reference: '',
    name: '',
    state: '',
    city: '',
    lastAlarmEventDate: '',
    anomalies: [],
});

export const toSitePrimary = (siteMap: SiteMap): SiteMapPrimary => ({
    ...siteMap,
    anomalies: [siteMap.anomaly],
});
