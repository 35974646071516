import { DoubleAuthType } from '@/domain/userManagement/DoubleAuthType';
import { Profil } from '@/domain/userManagement/Profil';
import { SimplifiedSite } from '@/domain/userManagement/SimplifiedSite';

export interface ManagedUser {
    id: number;
    lastName: string;
    firstName: string;
    email: string;
    phone: string;
    profil: Profil;
    doubleAuthType: DoubleAuthType;
    sites: SimplifiedSite[];
}

export const emptyManagedUser = (): ManagedUser => ({
    id: -1,
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    profil: Profil.Reader,
    doubleAuthType: DoubleAuthType.SMS,
    sites: [],
});
