import { MailAlertFrequency } from '@/domain/user/MailAlertFrequency';
import { MailAlertSubscription } from '@/domain/user/MailAlertSubscription';
import { MailAlertType } from '@/domain/user/MailAlertType';

export interface UserMailAlert {
    alertType: MailAlertSubscription;
    frequency: MailAlertFrequency;
    type: MailAlertType;
    subscribed: boolean;
}

export const emptyUserMailAlert = (alertType: MailAlertSubscription): UserMailAlert => ({
    alertType: alertType,
    frequency: MailAlertFrequency.A,
    type: MailAlertType.P,
    subscribed: false,
});
