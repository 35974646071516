import { DataTableHeader } from 'vuetify';

export const videoCreditHeader = (): DataTableHeader[] => [
    { text: 'Période', value: 'period', sortable: false, filterable: false },
    { text: 'Crédits', value: 'credits', sortable: false, filterable: false },
    { text: 'Engagés', value: 'engaged', sortable: false, filterable: false },
    { text: 'Solde', value: 'balance', sortable: false, filterable: false },
];

export const videoInstructionHeader = (): DataTableHeader[] => [{ text: '', value: 'instruction', sortable: false, filterable: false }];
