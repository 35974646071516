import { AxiosResponse } from 'axios';
import * as fileSaver from 'file-saver';

export const downloadFile = (response: AxiosResponse<any>) => {
    const blob: Blob = new Blob([response.data], {
        type: response.headers['content-type'],
    });
    const fileUrl = URL.createObjectURL(blob);
    fileSaver.saveAs(fileUrl, getFileNameFromResponse(response));
};

const getFileNameFromResponse = (response: AxiosResponse<any>) =>
    response.headers['content-disposition']!.replaceAll('"', '').split('=')[1].split(';')[0];
