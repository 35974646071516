import { Component, Vue } from 'vue-property-decorator';
import { VApp, VMain } from 'vuetify/lib';

@Component({
    components: {
        VApp,
        VMain,
    },
})
export default class CommonFooterComponent extends Vue {}
