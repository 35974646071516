import { SimplifiedSite } from '@/domain/userManagement/SimplifiedSite';

export interface RestSimplifiedSite {
    Reference: string;
    Name: string;
    Type: string;
}

export const toSimplifiedSite = (site: RestSimplifiedSite): SimplifiedSite => ({
    name: site.Name,
    reference: site.Reference,
});

export const toRestSimplifiedSite = (site: SimplifiedSite): RestSimplifiedSite => ({
    Name: site.name,
    Reference: site.reference,
    Type: 'SirusSite',
});
