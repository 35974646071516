import { Contact } from '@/domain/contact/Contact';

export interface RestContact {
    Name: string;
    PhoneNumbers: string[];
    Email: string;
    IsReadonly?: boolean;
}

export const toContact = (restContact: RestContact): Contact => ({
    phone: restContact.PhoneNumbers[0],
    phone2: restContact.PhoneNumbers.length > 1 ? restContact.PhoneNumbers[1] : undefined,
    phone3: restContact.PhoneNumbers.length > 2 ? restContact.PhoneNumbers[2] : undefined,
    phone4: restContact.PhoneNumbers.length > 3 ? restContact.PhoneNumbers[3] : undefined,
    name: restContact.Name,
    email: restContact.Email,
    isReadonly: restContact.IsReadonly ? restContact.IsReadonly : false,
});

export const toRestContact = (contact: Contact): RestContact => {
    const phoneNumbers = [contact.phone];
    if (contact.phone2) phoneNumbers.push(contact.phone2);
    if (contact.phone3) phoneNumbers.push(contact.phone3);
    if (contact.phone4) phoneNumbers.push(contact.phone4);

    return {
        PhoneNumbers: phoneNumbers,
        Name: contact.name,
        Email: contact.email,
    };
};
