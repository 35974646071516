import { VideoCredit } from '@/domain/video/VideoCredit';

export interface RestVideoCredit {
    SubscribedCredits: number;
    UsedCredits: number;
    EngagedCredits: number;
    RemainingCredits: number;
    Label: string;
}

export const toVideoCredits = (restVideoCredit: RestVideoCredit): VideoCredit => ({
    credits: restVideoCredit.SubscribedCredits,
    engaged: restVideoCredit.EngagedCredits,
    balance: restVideoCredit.RemainingCredits,
    periodLabel: restVideoCredit.Label,
});
