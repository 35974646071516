import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Emit, Inject, Prop, Watch } from 'vue-property-decorator';

import { Right } from '@/domain/menu/Right';
import { TimeGridRepository } from '@/domain/timeGrid/TimeGridRepository';
import { TimeGridSchedule } from '@/domain/timeGrid/TimeGridSchedule';
import { TimeSlot } from '@/domain/timeGrid/TimeSlot';
import { IconVue } from '@/primary/components/common/icon';
import { WeekDay } from '@/primary/pages/Instructions/WeekDay';
import { WeekDayMobile } from '@/primary/pages/Instructions/WeekDayMobile';
import { Responsive } from '@/primary/utils/Responsive';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: { IconVue },
})
export default class ContractGridTimeComponent extends mixins(RightMixin) {
    @Inject()
    private timeGridRepository!: () => TimeGridRepository;

    @Inject()
    private responsive!: () => Responsive;

    @Prop({ required: true })
    private siteReference!: string;

    @Prop({ required: true })
    private groupReference!: string;

    timeGridSchedules: TimeGridSchedule[] = [];
    schedules: TimeSlot[] = [];

    isGridEditMode = false;
    dayToEdit = -1;
    isContractEditMode = false;

    openTimePickerStart: boolean[] = [false, false];
    timeStart: string[] = ['', ''];
    openTimePickerEnd: boolean[] = [false, false];
    timeEnd: string[] = ['', ''];
    reload = 0;

    @Emit()
    reloadWeekGrid() {
        return this.siteReference;
    }

    mounted() {
        this.getTimeGridGroups();
    }

    save() {
        this.isGridEditMode = false;
        this.timeGridSchedules.forEach(timeGridSchedule => {
            timeGridSchedule.timeSlots = this.schedules.filter(schedule => schedule.reference == timeGridSchedule.reference);
            this.timeGridRepository().putGrid(timeGridSchedule);
        });
        this.reloadWeekGrid();
    }

    cancel() {
        this.isGridEditMode = false;
        this.getTimeGridGroups();
    }

    saveContract() {
        this.isContractEditMode = false;
    }

    cancelContract() {
        this.getTimeGridGroups();
        this.isContractEditMode = false;
    }

    @Watch('isContractEditMode')
    unselectDay() {
        if (!this.isContractEditMode) {
            this.resetAndUpdate();
        } else {
            this.loadTimeSlot();
        }
    }

    editDay(day: number) {
        this.dayToEdit = day;
        this.isContractEditMode = true;
    }

    deleteSlot(index: number) {
        if (index == 0) {
            this.timeStart[0] = this.timeStart[1];
            this.timeEnd[0] = this.timeEnd[1];
        }
        this.timeStart[1] = '';
        this.timeEnd[1] = '';
        this.reload++;
    }

    createNewSlot() {
        const index = +(this.timeStart[0] != '');
        this.timeStart[index] = '00:00';
        this.timeEnd[index] = '23:59';
        this.reload++;
    }

    myDayFormat(d: { weekday: number }) {
        return WeekDay[d.weekday];
    }

    myDayFormatMobile(d: { weekday: number }) {
        return WeekDayMobile[d.weekday];
    }

    toWeekDay(day: number) {
        return WeekDay[day];
    }

    get scheduledForCurrentDay(): TimeSlot[] {
        return this.schedules.filter(schedule => moment(schedule.start).toDate().getDay() == this.dayToEdit);
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    get hasRightMod(): boolean {
        return this.hasRight(Right.DEM_MOD_TSL);
    }

    private formatHour(start: string) {
        return moment(start).locale('fr').format('LT');
    }

    private getTimeGridGroups() {
        this.timeGridRepository()
            .getGrid(this.siteReference, this.groupReference, 0)
            .then(schedules => {
                this.timeGridSchedules = schedules;
                this.setSchedules();
                for (let i = 0; i < 7; i++) {
                    this.dayToEdit = i;
                    if (this.timeGridSchedules[this.dayToEdit == 0 ? 6 : this.dayToEdit - 1].timeSlots.length == 0)
                        this.addClosedTimeslot(this.getDay());
                }
                this.dayToEdit = -1;
            })
            .catch(error => {
                console.log(error);
            });
    }

    private setSchedules() {
        this.schedules.length = 0;
        this.timeGridSchedules.forEach(item => {
            item.timeSlots.forEach(timeslot => {
                this.schedules.push(timeslot);
            });
        });
    }

    private replaceWithNewHour(timeSlot: string, newHour: string): string {
        return timeSlot.replace(timeSlot.substring(11, 16), newHour);
    }

    private loadTimeSlot() {
        this.timeStart = [
            this.scheduledForCurrentDay.length > 0 && !this.scheduledForCurrentDay[0].closed
                ? this.formatHour(this.scheduledForCurrentDay[0].start)
                : '',
            this.scheduledForCurrentDay.length > 1 ? this.formatHour(this.scheduledForCurrentDay[1].start) : '',
        ];
        this.timeEnd = [
            this.scheduledForCurrentDay.length > 0 && !this.scheduledForCurrentDay[0].closed
                ? this.formatHour(this.scheduledForCurrentDay[0].end)
                : '',
            this.scheduledForCurrentDay.length > 1 ? this.formatHour(this.scheduledForCurrentDay[1].end) : '',
        ];
    }

    private resetAndUpdate() {
        this.updateOrDelete(1);
        this.updateOrDelete(0);
        this.addScheduleIfNeeded(0);
        this.addScheduleIfNeeded(1);
        this.dayToEdit = -1;
        this.openTimePickerStart = [false, false];
        this.timeStart = ['', ''];
        this.openTimePickerEnd = [false, false];
        this.timeEnd = ['', ''];
    }

    private updateOrDelete(index: number) {
        if (this.scheduledForCurrentDay.length > index) {
            if (this.timeStart[index].length > 0) {
                this.scheduledForCurrentDay[index].start = this.replaceWithNewHour(
                    this.scheduledForCurrentDay[index].start,
                    this.timeStart[index]
                );
                this.scheduledForCurrentDay[index].end = this.replaceWithNewHour(
                    this.scheduledForCurrentDay[index].end,
                    this.timeEnd[index]
                );
                this.scheduledForCurrentDay[index].closed = false;
            } else {
                const toDelete = this.scheduledForCurrentDay[index];
                this.schedules = this.schedules.filter(schedule => schedule !== toDelete);

                const day = this.getDay();

                if (index == 0) {
                    this.addClosedTimeslot(day);
                }
            }
        }
    }

    private addClosedTimeslot(day: string) {
        const timeslot: TimeSlot = {
            exceptional: false,
            start: day + ' 00:00:00',
            end: day + ' 00:00:00',
            closed: true,
            reference: this.timeGridSchedules[this.dayToEdit == 0 ? 6 : this.dayToEdit - 1].reference,
        };
        this.schedules.push(timeslot);
    }

    private addScheduleIfNeeded(index: number) {
        if (this.timeStart[index].length != 0 && this.scheduledForCurrentDay.length < index + 1) {
            const day = this.getDay();
            const timeslot: TimeSlot = {
                exceptional: false,
                start: day + ' ' + (this.timeStart[index].length == 5 ? this.timeStart[index] : '0' + this.timeStart[index]) + ':00',
                end: day + ' ' + (this.timeEnd[index].length == 5 ? this.timeEnd[index] : '0' + this.timeEnd[index]) + ':00',
                closed: false,
                reference: this.timeGridSchedules[this.dayToEdit == 0 ? 6 : this.dayToEdit - 1].reference,
            };
            this.schedules.push(timeslot);
        }
    }

    private getDay() {
        return moment()
            .startOf('week')
            .add(this.dayToEdit + (this.dayToEdit == 0 ? 8 : 1), 'days')
            .toISOString()
            .split('T')[0];
    }
}
