import { IRemoteMaintenance } from '@/domain/troubleshooting/IRemoteMaintenance';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export type RestRemoteMaintenance = {
    TicketNumber: string;
    SiteReference: string;
    SiteName: string;
    City: string;
    Status: string;
    OpeningDate: string;
    ClosingDate: string;
    Requester: string;
    Type: string;
    Description: string;
};

export const toRemoteMaintenance = (restRemoteMaintenance: RestRemoteMaintenance): IRemoteMaintenance => ({
    id: restRemoteMaintenance.TicketNumber,
    reference: restRemoteMaintenance.SiteReference,
    site: stringCleaner(restRemoteMaintenance.SiteName),
    city: restRemoteMaintenance.City,
    status: restRemoteMaintenance.Status,
    applicationDate: restRemoteMaintenance.OpeningDate,
    closingDate: restRemoteMaintenance.ClosingDate,
    requester: restRemoteMaintenance.Requester,
    applicationType: restRemoteMaintenance.Type,
    description: restRemoteMaintenance.Description,
});
