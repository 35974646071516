export interface FullUser {
    firstName: string;
    lastName: string;
    city: string;
    postalCode: string;
    address: string;
    phone: string;
    mail: string;
    twoFactorAuthentificationMode: number;
}

export const emptyFullUser = (): FullUser => ({
    firstName: '',
    lastName: '',
    city: '',
    postalCode: '',
    address: '',
    phone: '',
    mail: '',
    twoFactorAuthentificationMode: 0,
});
