import { SiteMap } from '@/domain/site/SiteMap';
import { State } from '@/domain/site/State';
import { stringCleaner } from '@/secondary/global/StringCleaner';

export interface RestSite {
    Reference: string;
    Name: string;
    State: number;
    City: string;
    LastAlarmEventDate: string;
    AnomalySeverity: number;
    AnomalyLabel: string;
    AnomalyType: number;
}

export const toSiteMap = (restSite: RestSite): SiteMap => ({
    name: stringCleaner(restSite.Name),
    lastAlarmEventDate: restSite.LastAlarmEventDate,
    reference: restSite.Reference,
    state: State[restSite.State],
    city: restSite.City,
    anomaly: restSite.AnomalyLabel,
});
