import { Right } from '@/domain/menu/Right';
import { Routes } from '@/domain/Routes';
import { IconName } from '@/primary/components/common/icon/IconUtils';

export interface IMenuItem {
    title: string;
    url?: string;
    childs?: IMenuItem[];
    icon?: IconName;
    right?: Right[];
}

export const initMenu = (): IMenuItem[] => [
    { title: 'Accueil', url: Routes.DashboardPage },
    {
        title: 'Télésurveillance',
        childs: [
            { title: 'Mes sites', url: Routes.SitePage, icon: 'icon-building', right: [Right.RS_SIT_LST, Right.HAS_SITES] },
            { title: 'Mes historiques', url: Routes.HistoryPage, icon: 'icon-history', right: [Right.CNS_HIS, Right.HAS_SITES] },
            { title: 'Mes consignes', url: Routes.InstructionsPage, icon: 'icon-consignes', right: [Right.CNS_CSG, Right.HAS_SITES] },
            {
                title: 'Demande de rondes vidéos',
                url: Routes.VideoCheckUpPage,
                icon: 'icon-video',
                right: [Right.CNS_PAT, Right.HAS_SITES],
            },
        ],
    },
    {
        title: 'Maintenance',
        childs: [
            {
                title: 'Mon SAV',
                url: Routes.TroubleshootingPage,
                icon: 'icon-depannages',
                right: [Right.RS_DEPH_LST],
            },
            {
                title: 'Mon entretien',
                url: Routes.MyInspection,
                icon: 'icon-check-circle',
                right: [Right.RS_VEP_LST],
            },
            {
                title: 'Mes chantiers',
                url: Routes.WorkSitePage,
                icon: 'icon-cone',
                right: [Right.RS_TN_AVCHA],
            },
            {
                title: 'Mes devis en attente',
                url: Routes.QuotesPage,
                icon: 'icon-receipt',
                right: [Right.RS_DEVA_LST],
            },
        ],
    },
    { title: 'Mes documents', url: Routes.DocumentPage },
];
