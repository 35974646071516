import { Contact } from '@/domain/contact/Contact';

export interface TableContact {
    name: string;
    contact: string;
    isEmail: boolean;
}

export const toTableContact = (contact: Contact): TableContact[] => {
    const result: TableContact[] = [];

    if (contact.phone && contact.phone.length > 0)
        result.push({
            name: contact.email && contact.email.length > 0 ? contact.name + ' (Tél)' : contact.name,
            contact: contact.phone,
            isEmail: false,
        });
    if (contact.email && contact.email.length > 0)
        result.push({
            name: contact.phone && contact.phone.length > 0 ? contact.name + ' (Mél)' : contact.name,
            contact: contact.email,
            isEmail: true,
        });

    return result;
};
