import { Document } from '@/domain/file/Document';
import { FileType } from '@/domain/file/FileType';
import { RestFile } from '@/secondary/restFile/RestFile';

export interface RestDocument extends RestFile {
    Type: FileType.DOCUMENT;
}

export const toDocument = (restDocument: RestDocument): Document => ({
    type: FileType.DOCUMENT,
    id: restDocument.Id,
    label: restDocument.Label,
});
