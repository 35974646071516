import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ManagedUser } from '@/domain/userManagement/ManagedUser';
import { UserManagementRepository } from '@/domain/userManagement/UserManagementRepository';
import { downloadFile } from '@/secondary/DownloadFile';
import { toRestManagedUser } from '@/secondary/userManagement/RestManagedUser';
import { RestManagedUserWithId, toManagedUser } from '@/secondary/userManagement/RestManagedUserWithId';

export class RestUserManagementRepository implements UserManagementRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getAll(): Promise<ManagedUser[]> {
        return this.axiosInstance
            .get('/ManagedUsers')
            .then((response: AxiosResponse<RestManagedUserWithId[]>) => response.data.map(toManagedUser));
    }

    async deleteAll(ids: number[]): Promise<void> {
        for (const id of ids) await this.axiosInstance.delete('/ManagedUsers/' + id);
        return Promise.resolve();
    }

    getExport(type: string): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance.post(`/ManagedUsers/Export/${type}`, null, params).then(downloadFile);
    }

    save(user: ManagedUser): Promise<void> {
        if (user.id !== -1) return this.axiosInstance.put('/ManagedUsers/' + user.id, toRestManagedUser(user));
        return this.axiosInstance.post('/ManagedUsers', toRestManagedUser(user));
    }
}
