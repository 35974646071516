import { FullUser } from '@/domain/user/FullUser';
import { User } from '@/domain/user/User';

export interface RestUser {
    FirstName: string;
    LastName: string;
    MobilePhoneNumber: string | null;
    TermsOfServiceValidationDate?: string;
    City: string;
    PostalCode: string;
    Address1: string;
    MainMail: string;
    Rights?: string[];
    TwoFactorAuthenticationMode: number | null;
}

export const toUser = (restUser: RestUser): User => ({
    firstName: restUser.FirstName,
    lastName: restUser.LastName,
    phone: restUser.MobilePhoneNumber!,
});

export const toFullUser = (restUser: RestUser): FullUser => ({
    firstName: restUser.FirstName,
    lastName: restUser.LastName,
    city: restUser.City,
    postalCode: restUser.PostalCode,
    address: restUser.Address1,
    phone: restUser.MobilePhoneNumber ?? '',
    mail: restUser.MainMail,
    twoFactorAuthentificationMode: restUser.TwoFactorAuthenticationMode ?? 0,
});

export const toRestUserFull = (user: FullUser): RestUser => ({
    FirstName: user.firstName,
    LastName: user.lastName,
    MobilePhoneNumber: user.phone,
    City: user.city,
    PostalCode: user.postalCode,
    Address1: user.address,
    MainMail: user.mail,
    TwoFactorAuthenticationMode: user.twoFactorAuthentificationMode,
});
