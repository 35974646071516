import { AxiosError } from 'axios';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Emit, Inject, Prop, Watch } from 'vue-property-decorator';

import { Right } from '@/domain/menu/Right';
import { IToast } from '@/domain/toast/IToast';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import { PatrolCreator } from '@/domain/video/PatrolCreator';
import { VideoInstructionRepository } from '@/domain/video/VideoInstructionRepository';
import { VideoPatrolRepository } from '@/domain/video/VideoPatrolRepository';
import { WeeklyPatrolCreator } from '@/domain/video/WeeklyPatrolCreator';
import { IconVue } from '@/primary/components/common/icon';
import { Responsive } from '@/primary/utils/Responsive';
import { RightMixin } from '@/primary/utils/rights/RightMixin';
import ToastBus from '@/primary/utils/ToastBus';

@Component({
    components: {
        IconVue,
    },
})
export default class VideoPatrolDialogComponent extends mixins(RightMixin) {
    @Inject()
    private videoInstructionRepository!: () => VideoInstructionRepository;

    @Inject()
    private videoPatrolRepository!: () => VideoPatrolRepository;

    @Inject()
    private toastBus!: () => ToastBus;

    @Inject()
    private responsive!: () => Responsive;

    @Prop({ required: true })
    private siteReference!: string;

    modal = false;

    rounds: string[] = [];
    roundSelected = '';

    times = ['Nuit (10 crédits)', 'Matin (10 crédits)', 'Midi (10 crédits)', 'Après-midi (10 crédits)', 'Soir (10 crédits)'];
    timeSelected = this.times[0];

    minDate = new Date().toISOString();

    dateMenu = false;
    dateSelected = new Date().toISOString();

    startDateMenu = false;
    startDateSelected = new Date().toISOString();

    endDateMenu = false;
    endDateSelected = new Date().toISOString();

    time: string | null = null;
    openTimePicker = false;

    fixedTime = false;
    repeted = false;
    selectedDays: boolean[] = [false, false, false, false, false, false, false];
    days: string[] = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];

    @Watch('siteReference')
    getPatrols() {
        this.videoInstructionRepository()
            .getPatrols(this.siteReference)
            .then(patrols => {
                this.rounds = patrols.map(round => round.name);
                this.roundSelected = patrols.length > 0 ? patrols[0].name : '';
            });
    }

    add() {
        if (this.repeted)
            this.videoPatrolRepository()
                .createWeeklyPatrol(this.siteReference, this.generateWeeklyPatrol())
                .then(() => this.concludeAdd())
                .catch(error => this.onError(error));
        this.videoPatrolRepository()
            .createPatrol(this.siteReference, this.generatePatrol())
            .then(() => this.concludeAdd())
            .catch(error => this.onError(error));
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    private onError(error: AxiosError) {
        if (error.message.includes('Not enough credit')) {
            const errorToast: IToast = {
                status: ToastStatus.ERROR,
                title: 'Erreur',
                text: this.fixedTime
                    ? 'Demande impossible / Vous ne disposez plus de suffisamment de crédit pour ajouter une nouvelle ronde sur un horaire fixe. Contactez votre agence pour plus de renseignement'
                    : 'Demande impossible / Vous ne disposez plus de suffisamment de crédit pour ajouter une nouvelle ronde. Contactez votre agence pour plus de renseignement',
            };
            this.toastBus().add(errorToast);
        } else throw new Error(error.message);
    }

    concludeAdd() {
        this.modal = false;
        this.reload();
    }

    closeModal() {
        this.modal = false;
        this.timeSelected = this.times[0];
        this.dateMenu = false;
        this.dateSelected = new Date().toISOString();
        this.startDateMenu = false;
        this.startDateSelected = new Date().toISOString();
        this.endDateMenu = false;
        this.endDateSelected = new Date().toISOString();
        this.time = null;
        this.fixedTime = false;
        this.repeted = false;
        this.selectedDays = [false, false, false, false, false, false];
    }

    get formatCustomDate() {
        return this.formatDate(this.dateSelected);
    }

    get minHour() {
        const selected = new Date(this.dateSelected);
        const date = new Date(this.minDate);

        if (selected.getDate() == date.getDate() && selected.getMonth() == date.getMonth())
            return date.getHours() + ':' + date.getMinutes();
        return '0:00';
    }

    get formatCustomStartDate() {
        return this.formatDate(this.startDateSelected);
    }

    get formatCustomEndDate() {
        return this.formatDate(this.endDateSelected);
    }

    get hasRightModifyRound(): boolean {
        return this.hasRight(Right.ADD_PAT_OD);
    }

    private formatDate(date: string) {
        return date.length > 0 ? moment(new Date(date)).locale('fr').format('L') : '';
    }

    @Emit()
    private reload() {}

    private generatePatrol(): PatrolCreator {
        const type = this.getType();
        return {
            siteReference: this.siteReference,
            roundCode: this.roundSelected,
            type: type,
            fixedTime: type == 0 && this.time != null ? this.time : undefined,
            date: new Date(this.dateSelected),
        };
    }

    private generateWeeklyPatrol(): WeeklyPatrolCreator {
        const type = this.getType();
        return {
            siteReference: this.siteReference,
            roundCode: this.roundSelected,
            type: type,
            fixedTime: type == 0 && this.time != null ? this.time : undefined,
            days: this.selectedDays.map((day, index) => (day ? index + 1 : 0)).filter(r => r != 0),
            from: new Date(this.startDateSelected),
            to: new Date(this.endDateSelected),
        };
    }

    private getType() {
        return this.fixedTime ? 0 : this.times.findIndex(time => time == this.timeSelected) + 1;
    }
}
