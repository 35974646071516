import { IRule } from './IRule';

export class MinLengthRule implements IRule {
    passed: boolean;
    minLength: number;
    description: string;
    descriptionMobile: string;
    showDescription: boolean;

    constructor(min: number, showDescription: boolean) {
        this.minLength = min;
        this.description = min.toString() + ' caractère' + (min > 1 ? 's ' : ' ') + 'min.';
        this.passed = false;
        this.showDescription = showDescription;
        this.descriptionMobile = min.toString() + ' carac';
    }

    check(value: string): boolean | string {
        this.passed = value != undefined && value != null && value.length >= this.minLength;
        return this.passed ? this.passed : this.description;
    }

    withDescriptionMobile(descriptionMobile: string) {
        this.descriptionMobile = descriptionMobile;
        return this;
    }
}
