import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import { IMenuItem } from '@/domain/menu/IMenuItem';
import { Right } from '@/domain/menu/Right';
import { RightRepository } from '@/domain/rights/RightRepository';
import { IconVue } from '@/primary/components/common/icon';
import MenuMobileBus from '@/primary/components/menu/MenuMobileBus';

@Component({
    components: {
        IconVue,
    },
})
export default class MenuItemComponent extends Vue {
    @Prop({ required: true })
    private menuItem!: IMenuItem;

    @Inject()
    private menuMobileBus!: () => MenuMobileBus;

    @Inject()
    private rightRepository!: () => RightRepository;

    subMenuIsOpen = false;
    selected = false;

    @Watch('$route')
    openSubMenuIfIsCurrentPage() {
        this.menuItem.childs?.forEach(child => {
            if (child.url === this.$route.name) this.showSubMenu();
        });
    }

    mounted() {
        this.openSubMenuIfIsCurrentPage();
    }

    showSubMenu() {
        this.subMenuIsOpen = true;
    }

    swapSubMenu() {
        this.subMenuIsOpen = !this.subMenuIsOpen;
    }

    closeMenuMobile() {
        this.menuMobileBus().add(false);
    }

    to(url: string) {
        this.$router.push({ name: url });
    }

    hasRight(rights?: Right[]): boolean {
        return rights ? rights.every(right => this.rightRepository().hasRight(right)) : true;
    }
}
