import { SiteDetails } from '@/domain/site/siteDetails/SiteDetails';
import { dateFormat } from '@/primary/utils/Filters/DateFormat';
import { RestSiteDetailsAddress } from '@/secondary/restSite/RestSiteDetailsAddress';
import { RestSiteDetailsContract } from '@/secondary/restSite/RestSiteDetailsContract';
import { RestSiteDetailsHardware } from '@/secondary/restSite/RestSiteDetailsHardware';
import { RestSiteDetailsManager } from '@/secondary/restSite/RestSiteDetailsManager';
import { RestSiteDetailsSupport } from '@/secondary/restSite/RestSiteDetailsSupport';

export interface RestSiteDetails {
    Address: RestSiteDetailsAddress;
    BillingAddress: RestSiteDetailsAddress;
    ContractInfos: RestSiteDetailsContract;
    Hardware: RestSiteDetailsHardware;
    Manager: RestSiteDetailsManager;
    MonitoringCustomerSupport: RestSiteDetailsSupport;
    Name: string;
    Reference: string;
}

export const toSiteDetails = (restSiteDetails: RestSiteDetails): SiteDetails => ({
    description: {
        siteReference: restSiteDetails.Reference,
        socialReason: restSiteDetails.Name,
        address: restSiteDetails.Address.Address ? restSiteDetails.Address.Address : '',
        postalCode: restSiteDetails.Address.ZipCode ? restSiteDetails.Address.ZipCode : '',
        city: restSiteDetails.Address.City ? restSiteDetails.Address.City : '',
    },
    address: {
        address: restSiteDetails.BillingAddress.Address ? restSiteDetails.BillingAddress.Address : '',
        postalCode: restSiteDetails.BillingAddress.ZipCode ? restSiteDetails.BillingAddress.ZipCode : '',
        city: restSiteDetails.BillingAddress.City ? restSiteDetails.BillingAddress.City : '',
    },
    responsible: {
        inCharge: restSiteDetails.Manager.Name ? restSiteDetails.Manager.Name : '',
        phone: restSiteDetails.Manager.PhoneNumber ? restSiteDetails.Manager.PhoneNumber : '',
        fax: restSiteDetails.Manager.FaxNumber ? restSiteDetails.Manager.FaxNumber : '',
        mail: restSiteDetails.Manager.Email ? restSiteDetails.Manager.Email : '',
    },
    contract: {
        clientNumber: restSiteDetails.ContractInfos.ClientNumber ? restSiteDetails.ContractInfos.ClientNumber : '',
        subscriptionDate: restSiteDetails.ContractInfos.SubscriptionDate ? dateFormat(restSiteDetails.ContractInfos.SubscriptionDate) : '',
        remoteMonitoringContract: restSiteDetails.ContractInfos.RemoteMonitoringContractNumber
            ? restSiteDetails.ContractInfos.RemoteMonitoringContractNumber
            : '',
        remoteSecurityContract: restSiteDetails.ContractInfos.RemoteVideoMonitoringContractNumber
            ? restSiteDetails.ContractInfos.RemoteVideoMonitoringContractNumber
            : '',
    },
    equipment: {
        model: restSiteDetails.Hardware.Model ? restSiteDetails.Hardware.Model : '',
        brand: restSiteDetails.Hardware.Brand ? restSiteDetails.Hardware.Brand : '',
    },
});
