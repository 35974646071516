import { CallList } from '@/domain/contact/CallList';
import { RestContactCallList, toContactCallList } from '@/secondary/restContact/RestContactCallList';
import { RestPrestation, toPrestation } from '@/secondary/restContact/RestPrestation';

export interface RestCallList {
    Code: string;
    Services: RestPrestation[];
    Contacts: RestContactCallList[];
}

export interface RestCallListsRef {
    Services: RestPrestation[];
    Reference: string;
    CallLists: RestCallList[];
    GeneralInstructions: string[];
}

export const toCallList = (restCallList: RestCallList, ref: string, services: RestPrestation[], generalInstruction?: string): CallList => ({
    reference: ref,
    code: restCallList.Code,
    generalInstruction: generalInstruction,
    prestations: restCallList.Services.map(toPrestation),
    availablePrestations: services.map(toPrestation),
    contacts: restCallList.Contacts.map(toContactCallList),
});
