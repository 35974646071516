import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { Right } from '@/domain/menu/Right';
import { SiteRepository } from '@/domain/site/SiteRepository';
import { UserSite } from '@/domain/site/UserSite';
import { ToastStatus } from '@/domain/toast/ToastStatus';
import { UserRepository } from '@/domain/user/UserRepository';
import { emptyManagedUser, ManagedUser } from '@/domain/userManagement/ManagedUser';
import { Profil } from '@/domain/userManagement/Profil';
import { SimplifiedSite } from '@/domain/userManagement/SimplifiedSite';
import { UserManagementRepository } from '@/domain/userManagement/UserManagementRepository';
import { IconVue } from '@/primary/components/common/icon';
import { userManagementHeaders } from '@/primary/pages/userManagement/userManagementHeaders';
import { Responsive } from '@/primary/utils/Responsive';
import { RightMixin } from '@/primary/utils/rights/RightMixin';
import ToastBus from '@/primary/utils/ToastBus';

@Component({
    components: {
        IconVue,
    },
})
export default class UserManagementPageComponent extends mixins(RightMixin) {
    @Inject()
    private toastBus!: () => ToastBus;

    @Inject()
    private userRepository!: () => UserRepository;

    @Inject()
    private siteRepository!: () => SiteRepository;

    @Inject()
    private responsive!: () => Responsive;

    @Inject()
    private userManagementRepository!: () => UserManagementRepository;

    headers: DataTableHeader[] = userManagementHeaders();
    headersMobile: DataTableHeader[] = userManagementHeaders();
    search = '';
    users: ManagedUser[] = [];
    selectedUserSites: SimplifiedSite[] = [];
    displaySites = false;
    isDeleteModalOpen = false;
    toDeleteUsers: number[] = [];
    isUserModalOpen = false;
    modalUser: ManagedUser = emptyManagedUser();
    hasError = false;
    sites: UserSite[] = [];
    selectedSites: number[] = [];
    telephoneNumberRules = /^((\+)33|0)[1-9](\d{2}){4}$/; // +33621639622
    isTelephoneNumberValid = true;

    mounted() {
        this.loadData();
        this.siteRepository()
            .getSirusUserSite()
            .then(sites => (this.sites = sites));
    }

    get flatSite() {
        return [
            ...this.sites.filter(site => site.sites === undefined),
            ...this.sites.filter(site => site.sites !== undefined).flatMap(site => site.sites!),
        ];
    }

    get isMobile() {
        return this.responsive().isMobile;
    }

    get isCurrentUser() {
        return false;
    }

    exportData(type: string) {
        this.userManagementRepository().getExport(type);
    }

    openSiteModal(users: ManagedUser) {
        this.selectedUserSites = users.sites;
        this.displaySites = true;
    }

    closeModalSites() {
        this.displaySites = false;
        this.selectedUserSites = [];
    }

    closeModalUsers() {
        this.isUserModalOpen = false;
        this.hasError = false;
        this.isTelephoneNumberValid = true;
        this.modalUser = emptyManagedUser();
        this.selectedSites = [];
    }

    getProfilLabel(user: ManagedUser) {
        switch (user.profil) {
            case Profil.Reader:
                return 'Consult.';
            case Profil.Manager:
                return 'Gest.';
            case Profil.Administrator:
                return 'Admin.';
            default:
                return 'Inconnu';
        }
    }

    openUserModal(user: ManagedUser) {
        this.userRepository()
            .startEdit(user.id)
            .then(() => {
                this.modalUser = { ...user };
                this.modalUser.sites = [...user.sites];
                this.selectedSites = user.sites.map(site => this.flatSite.findIndex(s => s.reference === site.reference));
                this.isUserModalOpen = true;
            })
            .catch(() => {});
    }

    swapSelect(user: ManagedUser) {
        const index = this.toDeleteUsers.indexOf(user.id);
        if (index === -1) this.toDeleteUsers.push(user.id);
        else this.toDeleteUsers.splice(index, 1);
    }

    deleteUsers() {
        this.userManagementRepository()
            .deleteAll(this.toDeleteUsers)
            .then(() => {
                this.toDeleteUsers = [];
                this.loadData();
                this.isDeleteModalOpen = false;
                this.toastBus().add({
                    status: ToastStatus.SUCCESS,
                    title: 'Succès',
                    text: 'Le(s) utilisateur(s) ont été supprimé(s).',
                });
            });
    }

    getUserById(id: number) {
        return this.users.find(user => user.id === id);
    }

    hasAddRight() {
        return this.hasRight(Right.ADD_USER);
    }

    hasDelRight() {
        return this.hasRight(Right.DEL_USER);
    }

    saveUser() {
        const isFormFilled =
            this.modalUser.lastName.length * this.modalUser.firstName.length * this.modalUser.phone.length * this.modalUser.email.length ===
            0;
        if (isFormFilled || !this.isTelephoneNumberValid) {
            this.hasError = true;
            return;
        }
        this.modalUser.sites = this.flatSite.filter((site, index) => this.selectedSites.find(i => i === index) !== undefined);
        this.userManagementRepository()
            .save(this.modalUser)
            .then(async () => {
                await this.loadData();
                this.toastBus().add({
                    status: ToastStatus.SUCCESS,
                    title: 'Succès',
                    text: this.modalUser.id !== -1 ? 'Modification appliquée' : 'Utilisateur créé',
                });
                this.closeModalUsers();
            });
    }

    checkNumberValidation(): void {
        this.isTelephoneNumberValid = this.telephoneNumberRules.test(this.modalUser.phone);
    }

    private loadData() {
        return this.userManagementRepository()
            .getAll()
            .then(users => (this.users = users));
    }
}
