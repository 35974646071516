import { Component, Inject, Vue } from 'vue-property-decorator';
import {
    VDivider,
    VIcon,
    VList,
    VListGroup,
    VListItem,
    VListItemContent,
    VListItemIcon,
    VListItemTitle,
    VNavigationDrawer,
} from 'vuetify/lib';

import { IMenuItem, initMenu } from '@/domain/menu/IMenuItem';
import { Routes } from '@/domain/Routes';
import { Theme } from '@/domain/theme/Theme';
import { IconVue } from '@/primary/components/common/icon';
import MenuBus from '@/primary/components/menu/MenuBus';
import MenuMobileBus from '@/primary/components/menu/MenuMobileBus';
import { MenuItemVue } from '@/primary/components/menuItem';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

@Component({
    components: {
        VNavigationDrawer,
        VList,
        VListItem,
        VDivider,
        VListItemContent,
        VListItemIcon,
        VListItemTitle,
        VIcon,
        VListGroup,
        IconVue,
        MenuItemVue,
    },
})
export default class MenuComponent extends Vue {
    @Inject()
    private menuBus!: () => MenuBus;

    @Inject()
    private menuMobileBus!: () => MenuMobileBus;

    minify = false;
    subMenuIsOpen = false;
    items: IMenuItem[] = initMenu();
    showMenuMobile = false;
    menuMobileId = 0;
    theme!: Theme;

    created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;
    }

    mounted() {
        this.menuMobileId = this.menuMobileBus().subscribe(show => {
            this.showMenuMobile = show!;
        });
    }

    destroyed() {
        this.menuMobileBus().unsubscribe(this.menuMobileId);
    }

    minifyMenu() {
        this.minify = !this.minify;
        this.menuBus().add(this.minify);
    }

    showSubMenu() {
        this.subMenuIsOpen = !this.subMenuIsOpen;
    }

    changeTheme() {
        this.$vuetify.theme.themes.light = {
            primary: '#8fc73e',
            secondary: '#000000',
            lightPrimary: '#eaf7d7',
            footer: '#eaf7d7',
            accent: '#3f51b5',
            error: '#D0021B',
            warning: '#FEBA68',
            success: '#168A37',
            info: '',
        };
    }

    goToContact() {
        this.$router.push({ name: Routes.Contact });
    }
}
