import { DoubleAuthType } from '@/domain/userManagement/DoubleAuthType';
import { ManagedUser } from '@/domain/userManagement/ManagedUser';
import { Profil } from '@/domain/userManagement/Profil';
import { RestManagedUser } from '@/secondary/userManagement/RestManagedUser';
import { toSimplifiedSite } from '@/secondary/userManagement/RestSimplifiedSite';

export interface RestManagedUserWithId extends RestManagedUser {
    Id: number;
}

const toProfilMap = new Map<number, Profil>([
    [0, Profil.Unknown],
    [1, Profil.Reader],
    [2, Profil.Manager],
    [3, Profil.Administrator],
]);

const toDoubleAuthMap = new Map<number, DoubleAuthType>([
    [0, DoubleAuthType.NO_DOUBLE_AUTH],
    [1, DoubleAuthType.SMS],
    [2, DoubleAuthType.MAIL],
]);

export const toManagedUser = (user: RestManagedUserWithId): ManagedUser => ({
    id: user.Id,
    firstName: user.FirstName,
    lastName: user.LastName,
    email: user.Email,
    phone: user.MobilePhoneNumber,
    profil: toProfilMap.get(user.Profile)!,
    doubleAuthType: toDoubleAuthMap.get(user.TwoFactorAuthenticationMode)!,
    sites: user.VisibleSites.map(toSimplifiedSite),
});
