import { CallListModifyPrimary } from '@/primary/components/modifyCallListModal/modifyCallListModalFirstStep/CallListModifyPrimary';

interface State {
    callList: CallListModifyPrimary;
}

export const callListStorageModule = () => ({
    namespaced: true,
    state: () => ({
        callList: {},
    }),
    getters: {
        callList(state: State) {
            return state.callList;
        },
    },
    mutations: {
        modifyCallList(state: State, callList: CallListModifyPrimary) {
            state.callList = callList;
        },
    },
});
