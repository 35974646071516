import { Component, Prop, Vue } from 'vue-property-decorator';

import { IconHTML, IconName } from '@/primary/components/common/icon/IconUtils';

@Component
export default class IconComponent extends Vue {
    @Prop({ required: true })
    name!: IconName;

    @Prop()
    color?: string;

    @Prop()
    customClass?: string;

    iconHTML?: number = 0;

    mounted() {
        this.iconHTML = IconHTML.get(this.name);
    }
}
