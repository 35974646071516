import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { RestQuote, toQuote } from './RestQuote';

import { IExportQuoteParam } from '@/domain/quotes/IExportQuoteParam';
import { IQuote } from '@/domain/quotes/IQuote';
import { IQuoteRepository } from '@/domain/quotes/IQuoteRepository';
import { downloadFile } from '@/secondary/DownloadFile';

export default class RestQuoteRepository implements IQuoteRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getQuotes(): Promise<IQuote[]> {
        return this.axiosInstance
            .get('/Quotes')
            .then((response: AxiosResponse<RestQuote[]>) => {
                return response.data.map(toQuote);
            })
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getQuoteDetailReport(quoteId: number): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .get(`/Quotes/${quoteId}/Export`, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }

    getExport(type: string, exportParams: IExportQuoteParam): Promise<any> {
        const params: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        };
        return this.axiosInstance
            .post(`/Quotes/Export/${type}`, exportParams, params)
            .then(downloadFile)
            .catch((error: Error) => {
                throw Error(error.message);
            });
    }
}
