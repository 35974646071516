import 'vuetify/dist/vuetify.min.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import fr from 'vuetify/src/locale/fr';
import { ThemeOptions } from 'vuetify/types/services/theme';

import globalTheme from '../../public/assets/global/config.json';
import itesisTheme from '../../public/assets/itesis/config.json';
import surwayTheme from '../../public/assets/surway/config.json';

import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

// Translation provided by Vuetify (typescript)

Vue.use(Vuetify);

export const themeOptions: ThemeOptions = {
    themes: {
        light: { ...itesisTheme, ...globalTheme },
    },
};

export const themeOptionsSurway: ThemeOptions = {
    themes: {
        light: { ...surwayTheme, ...globalTheme },
    },
};

export default new Vuetify({
    theme: RestThemeRepository.isItesis() ? themeOptions : themeOptionsSurway,
    options: {
        customProperties: true,
    },
    lang: {
        locales: { fr },
        current: 'fr',
    },
});
