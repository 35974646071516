import { Component, Vue } from 'vue-property-decorator';
import { VApp, VMain } from 'vuetify/lib';

import { Theme } from '@/domain/theme/Theme';
import { themeOptions, themeOptionsSurway } from '@/plugins/vuetify';
import { ToastVue } from '@/primary/app/toast';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

@Component({
    components: {
        VApp,
        VMain,
        ToastVue,
    },
})
export default class AppComponent extends Vue {
    private theme!: Theme;

    created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;

        this.$vuetify.theme.themes.light =
            this.theme == Theme.ITESIS ? (themeOptions.themes!.light as any) : themeOptionsSurway.themes!.light;

        if (this.theme == Theme.SURWAY) {
            const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
            link.href = '/assets/surway/fav/favicon_surway.ico';
            document.title = 'surway';
        }
    }

    get styleVariables() {
        const themeColors: any = {};
        Object.keys(this.$vuetify.theme.themes.light).forEach(color => {
            themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
        });
        return themeColors;
    }
}
