import { mixins } from 'vue-class-component';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';

import { Right } from '@/domain/menu/Right';
import { VideoPatrol } from '@/domain/video/VideoPatrol';
import { VideoPatrolRepository } from '@/domain/video/VideoPatrolRepository';
import { IconVue } from '@/primary/components/common/icon';
import { VideoPatrolDialogVue } from '@/primary/components/videoPatrolDialog';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
        VideoPatrolDialogVue,
    },
})
export default class VideoPatrolComponent extends mixins(RightMixin) {
    @Prop({ required: true })
    private siteReference!: string;

    @Inject()
    private videoPatrolRepository!: () => VideoPatrolRepository;

    private dayMap: Map<number, string> = new Map<number, string>([
        [0, 'DIMANCHE'],
        [1, 'LUNDI'],
        [2, 'MARDI'],
        [3, 'MERCREDI'],
        [4, 'JEUDI'],
        [5, 'VENDREDI'],
        [6, 'SAMEDI'],
    ]);

    isDeleteModalOpen = false;
    patrolToDeleteId = '';
    bpsm = 600;
    isMobile = this.bpsm > window.innerWidth;
    focus = '';
    type = 'week';
    reload = 0;
    numberOfDays = this.isMobile ? 2 : 7;
    currentDate = new Date();
    dates: Date[] = [];
    patrolTable: (string | VideoPatrol[])[][] = [
        [''],
        this.initNight(),
        this.initMorning(),
        this.initNoon(),
        this.initAfternoon(),
        this.initEvening(),
    ];

    monthMap: Map<number, string> = new Map<number, string>([
        [0, 'JANVIER'],
        [1, 'FÉVRIER'],
        [2, 'MARS'],
        [3, 'AVRIL'],
        [4, 'MAI'],
        [5, 'JUIN'],
        [6, 'JUILLET'],
        [7, 'AOÛT'],
        [8, 'SEPTEMBRE'],
        [9, 'OCTOBRE'],
        [10, 'NOVEMBRE'],
        [11, 'DÉCEMBRE'],
    ]);

    created() {
        this.updateDates(this.numberOfDays);
        window.onresize = () => {
            const isMobile = this.bpsm > window.innerWidth;
            this.numberOfDays = isMobile ? 2 : 7;
            this.updateDates(this.numberOfDays);
            this.isMobile = isMobile;
        };
    }

    setToday() {
        this.currentDate = new Date();
        this.updateDates(this.numberOfDays);
        this.getPatrols();
    }

    prev() {
        this.currentDate.setDate(this.currentDate.getDate() - this.numberOfDays);
        this.updateDates(this.numberOfDays);
        this.getPatrols();
    }

    next() {
        this.currentDate.setDate(this.currentDate.getDate() + this.numberOfDays);
        this.updateDates(this.numberOfDays);
        this.getPatrols();
    }

    triggerDelete(patrol: VideoPatrol): void {
        this.isDeleteModalOpen = true;
        this.patrolToDeleteId = patrol.id;
    }

    deletePatrol(): void {
        this.videoPatrolRepository().deletePatrol(this.siteReference, this.patrolToDeleteId);
        this.isDeleteModalOpen = false;
        this.getPatrols();
    }

    isStartedPatrol(patrol: VideoPatrol): boolean {
        return patrol.start < new Date();
    }

    @Watch('isMobile')
    @Watch('siteReference')
    getPatrols() {
        this.videoPatrolRepository()
            .getPatrols(this.siteReference, this.dates[0], this.dates[this.dates.length - 1])
            .then(patrols => {
                this.resetCalendar();
                patrols.forEach(patrol => {
                    const dayPosition = this.getDayPositionFromDate(patrol.start);
                    if (dayPosition != -1) (this.patrolTable[patrol.slot + 1][dayPosition + 1] as VideoPatrol[]).push(patrol);
                });
                this.reload++;
            });
    }

    get hasRightModifyRound(): boolean {
        return this.hasRight(Right.ADD_PAT_OD);
    }

    private generateFirstLine(dates: Date[]): string[] {
        const result: string[] = dates.map(date => {
            return this.getDayFromNumber(date.getDay()) + ' ' + date.getDate();
        });
        result.unshift('');
        return result;
    }

    private getDayFromNumber(day: number): string {
        return this.dayMap.get(day)!;
    }

    private updateDates(n: number) {
        const startDay = this.getStartDay();
        let i = 0;
        this.dates.length = 0;

        while (i < n) {
            this.dates.push(this.getDate(i + startDay));
            i++;
        }
    }

    private getStartDay() {
        return this.currentDate.getDay();
    }

    private getDate(dayPosition: number): Date {
        return new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            this.currentDate.getDate() - this.currentDate.getDay() + dayPosition
        );
    }

    private getDayPositionFromDate(start: Date): number {
        return this.dates.findIndex(date => date.getMonth() * 100 + date.getDate() == start.getMonth() * 100 + start.getDate());
    }

    private resetCalendar() {
        this.patrolTable[0] = this.generateFirstLine(this.dates);
        this.patrolTable[1] = this.initNight();
        this.patrolTable[2] = this.initMorning();
        this.patrolTable[3] = this.initNoon();
        this.patrolTable[4] = this.initAfternoon();
        this.patrolTable[5] = this.initEvening();
    }

    private initEvening() {
        return this.initEmptyLine('SOIR (18h30-0h00)');
    }

    private initAfternoon() {
        return this.initEmptyLine('APRÈS-MIDI (14h00-18h30)');
    }

    private initNoon() {
        return this.initEmptyLine('MIDI (12h00-14h00)');
    }

    private initMorning() {
        return this.initEmptyLine('MATIN (6h30-12h00)');
    }

    private initNight() {
        return this.initEmptyLine('NUIT (0h00-6h30)');
    }

    private initEmptyLine(name: string) {
        return [name, [], [], [], [], [], [], []];
    }
}
