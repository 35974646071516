import { CallListModify } from '@/domain/contact/CallListModify';

export interface CallListModifyPrimary extends CallListModify {
    created: boolean;
    index: number;
}

export const emptyCallListModifyPrimary: CallListModifyPrimary = {
    CallList: {
        Prestations: [],
        Contacts: [],
        Code: '',
    },
    SiteReference: '',
    ValidityStart: '',
    ValidityEnd: '',
    UserFirstName: '',
    UserLastName: '',
    UserPhoneNumber: '',
    created: false,
    index: 0,
};
