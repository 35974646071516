import { render, staticRenderFns } from "./App.html?vue&type=template&id=445972b3&lang=html&external"
import script from "./App.component.ts?vue&type=script&lang=ts&external"
export * from "./App.component.ts?vue&type=script&lang=ts&external"
import style0 from "./App.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports