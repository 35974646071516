import { Component, Inject, Vue } from 'vue-property-decorator';
import { VMain } from 'vuetify/lib';

import { CommonFooterVue } from '@/primary/components/commonFooter';
import { FooterVue } from '@/primary/components/footer';
import { MenuVue } from '@/primary/components/menu';
import MenuBus from '@/primary/components/menu/MenuBus';
import { NavbarVue } from '@/primary/components/navbar';

@Component({
    components: {
        VMain,
        CommonFooterVue,
        MenuVue,
        NavbarVue,
        FooterVue,
    },
})
export default class ClientLayout extends Vue {
    @Inject()
    private menuBus!: () => MenuBus;

    isMenuMinified = false;
    isMenuMinifiedSubscribeId = 0;

    mounted() {
        this.subscribeToIsMenuMinifiedBus();
    }

    destroyed() {
        if (this.isMenuMinifiedSubscribeId) this.menuBus().unsubscribe(this.isMenuMinifiedSubscribeId);
    }

    private subscribeToIsMenuMinifiedBus() {
        this.isMenuMinifiedSubscribeId = this.menuBus().subscribe(menuMinify => {
            this.isMenuMinified = menuMinify!;
        });
    }
}
