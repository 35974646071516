import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { CallList } from '@/domain/contact/CallList';
import { ContactInformationsModify } from '@/domain/contact/ContactInformationsModify';
import { ContactRepository } from '@/domain/contact/ContactRepository';
import { Site } from '@/domain/contact/Site';
import { CallListModifyPrimary } from '@/primary/components/modifyCallListModal/modifyCallListModalFirstStep/CallListModifyPrimary';
import { ErrorMessage } from '@/secondary/error/ErrorMessage';
import { RestCallListsRef, toCallList } from '@/secondary/restContact/RestCallList';
import { toRestCallListModify } from '@/secondary/restContact/RestCallListModify';
import { RestContactInformationsModify, toRestContactInformationsModify } from '@/secondary/restContact/RestContactInformationsModify';
import { RestSite, toSite } from '@/secondary/restContact/RestSite';

export class RestContactRepository implements ContactRepository {
    controller = new AbortController();
    currentSalve = false;

    constructor(private axiosInstance: AxiosInstance) {}

    getContacts(): Promise<Site[]> {
        return this.axiosInstance
            .get('/Contacts/Groups')
            .then((response: AxiosResponse<RestSite[]>) => {
                return response.data.map(toSite);
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }

    getContactsDelta(): Promise<Site[]> {
        return this.axiosInstance
            .get('/Contacts/Groups?ContactType=1')
            .then((response: AxiosResponse<RestSite[]>) => {
                return response.data.map(toSite);
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }

    updatePhones(name: string, oldPhones: string[], newPhones: string[]): Promise<boolean> {
        return this.axiosInstance
            .put('/Contacts/UpdatePhone', { Name: name, PhoneNumbers: newPhones, OldPhoneNumbers: oldPhones })
            .then(() => {
                return true;
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }

    updateContacts(contactInformationsModify: ContactInformationsModify): Promise<boolean> {
        const restContactInformationsModify: RestContactInformationsModify = toRestContactInformationsModify(contactInformationsModify);
        return this.axiosInstance
            .post('/Contacts/UpdateRequest', restContactInformationsModify)
            .then(() => {
                return true;
            })
            .catch((error: ErrorMessage) => {
                throw Error(error.message);
            });
    }

    interruptGetCallList(): void {
        this.controller.abort();
    }

    getCallList(siteReference: string): Promise<CallList[]> {
        if (!this.currentSalve) {
            this.controller = new AbortController();
            setTimeout(() => (this.currentSalve = false), 200);
            this.currentSalve = true;
        }

        return this.axiosInstance
            .get(`/Site/${siteReference}/CallLists`, {
                signal: this.controller.signal,
            })
            .then((response: AxiosResponse<RestCallListsRef>) => {
                return response.data.CallLists.map(callList =>
                    toCallList(callList, response.data.Reference, response.data.Services, response.data.GeneralInstructions[0])
                );
            })
            .catch(error => {
                throw Error(error);
            });
    }

    updateCallList(callListModify: CallListModifyPrimary): Promise<boolean> {
        if (callListModify.CallList.Code == '') {
            const date = new Date();
            callListModify.CallList.Code = 'DEM-' + callListModify.SiteReference + '-' + date.getDate() + date.getHours();
        }

        return this.axiosInstance
            .post('/CallLists/UpdateRequest', toRestCallListModify(callListModify))
            .then(() => {
                return true;
            })
            .catch((error: AxiosError) => {
                throw Error(error.message);
            });
    }
}
