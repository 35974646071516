export enum Routes {
    HomePage = 'Home',
    LoginPage = 'Login',
    DashboardPage = 'Dashboard',
    SettingsPage = 'Settings',
    HistoryPage = 'History',
    InstructionsPage = 'Instruction',
    VideoCheckUpPage = 'VideoCheckUp',
    Contact = 'Contact',
    SitePage = 'Site',
    TroubleshootingPage = 'Troubleshooting',
    MyInspection = 'MyInspection',
    WorkSitePage = 'WorkSite',
    QuotesPage = 'Quotes',
    DocumentPage = 'Document',
    ForgottenPasswordPage = 'ForgottenPassword',
    ActivateAccountPage = 'ActivateAccount',
}
