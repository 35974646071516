export interface Prestation {
    code: string;
    instruction?: string;
    label: string;
}

export const emptyPrestation = (): Prestation => ({
    code: '',
    label: '',
});
