import { Component, Prop, Vue } from 'vue-property-decorator';

import { CallList } from '@/domain/contact/CallList';
import { contactCallListModalHeaderReview } from '@/domain/header/modalCallListHeader';
import { IconVue } from '@/primary/components/common/icon';

@Component({
    components: {
        IconVue,
    },
})
export default class CallListComponent extends Vue {
    @Prop({ default: 1 })
    index!: number;

    @Prop({ required: true })
    callList!: CallList;

    headerContact = contactCallListModalHeaderReview;
}
